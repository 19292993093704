<template>
  <div class="SelectConnectPopup" @click="hide()" v-if="showSelectConnectPopup" style="opacity: 1;" @mousewheel.prevent>
    <div class="SelectConnectPopupContainer">
      <div class="SelectConnectPopupContent" @click.stop>
        <div class="connectWallet">
          <div class="hide">
            <img @click="hide" src="../assets/x_min.svg">
          </div>
          <div class="titleText">
            <div>Connect to a wallet</div>
          </div>
          <div class="content">
            <div class="connectContent">

              <button v-if="ifInstallMetamask" @click="onConnect('metamask', 'MetaMask')" :class="providerType == 'MetaMask' ? 'connectItemActive' : 'connectItem'">
                <div class="connectName">
                  <div class="connectTittle">
                    <div class="activeItem" v-if="providerType == 'MetaMask'">
                      <div class="active">
                        <div></div>
                      </div>
                    </div>MetaMask
                  </div>
                </div>
                <div class="connectImg">
                  <img src='../assets/metamask.png' alt="Icon">
                </div>
              </button>

              <a v-else target="_blank" rel="noopener noreferrer" href="https://metamask.io/" class="install">
                <button class="connectItem">
                  <div class="connectName">
                    <div color="#E8831D" class="connectTittle">Install Metamask</div>
                  </div>
                  <div class="connectImg">
                    <img src="../assets/metamask.png" alt="Icon">
                  </div>
                </button>
              </a>

              <button v-for="(item, index) in connectList" :key="index" @click="onConnect(item.connectTo, item.name)" :class="providerType == item.name ? 'connectItemActive' : 'connectItem'">
                <div class="connectName">
                  <div class="connectTittle">
                    <div class="activeItem" v-if="providerType == item.name">
                      <div class="active">
                        <div></div>
                      </div>
                    </div>{{ item.name }}
                  </div>
                </div>
                <div class="connectImg">
                  <img :src=item.imgSrc alt="Icon">
                </div>
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import proxy from "../proxy.js";
import Web3 from 'web3'
import Web3modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
export default {
  name: 'SelectConnectPopup',
  data() {
    return {
      connectList: [
        {
          name: 'WalletConnect',
          imgSrc: require('../assets/walletConnectIcon.svg'),
          connectTo: 'walletconnect'
        }
      ]
    }
  },
  props: ['connect'],
  computed: {
    showSelectConnectPopup() {
      return this.$store.state.navMenu.showSelectConnectPopup
    },

    ifInstallMetamask() {
      return this.$store.state.navMenu.ifInstallMetamask
    },

    providerType() {
      return this.$store.state.navMenu.providerType
    },
    
  },

  created () {
    this.init()
  },

  watch: {
  },

  methods: {
    init () {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.VUE_APP_INFURAID
          }
        }
      }

      this.web3Modal = new Web3modal({
        cacheProvider: false,
        providerOptions,
        disableInjectedProvider: false 
      })

      this.$store.commit('updateWeb3Modal',  this.web3Modal)
    },

    async fetchAccountData (name) {
      this.$store.commit('showSelectConnectPopup')
      const web3 = new Web3(this.provider)

      const accounts = await web3.eth.getAccounts()

      const rowResolvers = accounts.map(async () => {
        this.$store.commit('updateProviderType',  name)
        this.$store.commit('updateProvider',  this.provider)
      })
      await Promise.all(rowResolvers)
    },

    async refreshAccountData (name) {
      await this.fetchAccountData(name)
    },

    async onConnect (connectTo, name) {
      if(connectTo == 'metamask') {
          this.$store.commit('showSelectConnectPopup')
          // this.$store.commit('updateProvider',  window.ethereum)
          // this.$store.commit('updateProviderType',  'MetaMask')
          // this.$store.commit('updateConnectWallet', true)
          this.$parent.getData(false);
      } else {
        this.SelectConnectPopup(connectTo, name)
      }
    },

    async SelectConnectPopup(connectTo, name) {
        try {
          this.provider = await this.web3Modal.connectTo(connectTo);
        } catch (e) {
          console.log('Could not get a wallet connection', e)
          return
        }

        // Subscribe to provider disconnection
        this.provider.on("disconnect", async () => {
          this.$store.commit('updateProviderType',  'MetaMask')
          this.$store.commit('updateProvider',  null)
        });
        await this.refreshAccountData(name)
    },

    hide() {
      this.$store.commit('showSelectConnectPopup')
    },

    // logout() {
    //   this.hide()
    //   this.$store.commit('updateProviderType',  '')
    //   this.$parent.logout();
    // }
  },
}
</script>

<style scoped>
.SelectConnectPopup {
  z-index: 2;
  overflow: hidden;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  background: rgba(0,0,0,.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.SelectConnectPopupContainer {
  margin: 0px 0px 32px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(47, 128, 237, 0.05) 0px 4px 8px 0px;
  padding: 0px;
  width: 420px;
  height: 244px;
  overflow: hidden;
  align-self: center;
  display: flex;
  border-radius: 20px;
  background: #fff;
  outline: none;
}

.SelectConnectPopupContent {
  display: flex;
  flex-flow: column nowrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.connectWallet {
  position: relative;
}

.hide {
  position: absolute;
  right: 16px;
  font-size: 0;
  top: 14px;
}

.hide:hover {
  cursor: pointer;
  opacity: 0.6;
}

.titleText {
  display: flex;
  flex-flow: row nowrap;
  padding: 16px;
  font-weight: 500;
  color: inherit;
  font-size: 16px;
}

.back {
  font-weight: 500;
  color: #ff007a;
  cursor: pointer
}

.content {
  background-color: rgb(247, 248, 250);
  padding: 32px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.connectContent {
  display: grid;
  gap: 10px;
}

.connectItem {
  background-color: #f7f8fa;
  outline: none;
  border: 1px solid #edeef2;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0px;
  opacity: 1;
  width: 100% !important;
}

.connectItemActive {
  background-color: #edeef2;
  outline: none;
  border: 1px solid transparent;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0px;
  opacity: 1;
  width: 100% !important;
}

.connectItem:focus {
  box-shadow: #ff007a 0px 0px 0px 1px;
}

.connectItem:hover {
  cursor: pointer;
  border: 1px solid #ff007a;
}

.connectName {
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.connectTittle {
  display: flex;
  flex-flow: row nowrap;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 500;
}

.activeItem {
  color: #27ae60;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.active {
  height: 8px;
  width: 8px;
  margin-right: 8px;
  background-color: #27ae60;
  border-radius: 50%;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.connectImg {
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.connectImg > img, .connectImg span {
  height: 24px;
  width: 24px;
}

.install {
  text-decoration: none;
  cursor: pointer;
  color: rgb(255, 0, 122);
  font-weight: 500;
}

.more {
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 32px;
}

.more span {
  margin-right: 10px;
}

.moreLink {
  text-decoration: none;
  cursor: pointer;
  color: rgb(255, 0, 122);
  font-weight: 500;
}

.logout {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}

</style>
