<template>
  <div class="home" @click="popupHide">
    <div class="content" :style="'min-height:' + minHeight  + 'px'">
        <div>
        <div class="nav">
            <div>
                <img class="logoP" src="../assets/logo.png" @click="toCryptoart()">
                <img class="logoM" src="../assets/logo-m.png" @click="toCryptoart()">
                <div>V1</div>
            </div>
            <div>
            <div class="allCartAmount">
                <div v-if="connect" class="connected" @click="logout()">
                    <div>
                        <img class="logoP" src="../assets/bag.png">
                        <img class="logoM" src="../assets/bagM.png">
                        <span>{{ acc }}</span>
                    </div>
                    <div>{{ cartBalance }}<span> CART</span></div>
                </div>

                <div v-if="!connect" class="connect connectP" @click="showConnect()">
                    <img src="../assets/bag.png">
                    <span>CONNECT</span>
                </div>

                <div v-if="!connect" class="connect connectM" @click="getData(false)">
                    <img src="../assets/bagM.png">
                    <span>CONNECT</span>
                </div>

                <div class="navRight">
                    <div class="line"></div>
                    <div class="cartDate">
                        <div class="cartStaked">{{ cartStaked }}<span>CART STAKED</span></div>
                        <div class="cartLine"></div>
                        <div class="cart">{{ bcart }}<span>bCART</span></div>
                        <div v-if="connect" class="manegeStake connectManage" @click="popup" @click.stop>MANAGE STAKE</div>
                        <div v-else class="manegeStake">MANAGE STAKE</div>
                        <div v-if="popupShow" class="manege" @click.stop @mousewheel.prevent @touchmove.prevent>
                        <img class="hideManegeP" src="../assets/x.png" @click="popupHide">
                        <img class="hideManegeM" src="../assets/xM.png" @click="popupHide">
                        <div class="title">
                            <div>MANAGE STAKE</div>
                            <div v-if="type == 'stake'">
                                <span>Stake</span>
                                <img @click="switchType('withdraw')" src="../assets/switch-left.png">
                            </div>
                            <div v-if="type == 'withdraw'">
                                <span>Withdraw</span>
                                <img @click="switchType('stake')" src="../assets/switch-right.png">
                            </div>
                        </div>
                        <div v-if="type == 'stake'" class="stakeCart">Stake CART tokens</div>
                        <div v-if="type == 'withdraw'" class="stakeCart">Withdraw CART tokens</div>
                        <div v-if="type == 'stake'" class="earn">Earn<span></span>bCARTS<span></span>to Mint NFTs</div>
                        <div v-if="type == 'stake'" class="stakeDetails">Staking 2000 tokens in the pools earns you roughly 1.92 bCARTS per day. With enough bCARTS, you can mint an exclusive limted-edition NFT. Minimum deposit amount is 2000 CARTS and you can have multiple deposits. Earnings every moment, withdraw instantly any time.</div>
                        <div class="available">
                            <div>
                                <span>Available: </span>
                                <b v-if="type == 'stake'">{{ cartAmount }}</b>
                                <b v-if="type == 'withdraw'">{{ cartStaked }}</b>
                            </div>

                            <div>
                                <div @click="selectRatio(0.25)" :class="(ratio == 0.25) ? 'active' : '' ">25%</div>
                                <div @click="selectRatio(0.5)" :class="(ratio == 0.5) ? 'active' : '' ">50%</div>
                                <div @click="selectRatio(0.75)" :class="(ratio == 0.75) ? 'active' : '' ">75%</div>
                                <div @click="selectRatio(1)" :class="(ratio == 1) ? 'active' : '' ">100%</div>
                            </div>
                        </div>

                        <div v-if="type == 'stake'" class="amount">
                            <input name="stakeAmount" v-model="stakeAmount" type="number" placeholder="input amount you want stake">
                            <div v-if="prompt">Min 2000 CART</div>
                        </div>

                        <div v-if="type == 'withdraw'" class="amount">
                            <input name="stakeAmount" v-model="wdAmount" type="number" placeholder="input amount you want withdraw">
                        </div>

                        <div v-if="type == 'stake'" @click="deposit" class="stakeButton" :class="staking ? 'staking' : ''">STAKE</div>

                        <div v-if="type == 'withdraw'" @click="withdraw" class="stakeButton" :class="staking ? 'staking' : ''">WITHDRAW</div>
                    </div>
                    </div>
                </div>
            </div>

            <div class="tutorial">
                <div>
                    <div class="cartStaked">132102<span>Total Reward</span></div>
                    <div class="cartLine"></div>
                    <div class="cart">{{ balanceOfAward }}<span>CART In Reward</span></div>
                    <div v-if="connect" class="tutorialButton connectManage" @click="toTutortal()" @click.stop>TUTORIAL</div>
                    <div v-else class="tutorialButton">TUTORIAL</div>
                </div>
            </div>
            </div>
        </div>

        <div class="mintTitle">
            <div></div>
            <div>MINT</div>
        </div>

        <div class="mineList">
            <div>
                <!-- <img class="mintImg" src="../assets/mint-1.jpg"> -->
                <!-- <video class="mintImg" autoplay loop muted preload="none"  poster="../assets/mint-1.jpg" >
                    <source src="../assets/cryptokitties_common_final.mp4" type="video/mp4">
                </video> -->
                <!-- <img class="imgBg imgBgP" src="../assets/border.png">
                <img class="imgBg imgBgM" src="../assets/borderM.png"> -->
                <div>
                    <div class="mineInfo">
                        <div>
                            <b>{{ commonLeft }}</b>
                            <span>Left</span>
                        </div>
                        <div>
                            <b>75</b>
                            <span>bCART</span>
                        </div>
                        <div>
                            <b>300</b>
                            <span>Supply</span>
                        </div>
                    </div>
                    <div v-if="commonLeft > 0 && bcartInt >= 75" @click="showApprovePopup(1)" class="mine" @click.stop>MINT</div>
                    <div v-if="commonLeft <= 0" class="soldOut">SOLD OUT</div>
                    <div v-if="bcartInt < 75 && commonLeft > 0" class="soldOut">MINT</div>
                </div>
            </div>
            <div>
                <!-- <img class="mintImg" src="../assets/mint-2.jpg"> -->
                <!-- <video class="mintImg" autoplay loop muted preload="none"  poster="../assets/mint-2.jpg" >
                    <source src="../assets/cryptokitties_rare_final.mp4" type="video/mp4">
                </video> -->
                <!-- <img class="imgBg imgBgP" src="../assets/border.png">
                <img class="imgBg imgBgM" src="../assets/borderM.png"> -->
                <div>
                    <div class="mineInfo">
                        <div>
                            <b>{{ rareLeft }}</b>
                            <span>Left</span>
                        </div>
                        <div>
                            <b>150</b>
                            <span>bCART</span>
                        </div>
                        <div>
                            <b>50</b>
                            <span>Supply</span>
                        </div>
                    </div>
                    <div v-if="rareLeft > 0 && bcartInt >= 150" @click="showApprovePopup(2)" class="mine"  @click.stop>MINT</div>
                    <div v-if="rareLeft <= 0" class="soldOut">SOLD OUT</div>
                    <div v-if="bcartInt < 150 && rareLeft > 0" class="soldOut">MINT</div>
                </div>
            </div>
            <div>
                <!-- <img class="mintImg" src="../assets/mint-3.jpg"> -->
                <!-- <video class="mintImg" autoplay loop muted src="../assets/cryptokitties_epic_final.mp4"></video> -->
                <!-- <video class="mintImg" autoplay loop muted preload="none"  poster="../assets/mint-3.jpg" >
                    <source src="../assets/cryptokitties_epic_final.mp4" type="video/mp4">
                </video> -->
                <!-- <img class="imgBg imgBgP" src="../assets/border.png"> -->
                <!-- <img class="imgBg imgBgM" src="../assets/borderM.png"> -->
                <div>
                    <div class="mineInfo">
                        <div>
                            <b>{{ lengendaryLeft }}</b>
                            <span>Left</span>
                        </div>
                        <div>
                            <b>450</b>
                            <span>bCART</span>
                        </div>
                        <div>
                            <b>10</b>
                            <span>Supply</span>
                        </div>
                    </div>
                    <div v-if="lengendaryLeft > 0 && bcartInt >= 450" @click="showApprovePopup(3)" class="mine"  @click.stop>MINT</div>
                    <div v-if="lengendaryLeft <= 0" class="soldOut">SOLD OUT</div>
                    <div v-if="bcartInt < 450 && lengendaryLeft > 0" class="soldOut">MINT</div>
                </div>
            </div>
        </div>

        <div class="myTitle mintTitle" v-if="myCollects.length">
            <div></div>
            <div>MY COLLECTION</div>
        </div>

        <div class="mycollection" v-if="myCollects.length">
            <div v-for="(item, key) in myCollects" :key="key">
                <div class="collectionImg">
                    <img v-if="item.isNew" class="imgBg imgBgP" src="../assets/new.png">
                    <!-- <img v-else class="imgBg imgBgP" src="../assets/border.png"> -->
                    <img v-if="item.isNew" class="imgBg imgBgM" src="../assets/newM.png">
                    <!-- <img v-else class="imgBg imgBgM" src="../assets/borderM.png"> -->
                    <video class="mintImg" controls loop v-if="item.isVideo != -1" :src="item.img"></video>
                    <img v-else class="mintImg" :src="item.img">
                </div>
                <div class="collectionButton">
                    <div @click="reddem(item.tokenId)">
                        <div>Redeem</div>
                        <el-tooltip content=" rewards will back to your wallet, also you can sell your NFT on cryprtoart.ai " placement="top">
                            <img class="reddem" src="../assets/details.png">
                        </el-tooltip>
                    </div>

                    <a :href="item.detail">View</a>
                </div>
            </div>
        </div>

        <div class="recentTitle mintTitle" v-if="commonLeft + rareLeft + lengendaryLeft < 360">
            <div></div>
            <div>RECENT DROP</div>
        </div>

        <div class="pagination" v-if="commonLeft + rareLeft + lengendaryLeft < 360">
            <div class="tr th">
                <div class="owner">Owner</div>
                <div class="edition">Edition No</div>
                <div class="collection">Collection</div>
            </div>

            <div v-if="loding" class="loding">
                <img src="../assets/loding.png">
            </div>

            <div class="tr td" v-for="(item, key) in recentDrop" :key="key">
                <div class="owner">{{ item.owner }}</div>
                <div class="edition">{{ item.tokenId }}</div>
                <a class="collection" :href='"https://cryptoart.ai/gallery/token?id=" + item.tokenId' target="_blank">
                    <!-- <img :src="item.img"> -->
                    <video muted webkit-playsinline preload playsinline v-if="item.isVideo != -1" :src="item.img"></video>
                    <img v-else :src="item.img">
                </a>
            </div>
        </div>

        <!-- <div class="recentDrop" v-if="commonLeft + rareLeft + lengendaryLeft < 360">
            <template v-for="(item, key) in recentDrop">
                <a :key="key" class="recentDropImg" :href='"https://cryptoart.ai/gallery/token?id=" + item.tokenId'>
                    <img class="imgBg imgBgP" src="../assets/border.png">
                    <img class="imgBg imgBgM" src="../assets/borderM.png">
                    <img :src="item.img">
                </a>
            </template>
        </div> -->
        <div class="more" v-if="currentPage <  Math.ceil(total/10)">
            <img @click="more()" src="../assets/more.png">
        </div>

        <div class="container" v-if="commonLeft + rareLeft + lengendaryLeft < 360">
            <img :class="currentPage == 1 ? 'disabled' : ''" src="../assets/prev.png" @click="pervious">
            <el-pagination
                layout="pager"
                :total="total"
                :current-page.sync="currentPage"
                :page-size="10"
                :pager-count='9'
                @current-change="handleCurrentChange"
                ref="pageGroup"
            >
            </el-pagination>
            <img :class="currentPage ==  Math.ceil(total/10) ? 'disabled' : ''" src="../assets/next.png" @click="next">
        </div>
        
    </div>
    </div>

    <div class="bottomMenu">
        <img class="logoP" src="../assets/logo-buttom.png" @click="toCryptoart()">
        <img class="logoM" src="../assets/logo-buttomM.png" @click="toCryptoart()">
        <div>
            <div class="info">
                <div >
                    <span>Email</span>
                    <a>cart.finance@cryptoart.ai</a>
                </div>
                <div>
                    <span>ETHerscan</span>
                    <a class="tokenP nft" href="https://etherscan.io/token/0x3AD503084f1bD8d15A7F5EbE7A038C064e1E3Fa1" target="_blank">NFT</a>
                    <a class="tokenP" href="https://etherscan.io/token/0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e" target="_blank">CART</a>
                    <div class="tokenM">
                        <a class="nft" href="https://etherscan.io/token/0x3AD503084f1bD8d15A7F5EbE7A038C064e1E3Fa1">NFT</a>
                        <a href="https://etherscan.io/token/0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e">CART</a>
                    </div>
                </div>
            </div>

            <div class="icon">
                <span>SOCIAL</span>
                <div>
                    <a href="https://t.me/cryptoartai" target="_blank"><img src="../assets/icon-1.png"></a>
                    <a href="https://twitter.com/CryptoArt_Ai" target="_blank"><img src="../assets/icon-2.png"></a>
                    <a href="https://www.instagram.com/cryptoart.ai/" target="_blank"><img src="../assets/icon-3.png"></a>
                    <a href="https://discord.gg/n94R4haXTF"><img src="../assets/icon-4.png"></a>
                    <a href="https://cryptoart-ai.medium.com/" target="_blank"><img src="../assets/icon-5.png"></a>
                </div>
            </div>
        </div>

        <div class="logoButtomP"><img src="../assets/CERTIK.png"></div>
        <div class="logoButtomM"><img src="../assets/bottom.png"></div>

    </div>

    <div v-if="approvePopup" class="approvePopup"  @click.stop @mousewheel.prevent @touchmove.prevent>
        <img src="../assets/x.png" @click="popupHide">
        <div>Approve Contacts spent bCART to mint</div>
        <div @click="mint()">APPROVE</div>
    </div>

    <div v-if="mintPopup" class="mintPopup"  @click.stop @mousewheel.prevent @touchmove.prevent>
        <img src="../assets/x.png" @click="popupHide">
        <div>You have already minted a collection</div>
        <div @click="mintPopupHide">SUCCESS</div>
    </div>

    <div v-if="redeemPopup" class="mintPopup"  @click.stop @mousewheel.prevent @touchmove.prevent>
        <img src="../assets/x.png" @click="popupHide">
        <div>You have already earned {{ reddemAmount }} CART</div>
        <div @click="redeemPopupHide">SUCCESS</div>
    </div>

    <div v-if="transactionPopup" class="transactionPopup" @mousewheel.prevent @touchmove.prevent>
        <img src="../assets/x.png" @click="transactionPopupHide">
        <div class="started">
            <img src="../assets/clock-1.png">
            <span v-if="started">Transaction Submitted</span>
            <span v-else>Your transaction has started</span>
        </div>
        <div>
            <span>{{ time }}</span>
            <span> - </span>
            <img src="../assets/clock-2.png">
            <span> {{ seconds }} sec</span>
        </div>
    </div>

    <div v-if="popupBg" class="popupBg" @mousewheel.prevent @touchmove.prevent></div>
    <SelectConnectPopup :connect = connect></SelectConnectPopup>
  </div>
</template>

<script>
import SelectConnectPopup from "@/components/SelectConnectPopup.vue";
import Proxy from "./../proxy.js";
import { formatNum } from "./../decUtil.js";
import '../assets/style/font.css'
import axios from 'axios'

export default {
    name: "Home",
    components: {
        SelectConnectPopup,
    },
    data() {
        return {
            total: 1,
            currentPage: 1,
            balanceOfAward: null,
            clientWidth: document.body.clientWidth,
            redeemPopup: false,
            reddemAmount: 0,
            fontSize: 0.9375,
            commonIndex: -1,
            rareIndex: -1,
            lengendaryIndex: -1,
            started: false,
            seconds: 0,
            time: 0,
            transactionPopup: false,
            approvePopup: false,
            mintPopup:false,
            rerity: null,
            popupBg: false,
            cartBalance: null,
            staking: false,
            prompt: false,
            loding: false,
            connect: false,
            cartStakedNum: 0,
            cartAmount: 0,
            ratio: 0.25,
            type: 'stake',
            popupShow: false,
            cartStaked: 0,
            bcart: 0,
            bcartInt: 0,
            minHeight: 0,
            stakeAmount: null,
            wdAmount: null,

            commonLeft: 300,
            rareLeft: 50,
            lengendaryLeft: 10,

            myCollects: [],
            recentDrop: [],
            recentDropTotal: [],

            recentCommonNft: null,
            recentCommontokenId: null,
            
            recentRareNft: null,
            recentRaretokenId: null,

            recentLengendaryNft: null,
            recentLengendarytokenId: null,
        }
    },

    created() {
        var that = this;
        if(window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                that.getData(false)
            })
        }
        
    },

    computed: {
        provider() {
            return this.$store.state.navMenu.provider;
        }
    },

    activated() {
        var innerHeight = window.innerHeight
        var innerWidth = window.innerWidth
        if(innerHeight < 1000) {
            innerHeight = 1000
        }

        if(innerWidth < 1480) {
            this.minHeight = innerHeight - 189
        } else {
            this.minHeight = innerHeight - 326
        }
        this.getData(true)
    },

    watch: {
        provider() {
            this.$nextTick(function () {
                this.getData(false)
            })
        },

        // fontSize() {
        //     this.$nextTick(function () {
        //         var heigth = parseFloat(document.documentElement.style.fontSize) * 2.5625
        //         if((this.$refs.cartStaked.offsetHeight > heigth || this.$refs.bcart.offsetHeight > heigth) && this.fontSize >= 0.375){
        //             this.fontSize = this.fontSize - 0.01
        //         }
        //     })
        // },

        // cartStaked() {
        //     this.$nextTick(function () {
        //         var heigth = parseFloat(document.documentElement.style.fontSize) * 2.5625
        //         if(this.$refs.cartStaked.offsetHeight > heigth) {
        //             this.fontSize = this.fontSize - 0.01
        //         }
        //     })
        // },

        // bcart() {
        //     this.$nextTick(function () {
        //         var heigth = parseFloat(document.documentElement.style.fontSize) * 2.5625
        //         if(this.$refs.bcart.offsetHeight > heigth) {
        //             this.fontSize = this.fontSize - 0.01
        //         }
        //     })
        // },

        clientWidth() {
            this.$nextTick(function () {
                var innerHeight = window.innerHeight
                var innerWidth = window.innerWidth
                if(innerHeight < 1000) {
                    innerHeight = 1000
                }

                if(innerWidth < 1480) {
                    this.minHeight = innerHeight - 189.35
                } else {
                    this.minHeight = innerHeight - 326
                }
            })
        }
    },

    mounted(){
        let that = this;
        window.onresize =() =>{
            return (()=>{
                window.clientWidth = document.body.clientWidth;
                that.clientWidth = window.clientWidth;
            })()
        };
    },

    methods: {
        getData(first) {
            clearInterval(this.refreshCartNum)
            this.refreshCartNum = null
            this.myCollects = []
            Proxy.initWeb3Account((web3, acc) => {
                console.log("my account address:" + acc)
                if(acc) {
                    this.connect = true
                    this.account = acc
                    var accLeft = acc.substring(0, 8)
                    var accRight = acc.substr(-4)
                    this.acc = accLeft + '....' + accRight
                    this.web3 = web3
                    this.initContact(first);
                    this.getCart();
                } else {
                    this.connect = false
                }
            });
        },

        refreshCart() {
            var that = this
            if(this.refreshCartNum) {
                return
            } else {
                this.refreshCartNum = setInterval(function(){
                    if(that.connect) {
                        that.myStake()
                    }
                },20000)
            }
        },

        async getCart() {
          await Proxy.getToken().then(r => {
            return r.balanceOf(this.account)
          }).then((r) => {
            this.cartBalance = this.web3.utils.fromWei(r.toString())
          })
        },

        async initContact(first) {
          this.token = await Proxy.getToken();
          this.cart = await Proxy.getToken();
        //  this.nft =  await Proxy.getERC721TokenAt("0x5FbDB2315678afecb367f032d93F642f64180aa3");
          this.nft = await Proxy.getNFTMock();
          this.farmNft = await Proxy.getFarmNFT();
          this.farmbCart = await Proxy.getFarmbCart();
          this.getBalance();
          this.myStake();
          if(first) {
              this.numInfo(true);
          }
          this.myNfts();
          this.refreshCart()
        },

        getBalance() {
          this.cart.balanceOf(this.account).then((r) => {
            this.cartAmount = this.web3.utils.fromWei(r.toString())
          })

          this.token.balanceOf(this.farmNft.address).then((r) => {
            this.balanceOfAward = this.web3.utils.fromWei(r);
          })
        },

        myStake() {
          this.farmbCart.myStake(this.account).then(r => {
            this.cartStaked = formatNum(this.web3.utils.fromWei(r[0]), 2);
            this.cartStakedNum = this.web3.utils.fromWei(r[0]) 
            this.bcart = formatNum(this.web3.utils.fromWei(r[1]), 2);
            this.bcartInt = parseInt(this.web3.utils.fromWei(r[1]))
            
          })
          
        },

        numInfo(recentNft) {
          this.farmNft.numInfo().then(r => {
            if(recentNft) {
                this.recentDrop = []
                this.recentDropTotal = []
                var refreshNft = []
                this.recentNft(r[0].toNumber() - 1, r[1].toNumber() - 1, r[2].toNumber() -1, refreshNft)
            }
            this.commonLeft = 300 - r[0].toNumber();
            this.rareLeft = 50 - r[1].toNumber();
            this.lengendaryLeft =  10 - r[2].toNumber();
          })
        },

        myNfts() {
          //0x19d4f1012008a0d89ae9f3a54ca1f88feb69bed8
          this.farmNft.myNfts(this.account).then( async (nfts) => {
            console.log("my nfts len:" + nfts.length);
            for(let i = nfts.length - 1; i>=0; i--) {
              let nftInfo = await this.farmNft.nftInfos(nfts[i]);
              if(!nftInfo.redeemed) {
                await this.myCollects.push({
                  "tokenId":nfts[i],
                  "detail": "https://cryptoart.ai/gallery/token?id=" + nfts[i],
                  isVideo: -1
                })
              }
            }
            for(let i = 0; i < this.myCollects.length; i++) {
              this.listMyNft(this.myCollects[i].tokenId, 'fatch', i);
            }
          })
        },

        async listMyNft(tokenId, type, index) {
          console.log("my nfts tokenId:" + tokenId);

          // console.log(nftInfo.rerity.toNumber())
          // console.log("redeemed:" + nftInfo.redeemed)
          //   let imgURL = await this.getNFTImg(tokenId);
          let isNew = this.isNewTokenId(tokenId)
          let data = {"tokenNum": tokenId.toNumber(), "ethAddress": ""}

          axios.post('https://api.cryptoart.ai/api/artwork/token/detail', data).then(res=>{
            let imgURL
            let isVideo
            if(res.data.data.metadataContentType) {
                isVideo = res.data.data.metadataContentType.indexOf('video')
                if(isVideo == -1){
                    imgURL = res.data.data.shareUrl
                } else {
                    imgURL = res.data.data.ossUrl
                }
            } else {
                isVideo = -1
                imgURL = res.data.data.shareUrl
            }

            if(type == 'fatch') {
                this.myCollects[index].img = imgURL
                this.myCollects[index].isVideo = isVideo
                this.myCollects[index].isNew = isNew
                this.myCollects.sort()
            } else if (type == 'unshift') {
                this.myCollects.unshift({
                    "tokenId": tokenId,
                    "img": imgURL,
                    "isNew": isNew,
                    "detail": "https://cryptoart.ai/gallery/token?id=" + tokenId,
                    isVideo: isVideo
                })
                this.myCollects.sort()
            }
          })
        },

        async getNFTImg(tokenId) {
          let metaUrl = await this.nft.tokenURI(tokenId);
            console.log(metaUrl);
          let meta = await axios.get(metaUrl)
          return  meta.data.image;
        },

        async recentNft(commonIndex, rareIndex, lengendaryIndex, refreshNft) {
            console.log('recentNft')
          this.loding = true
          let token1Ids = await this.farmNft.tokenIds(1);
          let token2Ids = await this.farmNft.tokenIds(2);
          let token3Ids = await this.farmNft.tokenIds(3);

          if (lengendaryIndex >= 0) {
            refreshNft.push(await this.getLengendary(lengendaryIndex,token3Ids))
            this.lengendaryIndex = lengendaryIndex - 1
          }

          if (rareIndex >= 0  && refreshNft.length < 10) {
            refreshNft.push(await this.getRare(rareIndex, token2Ids))
            this.rareIndex = rareIndex - 1
            if(this.rareIndex >= 0  && refreshNft.length < 10){
                refreshNft.push(await this.getRare(this.rareIndex, token2Ids))
                this.rareIndex = this.rareIndex - 1
                if(this.rareIndex >= 0  && refreshNft.length < 10){
                    refreshNft.push(await this.getRare(this.rareIndex, token2Ids))
                    this.rareIndex = this.rareIndex - 1
                }
            }
          }

          if (commonIndex >= 0 ) {
            var i = 10 - refreshNft.length
            this.commonIndex = commonIndex
            for( i > 0; i--;) {
                if(this.commonIndex >= 0){
                    refreshNft.push(await this.getCommon(this.commonIndex, token1Ids))
                    this.commonIndex = this.commonIndex - 1
                }
            }
          }
          if(refreshNft.length < 10 && (this.commonIndex >= 0 || this.lengendaryIndex >= 0 || this.rareIndex >= 0)){
            this.recentNft(this.commonIndex, this.rareIndex, this.lengendaryIndex, refreshNft);
          } else if(this.commonIndex >= 0 || this.lengendaryIndex >= 0 || this.rareIndex >= 0) {
            this.recentDropTotal = this.recentDropTotal.concat(refreshNft)
            var lodingNft = []
            this.recentNft(this.commonIndex, this.rareIndex, this.lengendaryIndex, lodingNft);
          } else {
            this.loding = false
            this.recentDropTotal = this.recentDropTotal.concat(refreshNft)
            this.total = this.recentDropTotal.length
            this.getRecentDropNftImg()
          }
        },

        getRecentDropNftImg() {
            let recentDropTotal = this.recentDropTotal
            for(let i=0; i<recentDropTotal.length; i++) {
                let data = {"tokenNum": recentDropTotal[i].tokenId.toNumber(), "ethAddress": ""}
                axios.post('https://api.cryptoart.ai/api/artwork/token/detail', data).then(res=>{
                    // this.recentDropTotal[i].img = res.data.data.ossUrl
                    // if(res.data.data.metadataContentType) {
                    //     this.recentDropTotal[i].isVideo = res.data.data.metadataContentType.indexOf('video')
                    // }

                    if(res.data.data.metadataContentType) {
                        let isVideo = res.data.data.metadataContentType.indexOf('video')
                        if(isVideo == -1){
                            this.recentDropTotal[i].img = res.data.data.shareUrl
                        } else {
                            this.recentDropTotal[i].isVideo = isVideo
                            this.recentDropTotal[i].img = res.data.data.ossUrl
                        }
                    } else {
                        // isVideo = -1
                        this.recentDropTotal[i].img = res.data.data.shareUrl
                    }

                })
            }
            this.pagination(1)
        },

        pagination(index) {
            this.recentDrop = []
            this.recentDrop = this.recentDropTotal.slice((index-1)*10, index*10)
        },

        handleCurrentChange(val) {
            this.pagination(val)
        },

        ownerFormat(owner) {
            var accLeft = owner.substring(0, 4)
            var accRight = owner.substr(-4)
            var ownerFormat = accLeft + '....' + accRight
            return ownerFormat
        },

        async getLengendary(lengendaryIndex, tokenIds) {
            let recentLengendarytokenId = tokenIds[lengendaryIndex];
            let owner = await this.nft.ownerOf(recentLengendarytokenId)
            let ownerFormat = this.ownerFormat(owner)
            // return {"tokenId": recentLengendarytokenId, "img": this.getNftImg(recentLengendarytokenId)}
            // try {
            //     let recentLengendaryNft = await this.getNFTImg(tokenIds[lengendaryIndex]);
            //     return {"tokenId": recentLengendarytokenId, "img": recentLengendaryNft, 'owner': ownerFormat}
            // } catch {
                return {"tokenId": recentLengendarytokenId, "img": require('../assets/loding-bg.png'), 'owner': ownerFormat, 'isVideo': -1}
            // }
        },

        async getRare(rareIndex, tokenIds) {
            let recentRaretokenId = tokenIds[rareIndex];
            let owner = await this.nft.ownerOf(recentRaretokenId)
            let ownerFormat = this.ownerFormat(owner)
            // return {"tokenId": recentRaretokenId, "img": this.getNftImg(recentRaretokenId)}
            // try {
            //     let recentRareNft = await this.getNFTImg(tokenIds[rareIndex])
            //     return {"tokenId": recentRaretokenId, "img": recentRareNft, 'owner': ownerFormat}
            // } catch {
                return {"tokenId": recentRaretokenId, "img": require('../assets/loding-bg.png'), 'owner': ownerFormat, 'isVideo': -1}
            // }
        },

        async getCommon(commonIndex, tokenIds) {
            let recentCommontokenId = tokenIds[commonIndex];
            let owner = await this.nft.ownerOf(recentCommontokenId)
            let ownerFormat = this.ownerFormat(owner)
            // return {"tokenId": recentCommontokenId, "img": this.getNftImg(recentCommontokenId)}
            // try {
            //     let recentCommonNft = await this.getNFTImg(tokenIds[commonIndex])
            //     return {"tokenId": recentCommontokenId, "img": recentCommonNft, 'owner': ownerFormat}
            // } catch {
                return {"tokenId": recentCommontokenId, "img": require('../assets/loding-bg.png'), 'owner': ownerFormat, 'isVideo': -1}
            // }
        },

        pervious() {
            this.$refs.pageGroup.prev()
        },

        next() {
            this.$refs.pageGroup.next()
        },

        more() {
            this.currentPage = this.currentPage + 1
            var more = this.recentDropTotal.slice((this.currentPage-1)*10, this.currentPage*10)
            this.recentDrop = this.recentDrop.concat(more)
        },

        // getNftImg(id) {
        //     for(let i=0; i<this.nftImgs.length; i++) {
        //         if(this.nftImgs[i].id == id) {
        //             return this.nftImgs[i].imgURL
        //         }
        //     }
        // },

        deposit() {
          if(this.stakeAmount < 2000) {
              this.prompt = true
          } else {
              this.staking = true
              this.cart.send(this.farmbCart.address,
               this.web3.utils.toWei(this.stakeAmount.toString()), "0x", {from: this.account})
               .on('transactionHash', () => {
                 this.getTime()
                 this.popup();
               }).catch(() =>{
                 this.staking = false
               }).then(() => {
                 this.clearTiming();
                 this.myStake();
                 this.staking = false
                //  this.notify.close()
               })
          }
        },

        withdraw() {
          this.staking = true
          this.farmbCart.withdraw(this.web3.utils.toWei(this.wdAmount.toString()), {from: this.account})
              .on('transactionHash', () => {
               this.getTime();
               this.popup();
             }).catch(() =>{
               this.staking = false
             }).then(() => {
               this.clearTiming();
               this.myStake();
               this.staking = false
             })
        },

        mint() {
          this.approvePopup = false
          this.popupBg = false
          this.farmNft.mint(this.rerity, {from: this.account}).on('transactionHash', () => {
            this.getTime()
          }).then((r) => {
            this.clearTiming();
            this.popupBg = true
            this.mintPopup = true;
            this.numInfo();
            this.myStake();
            this.listMyNft(r.logs[0].args.tokenId, 'unshift');
          })
        },

        isNewTokenId(tokenId) {
          let show = localStorage.getItem('SHOWED_' + tokenId);
          if(show) {
            return false;
          }
          localStorage.setItem('SHOWED_' + tokenId, 1);
          return true;
        },
        reddem(tokenId) {
          var cartBalance = this.cartBalance
          this.nft.safeTransferFrom(this.account, this.farmNft.address, tokenId,
           {from: this.account}).on('transactionHash', () => {
            this.getTime()
           }).then( async () => {
            // 更新余额, TODO: 对比余额动画
            this.clearTiming();
            this.numInfo();
            this.myStake();
            this.getBalance();
            await this.getCart();
            this.reddemAmount = this.cartBalance - cartBalance;
            this.popupBg = true;
            this.redeemPopup = true;
            for(let i = 0; i < this.myCollects.length; i++) {
                console.log(this.myCollects[i].tokenId)
                if(this.myCollects[i].tokenId == tokenId){
                    this.myCollects.splice(i,1)
                }
            }
          })
        },

        getTime() {
            var m;
            var date = new Date()
            var hours = date.getHours()
            var minutes = date.getMinutes()
            var that = this
            this.seconds = 0
            this.started = false
            this.transactionPopup = true
            if(hours > 12) {
                m = 'PM'
                hours = hours -12
            } else {
                m = 'AM'
            }
            if(minutes < 10) {
                minutes = '0' + minutes
            }
            this.time = hours + ':' + minutes + m

            this.timing = setInterval(function(){
                that.seconds = that.seconds + 1;
            },1000)
        },

        clearTiming() {
            this.started = true
            clearInterval(this.timing)
        },

        popup() {
            clearInterval(this.timing)
            this.popupShow = !this.popupShow
            if(this.popupShow) {
              this.popupBg = true
              this.selectRatio(this.ratio)
            } else {
                this.prompt = false
                this.popupBg = false
            }
        },

        popupHide() {
            this.redeemPopup = false
            this.mintPopup = false
            this.approvePopup = false
            this.popupShow = false
            this.prompt = false
            this.popupBg = false
        },

        mintPopupHide() {
            this.popupBg = false
            this.mintPopup = false
        },

        redeemPopupHide() {
            this.popupBg = false
            this.redeemPopup = false
        },

        transactionPopupHide() {
            this.transactionPopup = false
        },

        showApprovePopup(rerity) {
            this.popupBg = true
            this.rerity = rerity
            this.approvePopup = true
        },

        switchType(type) {
            this.type = type
            this.selectRatio(this.ratio)
        },

        selectRatio(ratio) {
            this.ratio = ratio
            if (this.type == 'stake') {
              this.stakeAmount = this.cartAmount * this.ratio
            } else {
              this.wdAmount = this.cartStakedNum * this.ratio
            }
        },

        showConnect() {
            this.$store.commit('showSelectConnectPopup')
        },

        toCryptoart() {
            window.open('https://cryptoart.ai/',"_blank")
        },

        toTutortal() {
            window.open('https://cryptoart-ai.medium.com/cart-finance-tutorial-6d0dca9ac640',"_blank")
        },

        logout() {
            clearInterval(this.refreshCartNum)
            this.refreshCartNum = null
            this.$store.commit('updateProviderType',  '')
            this.connect = false
            this.account = null
            this.acc = null
            this.web3 = null
            this.myCollects = []
            this.cartStaked = 0
            this.bcart = 0
            this.bcartInt = 0
            this.balanceOfAward = 0
        }
    }
};
</script>

<style scoped>
    .home {
        min-width: 92.5rem;
        background-image: linear-gradient(to bottom , #6d3d1d, #2b0a79, #0b024d);
        padding: 4.375rem 0 0px;
    }

    .connected div:first-child img,
    .connect img {
        width: 1.625rem;
        margin-right: .875rem;
    }

    .connected div:first-child {
        display: flex;
        align-items: center;
        min-width: 7.5rem;
        height: 2.625rem;
        background: #4D4D4D;
        border-radius: 2.625rem;
        line-height: 2.625rem;
        box-sizing: border-box;
        padding: 0 1.25rem;
        font-size: .9375rem;
        font-family: din_regular;
        font-weight: 400;
        color: #FFFFFF;
    }

    .connected div:last-child {
        font-size: .9375rem;
        box-sizing: border-box;
        padding: 0 1.25rem;
        padding-left: .75rem;
        height: 2.625rem;
        font-family: din_regular;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 2.625rem;
    }

    .connected div:last-child span {
        color: #4D4D4D;
    }

    .connect {
        width: 9.375rem;
        height: 2.625rem;
        display: flex;
        align-items: center;
        background: #FA6401;
        line-height: 2.625rem;
        border-radius: 2.625rem;
        justify-content: center;
        box-sizing: border-box;
        cursor: pointer;
        font-size: .875rem;
        font-weight: 900;
        color: #FFFFFF;
        font-family: din_black;
    }

    .connected {
        background-color: #1a1a1a;
        border-radius: 2.625rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        cursor: pointer;
    }

    .content {
        width: 90rem;
        margin: auto;
        border-radius: 1.25rem;
        padding: 3.375rem 5rem;
        padding-bottom: 4.625rem;
        background-color: #000000;
        box-sizing: border-box;
    }

    .nav {
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
    }

    .nav > div:first-child {
        height: 6.125rem;
        display: flex;
        align-items: center;
    }

    .nav > div:first-child img {
        width: 9.5625rem;
        cursor: pointer;
    }

    .nav > div:first-child div {
        background-color: #1a1a1a;
        width: 1.6875rem;
        height: 1.25rem;
        line-height: 1.25rem;
        font-size: .875rem;
        font-family: din_regular;
        font-weight: 400;
        color: #4D4D4D;
        border-radius: 1.25rem;
        margin-left: 1.875rem;
    }

    .allCartAmount {
        display: flex;
        align-items: center;
        position: relative;
    }

    .cartDate {
        display: flex;
        background: #1a1a1a;
        border-radius: 1.5625rem;
        align-items: center;
    }

    .tutorial {
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
        align-items: center;
    }

    .tutorial > div {
        display: flex;
        background: #1a1a1a;
        border-radius: 1.5625rem;
        align-items: center;
    }

    .navRight {
        display: flex;
    }

    .cartStaked,
    .cart {
        height: 2.625rem;
        border-radius: 1.5625rem;
        font-size: .875rem;
        padding: 0 1.875rem;
        box-sizing: border-box;
        font-family: din_regular;
        font-weight: 400;
        color: #FF6400;
        line-height: 2.625rem;
    }

    .cartLine {
        width: 0px;
        height: .8125rem;
        border: 1px solid #4d4d4d;
    }

    .cartStaked span,
    .cart span {
        color: #4D4D4D;
        margin-left: .5rem;
    }

    .line {
        width: 1px;
        height: 2.625rem;
        margin: 0 2rem;
        background-color: #FA6401;
    }

    .manegeStake {
        width: 10rem;
        height: 2.625rem;
        border-radius: 2.625rem;
        line-height: 2.625rem;
        background: #4d4d4d;
        cursor: not-allowed;
        font-size: .875rem;
        font-weight: 900;
        font-family: din_black;
        color: #FFFFFF;
    }

    .tutorialButton {
        width: 10rem;
        height: 2.625rem;
        border-radius: 2.625rem;
        line-height: 2.625rem;
        background: #4d4d4d;
        cursor: not-allowed;
        font-size: .875rem;
        font-weight: 900;
        font-family: din_black;
        color: #FFFFFF;
    }

    .connectManage {
        background: #FA6401;
        cursor: pointer;
    }

    .manege {
        top: 4.0625rem;
        right: 0;
        position: absolute;
        width: 31.25rem;
        padding: 1.875rem;
        background-color: #262626;
        box-sizing: border-box;
        border-radius: .625rem;
        z-index: 10;
    }

    .manege > img,
    .approvePopup > img,
    .transactionPopup > img,
    .mintPopup > img {
        width: 1.75rem;
        position: absolute;
        top: -.875rem;
        right: -.875rem;
        cursor: pointer;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title div:first-child {
        font-size: 1.125rem;
        font-weight: 900;
        color: #FF6400;
        font-family: din_black;
    }

    .title div:last-child {
        display: flex;
        align-items: center;
    }

    .title div:last-child span {
        font-size: .875rem;
        font-family: din_regular;
        font-weight: 400;
        color: #808080;
        margin-right: .625rem;
    }

    .title div:last-child img {
        width: 3.125rem;
        cursor: pointer;
    }

    .stakeCart,
    .earn,
    .stakeDetails {
        text-align: left;
    }

    .stakeCart {
        font-size: .875rem;
        font-family: din_regular;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 1.875rem;
    }

    .earn {
        font-size: 1rem;
        font-weight: bold;
        color: #FF6400;
        margin-top: .625rem;
        font-family: din_bold;
    }

    .earn span {
        display: inline-block;
        width: .75rem;
    }

    .stakeDetails {
        font-size: .875rem;
        font-family: din_regular;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 1.125rem;
    }

    .available {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.875rem;
    }

    .available > div:last-child {
        display: flex;
        align-items: center;
        margin-left: .625rem;
    }

    .available > div:first-child span {
        font-size: 1rem;
        font-family: din_regular;
        font-weight: 400;
        color: #4D4D4D;
    }

    .available > div:first-child b {
        font-size: 1.125rem;
        font-family: din_regular;
        font-weight: 400;
        color: #ff6400;
        word-break:break-all;
    }

    .available > div:last-child {
        display: flex;
        align-items: center;
        height: 1.875rem;
        border-radius: 1.875rem;
    }

    .available > div:last-child div {
        background: #000;
        width: 4rem;
        height: 2rem;
        font-size: 1rem;
        font-family: din_regular;
        font-weight: 400;
        color: #4D4D4D;
        line-height: 2rem;
        text-align: center;
        cursor: pointer;
    }

    .available > div:last-child div:first-child {
        border-radius: 2rem 0 0 2rem;
    }

    .available > div:last-child div:last-child {
        border-radius: 0 2rem 2rem 0;
    }

    .active {
        background: #FA6401 !important;
        color: #fff !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    .amount input {
        width: 27.5rem;
        height: 2rem;
        background: #333;
        border-radius: 2rem;
        outline:none;
        padding: 0 1.25rem;
        box-sizing: border-box;
        margin-top: 1.5rem;
        font-size: 1rem;
        font-family: din_regular;
        font-weight: 400;
        color: #FFFFFF;
        border: none;
    }

    .amount div {
        position: absolute;
        margin: .3125rem 1.25rem;
        color: #FA6401;
        font-size: .875rem;
        font-family: din_regular;
        font-weight: 600;
    }

    input::-webkit-input-placeholder {
        font-size: 1rem;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #4d4d4d;
	}

    .stakeButton {
        width: 9.125rem;
        height: 2.75rem;
        background: #FA6401;
        border-radius: 1.875rem;
        font-size: .875rem;
        font-weight: 900;
        color: #FFFFFF;
        line-height: 2.75rem;
        cursor: pointer;
        margin: auto;
        font-family: din_black;
        margin-top: 1.375rem;
    }

    .staking {
        background: #717171;
        color: #fff;
        cursor: not-allowed;
        box-shadow: none;
    }

    .mintTitle {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        font-family: din_black;
        align-items: center;
        position: relative;
    }

    .mintTitle {
        margin: 4.375rem auto 5rem;
    }

    .myTitle,
    .recentTitle {
        margin: 5rem auto;
    }

    .myTitle,
    .recentTitle {
        margin-top: 2.5rem;
    }

    .mintTitle div:first-child {
        width: 100%;
        border-bottom: 1px solid #4d4d4d;
    }

    .mintTitle div:last-child {
        position: absolute;
        height: 2.125rem;
        background-color: #000;
        line-height: 2.125rem;
        font-size: 1.4375rem;
        padding: 0 .875rem;
        font-weight: 900;
        color: #FF6400;
        left: 11rem;
    }

    .mineList {
        width: 100%;
        box-sizing: border-box;
        padding: 0 11.625rem;
        display: flex;
        justify-content: space-between;
    }

    .mineList > div {
        width: 15.625rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: .625rem;
        overflow: hidden;
        position: relative;
        background-color: #1a1a1a;
        margin-bottom: 2.5rem;
    }

    .mintImg {
        width: 15.625rem;
        height: 18.875rem;
        object-fit: cover;
        border-radius: .625rem;
    }

    .imgBg {
        position: absolute;
        /* top: 8px;
        left: .625rem; */
    }

    .imgBgP {
        width: 15.625rem;
        object-fit: cover;
    }

    .mineList > div > div {
        display: flex;
        position: relative;
        justify-content: space-between;
        width: 100%;
        height: 4rem;
        background: #1a1a1a;
    }

    .mineInfo {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
    }

    .mineInfo div {
        display: flex;
        flex-direction: column;
        width: 5.1875rem;
        margin: .9375rem 0;
        line-height: 1;
    }

    .mineInfo div:nth-child(2) {
        border-left: 1px solid #343434;
        border-right: 1px solid #343434;
    }

    .mineInfo div b {
        font-size: 1.125rem;
        font-family: din_light;
        font-weight: 400;
        color: #FF6400;
    }

    .mineInfo div span {
        font-size: 1rem;
        font-family: din_regular;
        font-weight: 300;
        color: #4D4D4D;
    }

    .soldOut,
    .mine {
        position: absolute;
        top: -4.0625rem;
        left: 4.375rem;
        width: 6.25rem;
        height: 1.875rem;
        border-radius: 1.875rem;
        line-height: 1.875rem;
        font-size: .875rem;
        font-weight: 900;
        font-family: din_black;
        color: #FFFFFF;
    }
    
    .mine {
        background: #FA6401;
        cursor: pointer;
    }

    .soldOut {
        background: #4d4d4d;
        cursor: not-allowed
    }

    .approvePopup,
    .mintPopup {
        width: 18.75rem;
        height: 11.25rem;
        background-color: #262626;
        position: fixed;
        display: flex;
        align-items: center;
        flex-direction: column;
        left: 50%;
        top: 50%;
        margin-left: -9.375rem;
        margin-top: -5.625rem;
        border-radius: .625rem;
        z-index: 10;
    }

    .approvePopup div,
    .mintPopup div {
        width: 11.875rem;
        font-size: 1rem;
        font-weight: bold;
        color: #FF6400;
        margin-top: 2.125rem;
    }

    .approvePopup div:last-child,
    .mintPopup div:last-child {
        width: 9.125rem;
        height: 2.6875rem;
        line-height: 2.6875rem;
        font-size: .875rem;
        font-weight: 900;
        color: #FFFFFF;
        margin-top: 1.5rem;
        background: #FA6401;
        border-radius: 2.6875rem;
        margin-bottom: 2.1875rem;
        cursor: pointer;
    }

    .collectionContent,
    .recentDropContent {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .mycollection:after,
    .recentDrop:after {
        content: ""; 
        width: 15.625rem;
    }

    .mycollection,
    .recentDrop {
        width: 100%;
        box-sizing: border-box;
        padding: 0 11.625rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .mycollection > div,
    .recentDrop > a {
        width: 15.625rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: .625rem;
        overflow: hidden;
        position: relative;
        background-color: #1a1a1a;
        margin-bottom: 2.5rem;
    }

    .recentDrop > a {
        height: 18.875rem;
    }

    .pagination {
        display: flex;
        flex-direction: column;
        height: 44.375rem;
        margin-bottom: 4.0625rem;
    }

    .pagination .tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 11.625rem;
        padding: 0 2.4375rem;
        border-bottom: 1px solid #3A1700;
    }

    .pagination .th {
        height: 2.25rem;
        font-family: din_medium;
        font-size: 1.125rem;
        font-weight: 500;
        color: #FF6400;
    }

    .pagination .td {
        height: 4.125rem;
        font-size: 1.125rem;
        font-family: din_regular;
        font-weight: 400;
        color: #808080;
    }

    .pagination .th div {
        /* height: 2.75rem; */
    }

    .pagination .tr div {
        width: 6.25rem;
    }

    .pagination .owner {
        
    }

    .pagination .edition {
        margin-right: 9.375rem;
    }

    .pagination .collection {
        width: 6.25rem;
    }

    .pagination .td .collection{
        height: 2.75rem;
        object-fit: cover;
    }

    .pagination .collection img {
        width: 3.9375rem;
        height: 100%;
        object-fit: cover;
    }

    .pagination .collection video {
        width: 3.9375rem;
        height: 100%;
        object-fit: cover;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container img {
        cursor: pointer;
    }

    .container .disabled {
        cursor: not-allowed;
    }

    /deep/ .el-pagination {
        font-size: 1.25rem;
        padding: 0 2.125rem;
    }

    /deep/ .el-pager li {
        background: none;
        font-size: 1.25rem;
        font-size: 1.25rem;
        font-family: din_light;
        font-weight: 300;
        color: #4D4D4D;
        min-width: 3.75rem;
    }

    /deep/ .el-pager li:hover {
        color: #ff6400;
    }

    /deep/ .el-pager li.active {
        color: #ff6400;
        cursor: default;
    }

    /deep/ .el-icon-more:before {
        content: "...";
    }

    .collectionButton {
        width: 100%;
        height: 4rem;
        display: flex;
        padding: 0 1.5rem;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
    }

    .collectionButton > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 .8125rem;
        padding-left: 1rem;
        width: 6.9375rem;
        height: 1.9375rem;
        background-color: #000;
        box-sizing: border-box;
        border-radius: 1.9375rem;
        font-size: .875rem;
        font-family: din_black;
        font-weight: 900;
        color: #FF6400;
        cursor: pointer;
    }

    .collectionButton a {
        display: block;
        text-decoration: none;
        width: 5.0625rem;
        height: 1.9375rem;
        line-height: 1.9375rem;
        background-color: #000;
        border-radius: 1.9375rem;
        font-size: .875rem;
        font-family: din_black;
        font-weight: 900;
        color: #FF6400;
    }

    .recentDropImg {
        width: 15.625rem;
        border-radius: .625rem;
        height: 18.875rem;
        overflow: hidden;
    }

    .recentDropImg img:last-child {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .collectionImg {
        border-radius: .625rem;
        overflow: hidden;
        height: 18.875rem;
        position: relative;
    }

    .new {
        position: absolute;
        bottom: .5625rem;
        right: .625rem;
        z-index: 2;
    }

    .bottomMenu {
        width: 87.5rem;
        margin: 4.375rem auto 0;
        padding: 0 5rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .bottomMenu > img {
        width: 21.8125rem;
        cursor: pointer;
    }

    .bottomMenu > div {
        display: flex;
        align-items: center;
    }

    .info {
        width: 26.125rem;
        display: flex;
        border-left: .125rem solid #68438C;
        border-right: .125rem solid #68438C;
        margin-right: 3.125rem;
        justify-content: space-between;
        /* padding: 6px 0; */
        height: 5.375rem;
    }

    .info > div {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .info > div span {
        font-size: .875rem;
        font-weight: 900;
        color: #FF6400;
        margin-bottom: .625rem;
        font-family: din_black;
    }

    .info > div a {
        font-size: .875rem;
        font-family: din_light;
        font-weight: 900;
        color: #fff;
        text-decoration: none;
    }

    .info > div .nft {
        margin-bottom: 0.2rem;
    }

    .info > div:first-child {
        margin-left: 2.875rem;
    }

    .info > div:last-child {
        margin: 0 2.875rem;
    }

    .icon {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 23.0625rem;
        height: 5.375rem;
    }

    .icon div img {
        width: 3.125rem;
    }

    .icon span {
        font-size: .875rem;
        font-weight: 900;
        color: #FF6400;
        margin-bottom: .75rem;
        font-family: din_black;
    }

    .icon div {
        display: flex;
        justify-content: space-between;
    }

    .transactionPopup {
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 2.5rem;
        bottom: 2.8125rem;
        width: 18.8125rem;
        height: 5.0625rem;
        border-radius: .625rem;
        background-color: #262626;
    }

    .started {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        color: #FF6400;
        margin-left: 1.125rem;
        margin-top: 1.0625rem;
        font-family: din_black;
    }

    .started img {
        width: 1.375rem;
    }

    .started span {
        margin-left: .6875rem;
    }

    .transactionPopup div:last-child {
        display: flex;
        font-size: 1rem;
        font-weight: 300;
        color: #808080;
        margin-left: 3.25rem;
        margin-top: .4375rem;
        align-items: flex-end;
    }

    .transactionPopup div:last-child span:nth-child(2) {
        margin: 0 .3125rem;
    }

    .transactionPopup div:last-child span {
        margin-top: 0px;
    }

    .transactionPopup div:last-child img {
        margin-right: .1875rem;
        width: 1rem;
    }

    .popupBg {
        z-index: 2;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);
        background: rgba(0,0,0,.33);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .loding {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loding img{
        width: 3rem;
        object-fit: cover;
    }

    .loding img {
        animation-name:loding;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .logoM {
        display: none;
    }

    .cartM {
        display: none;
    }

    .imgBgM {
        display: none;
    }

    .hideManegeM {
        display: none;
    }

    .tokenM {
        display: none;
    }

    .logoButtomM {
        display: none !important;
    }

    .connectM {
        display: none;
    }

    .logoButtomP {
        margin: 2.75rem auto 2.25rem;
    }

    .logoButtomP img {
        width: 12.8125rem;
    }
    @keyframes loding{
        from {
            transform: rotateZ(0deg);
        }

        to{
            transform: rotateZ(360deg);
        }
    }

    .more img {
        width: 1.875rem;
        cursor: pointer;
        object-fit: cover;
    }

    .more {
        display: none;
    }

    /* @media (max-width: 1480px) {
        .home {
            min-width: 1180px;
            width: 100%;
        }

        .content {
            width: 90%;
        }

        .allCartAmount {
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-end;
        }

        .navRight {
            margin-top: 1.5rem;
        }

        .manege {
            top: 120px;
        }
        
        .mineList {
            width: 100%;
            flex-wrap: wrap;
            padding: 0 11%;
        }

        .mintTitle div:last-child {
            left: 10%;
        }

        .pagination .tr {
            margin: 0 11%;
        }

        .mycollection, 
        .recentDrop {
            padding: 0 11%;
        }

        .bottomMenu {
            width: 90%;
            flex-direction: column;
            align-items: flex-start;
        }

        .bottomMenu > div {
            margin-top: 1.875rem;
        }

    }

    @media (max-width: 1330px) {
        .mineList > div:first-child,
        .mycollection > div:nth-child(2n + 1),
        .recentDrop > a:nth-child(2n + 1) {
            margin-right: 5rem;
        }
    } */

    @media (max-width: 750px) {
        .home {
            background: url('../assets/bg.png') 100% no-repeat;
            background-size: 100% 100%;
            padding: 4.0625rem 0 1.875rem;
        }

        .connectP {
            display: none;
        }

        .connectM {
            display: flex;
        }

        .logoM {
            display: inline-block;
        }

        .home {
            min-width: 0;
            width: 100%;
        }

        .content {
            width: 100%;
            padding: 0;
            padding-bottom: 2.4375rem;
            border-radius: .625rem;
        }

        .content > div {
            width: 85%;
            margin: auto;
        }

        .nav {
            flex-direction: column;
            font-size: .9375rem;
        }

        .nav > div:first-child {
            width: 100%;
            height: 100%;
            align-items: flex-end;
            justify-content: space-between;
            margin: 2.5rem 0;
        }

        .nav > div:first-child img {
            width: 15.625rem;
            object-fit: contain;
        }

        .allCartAmount {
            width: 100%;
            height: 100%;
            position: initial;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
        }

        .nav > div:first-child div {
            width: 1.6875rem;
            height: 1.25rem;
            line-height: 1.25rem;
            font-size: .875rem;
            border-radius: 1.25rem;
            margin-left: 1.875rem;
        }

        .connect {
            width: 9.375rem;
            height: 2.5625rem;
            font-size: .9375rem;
            border-radius: 2.625rem;
            line-height: 2.5625rem;
            justify-content: space-between;
            padding: 0 1.7rem;
        }

        .connect img {
            margin-right: 0;
            width: 1.4375rem;
        }

        .connected {
            width: 100%;
            border-radius: 2.625rem;
        }

        .connected > div:first-child {
            flex-grow: 2;
            padding-right: 0;
            height: 2.5625rem;
            font-size: .9375rem;
            border-radius: 2.5625rem;
            line-height: 2.5625rem;
            padding: 0 1.25rem;
        }

        .connected > div:first-child img {
            margin-right: 0;
            width: 1.4375rem;
        }

        .connected > div:first-child span {
            flex-grow: 2;
            text-align: center;
        }

        .connected > div:last-child {
            font-size: .9375rem;
            padding: 0 1.25rem;
            padding-left: .75rem;
            height: 2.5625rem;
            line-height: 2.5625rem;
        }

        .line {
            width: 100%;
            height: 1px;
            margin: 1.375rem 0;
        }

        .cartDate {
            flex-wrap: wrap;
        }

        .cartStaked,
        .cart {
            height: 2.125rem;
            border-radius: 2.5625rem;
            font-size: .9375rem;
            padding: 0px;
            box-sizing: border-box;
            font-family: din_regular;
            font-weight: 400;
            color: #FF6400;
            display: flex;
            line-height: 100%;
            flex-direction: column;
            width: 49%;
            margin-top: 1.625rem;
            justify-content: space-between;
        }

        .cartLine {
            height: 2.5625rem;
            margin-top: 1.625rem;
            border: 0.5px solid #4d4d4d;
        }

        .tutorial {
            margin-top: 1.5rem;
        }

        .tutorial div {
            flex-wrap: wrap;
        }

        .tutorialButton {
            width: 13.125rem;
            height: 2.5625rem;
            font-size: .9375rem;
            border-radius: 2.5625rem;
            line-height: 2.5625rem;
            margin: auto;
            margin-top: 1.4375rem;
            margin-bottom: 1.5625rem;
        }

        .cartM {
            display: flex;
            font-size: .9375rem;
            height: 2.5625rem;
            line-height: 2.5625rem;
            background-color: #1a1a1a;
            border-radius: 2.5625rem;
            justify-content: space-around;
            align-items: center;
            padding: 0 1.25rem;
            box-sizing: border-box;
        }

        .cartM div:nth-child(2) {
            box-sizing: border-box;
            height: 1.5625rem;
            border-left: 1px solid #4D4D4D;
            margin: 0 .625rem;
        }

        .cartM div {
            font-family: din_regular;
            font-weight: 400;
            color: #FF6400;
        }

        .cartM div span,
        .cartM span {
            color: #4D4D4D;
            margin-left: .3125rem;
        }

        .navRight {
            width: 100%;
            flex-direction: column;
        }

        .manegeStake {
            width: 13.125rem;
            height: 2.5625rem;
            font-size: .9375rem;
            border-radius: 2.5625rem;
            line-height: 2.5625rem;
            margin: auto;
            margin-top: 1.4375rem;
            margin-bottom: 1.5625rem;
        }

        .mintTitle {
            margin: 2.6875rem auto 2.5625rem;
        }

        .mintTitle div:last-child {
            left: 0;
            height: 2.125rem;
            line-height: 2.125rem;
            font-size: 1.3125rem;
            padding: 0 .4375rem;
            padding-left: 0;
        }

        .pagination {
            height: 100%;
            margin-bottom: 0;
        }

        .pagination .th {
            font-size: .9375rem;
            height: 2rem;
        }

        .pagination .tr {
            margin: 0;
            padding: 0;
        }

        .pagination .tr div {
            width: 6.25rem;
        }

        .pagination .tr .owner {
            text-align: left;
        }

        .pagination .td {
            height: 3.625rem;
            font-size: 1rem;
        }

        .pagination .td .collection {
            height: 2.4375rem;
        }

        .pagination .collection {
            width: 6.25rem;
            text-align: right;
        }

        .pagination .collection img {
            width: 3.4375rem;
        }

        .pagination .collection video {
            width: 3.4375rem;
        }

        .pagination .edition {
            margin-right: 0px;
        }

        .mineList > div:first-child,
        .mycollection > div,
        .recentDrop > a {
            margin-right: 0 !important;
        }

        .mineList,
        .mycollection,
        .recentDrop {
            padding: 0;
            flex-direction: column;
        }

        .mineList > div,
        .mycollection > div,
        .recentDrop > a {
            width: 100%;
            border-radius: .75rem;
            margin-bottom: 1.75rem;
        }

        .recentDrop > a {
            height: 10.9375rem;
        }

        .mineList > div > div {
            align-items: center;
            height: 4rem;
        }

        .mintImg {
            width: 100%;
            object-fit: cover;
            height: 10.9375rem;
            border-radius: .75rem;
        }

        .mycollection div {
            border-radius: .75rem;
        }

        .imgBgP {
            display: none;
        }

        .collectionImg {
            height: 10.9375rem;
        }

        .collectionButton {
            height: 4.0625rem;
            width: 100%;
            padding: 0 1.8125rem;
        }

        .collectionButton > div {
            width: 7.875rem;
            height: 1.875rem;
            margin-right: 1.0625rem;
            justify-content: center;
            padding: 0;
            font-size: .9375rem;
        }

        .collectionButton > div div {
            line-height: 0.9375;
            padding: 0;
            margin: 0;
        }

        .collectionButton a {
            width: 7.875rem;
            height: 1.875rem;
            font-size: .9375rem;
            line-height: 1.875rem;
            border-radius: 2rem;
        }

        .imgBgM {
            display: inline;
            width: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
        }

        .mine,
        .soldOut {
            position: initial;
            height: 1.875rem;
            width: 6.25rem;
            line-height: 1.75rem;
            font-size: .875rem;
            border-radius: 1.875rem;
            margin-right: .8125rem;
        }

        .mineInfo {
            width: 12.5rem;
        }

        .mineInfo div {
            width: 5.1875rem;
            margin: .9375rem 0;
            line-height: 1.2;
        }

        .mineInfo div b {
            font-size: 1.125rem;
        }

        .mineInfo div span {
            font-size: .875rem;
        }

        .loding {
            margin-top: 2.4375rem;
        }

        .loding img {
            width: 1.875rem;
        }

        .more {
            display: block;
            margin: 2.1875rem 0 0;
        }

        .more img {
            width: 1.875rem;
        }

        .bottomMenu {
            width: 85%;
            padding: 0;
            flex-direction: column;
            align-items: center;
            margin-top: 3.125rem;
        }

        .bottomMenu > img {
            width: 10.875rem;
            margin-bottom: 2.8125rem;
        }

        .bottomMenu > div {
            flex-direction: column;
            margin-top: 0;
            padding-top: 1.875rem;
            border-top: 1px solid #9824FF;
            width: 100%;
        }

        .bottomMenu > div:last-child {
            flex-direction: column;
            margin-top: 3.75rem;
            align-items: center;
            padding-top: 0;
            border-top: none;
            width: 100%;
        }

        .info {
            flex-direction: column;
            width: 100%;
            margin-right: 0;
            border: none;
        }

        .info > div {
            flex-direction: row;
        } 

        .info > div:first-child {
            margin-left: 0;
            align-items: center;
            margin-bottom: 1.75rem;
        }

        .info > div span {
            margin: 0;
            font-weight: 400;
            font-family: din_regular;
            width: 5rem;
            font-size: .875rem;

        }

        .info > div a {
            font-weight: 300;
            font-size: .875rem;
        }

        .info > div:last-child {
            margin-left: 0;
        }

        .info > div:last-child div {
            display: flex;
            flex-direction: column;
        }

        .icon {
            width: 100%;
            margin-top: .5625rem;
        }

        .icon span {
            font-weight: 400;
            font-family: din_regular;
            margin-bottom: 1.4375rem;
            font-size: .875rem;
        }

        .icon div img {
            width: 2.75rem;
        }

        .logoButtomM {
            display: block !important;
        }

        .logoButtomM img {
            width: 11.1875rem;
        }

        .manege {
            width: 100%;
            position: fixed;
            top: 50%;
            transform:translate(0,-50%);
            padding: 2.5rem 7.5%;
            box-sizing: border-box;
            border-radius: .75rem;
        }

        .hideManegeM {
            display: block;
        }

        .manege > img {
            right: .6875rem;
            top: .6875rem;
            width: .9375rem;
        }

        .title div:first-child {
            font-size: 1.25rem;
        }

        .title div:last-child span {
            font-size: .875rem;
            margin-right: .625rem;
        }

        .title div:last-child img {
            width: 3.125rem;
        }

        .stakeCart {
            margin-top: 1.625rem;
            font-size: .9375rem;
        }

        .earn {
            margin-top: 1rem;
            font-size: .9375rem;
        }

        .stakeDetails {
            margin-top: 1.4375rem;
            font-size: .9375rem;
            text-align: justify;
        }

        .available {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1.5rem;
        }

        .available > div:first-child span {
            font-size: .9375rem;
        }

        .available > div:first-child b {
            font-size: 1.125rem;
            font-weight: 300;
            font-family: din_light;
        }

        .available > div:last-child {
            margin-left: 0;
            margin-top: 1rem;
            width: 100%;
            height: 2.5625rem;
            border-radius: 5rem;
        }

        .available > div:last-child div:first-child {
            border-radius: 5rem 0 0 5rem;
        }

        .available > div:last-child div:last-child {
            border-radius: 0 5rem 5rem 0;
        }

        .available > div:last-child div {
            width: 25%;
            height: 2.5625rem;
            line-height: 2.35rem;
            font-size: 1.25rem;
        }

        .amount input {
            width: 100%;
            height: 2.5625rem;
            line-height: 2.35rem;
            font-size: 1.25rem;
            padding: 0 1.5625rem;
            margin-top: .9375rem;
            border-radius: 5rem;
        }

        input::-webkit-input-placeholder {
            font-size: 1rem;
        }

        .stakeButton {
            width: 10.9375rem;
            height: 3.125rem;
            line-height: 3.125rem;
            font-size: 1.125rem;
            margin-top: 2.25rem;
            border-radius: 5rem;
        }

        .approvePopup,
        .mintPopup {
            width: 85%;
            left: 7.5%;
            margin: 0;
            transform:translate(0,-50%);
            height: 11.9375rem;
            border-radius: .75rem;
        }

        .approvePopup > img,
        .transactionPopup > img,
        .mintPopup > img {
            width: 1.75rem;
            position: absolute;
            top: -0.875rem;
            right: -0.875rem;
            cursor: pointer;
        }

        .approvePopup div,
        .mintPopup div {
            font-size: 1.125rem;
            font-family: din_bold;
            width: 13.5rem;
            margin-top: 2.125rem;
        }

        .approvePopup div:last-child,
        .mintPopup div:last-child {
            width: 10.9375rem;
            height: 3.125rem;
            font-size: 1.125rem;
            line-height: 3.125rem;
            margin-bottom: 0;
            margin-top: 1.5rem;
            border-radius: 5rem;
        }

        .transactionPopup {
            width: 85%;
            left: 7.5%;
            bottom: .9375rem;
            height: 5.6875rem;
            border-radius: .75rem;
        }

        .started {
            margin-left: 1.1875rem;
            margin-top: 1.25rem;
            font-size: 1.125rem;
        }

        .started img {
            width: 1.4375rem;
        }

        .started span {
            margin-left: .6875rem;
        }

        .transactionPopup div:last-child {
            font-size: 1.125rem;
            margin-top: .625rem;
            margin-left: 3.4375rem;
        }

        .transactionPopup div:last-child span:nth-child(2) {
            margin: 0 .3125rem;
        }

        .transactionPopup div:last-child img {
            width: 1.0625rem;
            margin-right: .1875rem;
        }



        .hideManegeP {
            display: none;
        }

        .logoP {
            display: none;
        }

        .cartP {
            display: none;
        }

        .reddem {
            display: none;
        }

        .tokenP {
            display: none;
        }

        .logoButtomP {
            display: none !important;
        }

        .container {
            display: none;
        }
    }
</style>