<template>
  <div
    class="farmsbg"
    :style="'min-height:' + minHeight + 'px'"
    @click="hidedialog()"
  >
    <!-- 导航栏顶部 -->
    <NaviTop></NaviTop>

    <div class="placeholder">
      <div ref="placeholder">{{ inputlp }}</div>
    </div>
    <!-- 导航栏下方区域 -->
    <div class="bottom">
      <div class="farms">
        <!-- 返回组件 -->
        <div style="margin-top: 1.8125rem"><GoBack /></div>

        <!-- 返回组件的正下方 -->
        <div class="farmsbottom">
          <div class="farmsblock">
            <!-- 左半边部分 -->
            <div class="farmsblockleft" ref="backTop">
              <!-- <Earn :earnTitle="earnTitle" :usdtPrices="usdtPrices"></Earn>uniLpTotal -->
              <Earn :earnTitle="earnTitle" :usdtPrices="formatNumber(uniLpTotal)"></Earn>

              <!-- 当用户点击进入 无锁区域 -->
              <div v-show="ButtonType == 0" class="FlexibleBlock">
                <!-- 按钮外部矩形框样式 -->
                <div class="switchbtn">
                  <!-- 左边Locked外部按钮 -->
                  <div class="lockbutton" @click="ChangeLock()">
                    <!-- 图片显示 -->
                    <!-- <div>
                                            <img src="../assets/whitelock.png" class="buttonicon" >
                                        </div>
                                        <span class="buttontext" style="color:#FFF;"></span> -->
                    Locked
                  </div>
                  <!-- 右边Flexible外部按钮 -->
                  <div class="flexbuttonactive" @click="ChangeFlexible()">
                    <!-- 图片显示 -->Flexible
                    <!-- <div>
                                            <img src="../assets/brownflex.png" class="buttonicon" >
                                        </div>
                                        <span class="buttontext" style="color:#503C03;"></span> -->
                  </div>
                </div>

                <!-- 文字介绍 -->
                <div class="flexibletext">
                  Flexible tokens can be withdrawn at any time, but do not
                  accumulate weight. If the specified NFT is held, the weight
                  will be increased by 0.2, and only one NFT can be used at a
                  time.
                </div>

                <!-- 显示流程图片区域 -->
                <div>
                  <div v-show="step == 1">
                    <div class="progressPicBlock">
                      <ProgressBarOne></ProgressBarOne>
                    </div>

                    <div v-if="nonftloading" class="nftloadingblock">
                      <NFTLoading></NFTLoading>
                    </div>

                    <div
                      v-if="!MyNFTlist.length && !nonftloading"
                      class="nonftblock"
                    >
                      <NoNFT></NoNFT>
                    </div>

                    <!-- 如果有NFT 显示区域 -->
                    <div
                      v-if="MyNFTlist.length && !nonftloading"
                      class="carousel_container"
                    >
                      <img
                        v-if="currentPage == 1 && MyNFTlist.length > 1"
                        src="../assets/arrowleft.png"
                        style="cursor: not-allowed"
                      />
                      <img
                        v-if="currentPage != 1 && MyNFTlist.length > 1"
                        src="../assets/arrowleft.png"
                        style="cursor: pointer"
                        @click="previousPage()"
                      />
                      <div class="carousel_img">
                        <div
                          :style="{
                            transform: `translate(-${
                              (currentPage - 1) * 14
                            }rem, 0px)`,
                          }"
                        >
                          <!-- <div> -->
                          <div
                            v-for="(item, index) in MyNFTlist"
                            :key="index + ' MyNFTlist'"
                          >
                            <div class="NFTWhight">
                              weight + {{ item.weight }}
                            </div>
                            <video
                              class="carouselPic"
                              muted
                              webkit-playsinline
                              preload
                              playsinline
                              v-if="item.isVideo != -1"
                              :src="item.imgUrl"
                            ></video>
                            <img
                              class="carouselPic"
                              v-else
                              :src="item.imgUrl"
                            />
                            <div
                              class="NFTButton"
                              v-if="
                                poolTokenHistory.findIndex(
                                  (n) => n.token_id == item.tokenId
                                ) < 0
                              "
                              @click="
                                NFTSelect(item.tokenId, item.weight, item.name)
                              "
                            >
                              Select
                            </div>
                            <div
                              class="NFTButton"
                              v-else
                              style="background-color: #ccc; color: #000"
                            >
                              Selected
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        v-if="currentPage == pageAmount && MyNFTlist.length > 1"
                        src="../assets/arrowright.png"
                        style="cursor: not-allowed"
                      />
                      <img
                        v-if="currentPage != pageAmount && MyNFTlist.length > 1"
                        src="../assets/arrowright.png"
                        style="cursor: pointer"
                        @click="nextPage()"
                      />
                    </div>

                    <!-- skip -->
                    <div class="linkbutton" @click="skip()">Skip</div>
                  </div>

                  <div v-show="step == 2">
                    <div class="progressPicBlock">
                      <ProgressBarTwo></ProgressBarTwo>
                    </div>

                    <!-- 第一行文字 -->
                    <div class="textarea">
                      <!-- 显示左边文字区域 -->
                      <div class="textleft">
                        <!-- 左边第一段文字 -->
                        <div v-if="NFTSelectTokenId != 0" class="weighttext">
                          {{ NFTSelectName }}, WEIGHT + {{ NFTSelectWeight }}
                        </div>

                        <!-- 左边第二段文字 -->
                        <div class="selectbtn" @click="selectagain()">
                          Select Again
                        </div>
                      </div>

                      <!-- 显示右边文字区域 -->
                      <div class="textright">
                        <!-- 显示代币权重的标题文字 -->
                        <div class="weititle">
                          Token Weight:
                          <span v-if="NFTSelectTokenId == 0" class="weinum"
                            >1</span
                          >
                          <!-- <span v-else class="weinum">1.02</span> -->
                          <!-- <span v-else class="weinum">{{parseFloat(NFTSelectWeight) + 1 }}</span> -->
                          <div v-else>
                            <span class="weinum">{{
                              parseFloat(NFTSelectWeight) + 1
                            }}</span>
                            <!-- <span class="weinum">{{ NFTSelectName }}</span> -->
                          </div>
                        </div>

                        <!-- 图标 -->
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="right"
                        >
                          <div
                            slot="content"
                            style="
                              font-size: 1rem;
                              font-family: 'CeraGR-Regular';
                              font-weight: 400;
                            "
                          >
                            For each additional week selected, the token <br />
                            weight will increase by about 0.02; for 52 weeks,<br />
                            the maximum token weight will be close to 2. <br />
                            Token weight affects APR.
                          </div>
                          <img src="../assets/desc.png" class="weiicon" />
                        </el-tooltip>
                      </div>
                    </div>

                    <!-- 第二行输入框和按钮 -->
                    <div class="inputarea flexibleInputarea">
                      <!-- 输入框左半边区域 -->
                      <div style="display: flex; align-items: center">
                        <!-- 显示数据输入框 给予用户输入-->

                        <input
                          class="cartinput"
                          type="number"
                          v-model="inputlp"
                          :style="'width:' + inputWidth + 'px'"
                        />

                        <!-- cart 文字 -->
                        <span class="carttitle">LP</span>
                      </div>

                      <!-- 输入框右半边区域 -->
                      <div style="display: flex; align-items: center">
                        <!-- 我的余额 英文文字显示 -->
                        <span class="balancetext">My Balance:</span>

                        <!-- 显示余额数字 -->
                        <div class="balancenum">{{ lpBalance }}LP</div>

                        <!-- 点击Max按钮 -->
                        <div class="maxbutton" @click="MaxLpAmount()">Max</div>
                      </div>
                    </div>

                    <!-- 显示 apy -->
                    <div class="apyarea">
                      <!-- apy文字样式 -->
                      <span>APR:</span>

                      <!-- apy数据显示 -->
                      <div>{{ apy }}</div>
                    </div>

                    <!-- 判断钱包是否处于连接状态 -->
                    <div v-if="ConnectWallet">
                      <!-- 点击按钮区域 -->
                      <div
                        v-if="!isVal"
                        class="enteramount shinydarken"
                        style="cursor: not-allowed"
                      >
                        <!-- 按钮文字 -->
                        <span>Enter Amount</span>
                      </div>

                      <div
                        v-if="isVal && !isApprove && FlexibleStakeLoading"
                        class="enteramount"
                        style="cursor: not-allowed"
                      >
                        <!-- 按钮显示图片 -->
                        <img
                          src="../assets/loading_white.png"
                          class="loadingicon"
                        />

                        <!-- 按钮文字 -->
                        <span>Approve</span>
                      </div>

                      <div
                        v-if="isVal && !isApprove && !FlexibleStakeLoading"
                        class="enteramount"
                        style="cursor: pointer"
                        @click="approve"
                      >
                        <!-- 按钮文字 -->
                        <span>Approve</span>
                      </div>

                      <div
                        v-if="isVal && isApprove && FlexibleStakeLoading"
                        class="enteramount"
                        style="cursor: not-allowed"
                      >
                        <!-- 按钮显示图片 -->
                        <img
                          src="../assets/loading_white.png"
                          class="loadingicon"
                        />

                        <!-- 按钮文字 -->
                        <span>Stake</span>
                      </div>

                      <div
                        v-if="isVal && isApprove && !FlexibleStakeLoading"
                        class="enteramount"
                        style="cursor: pointer"
                        @click="OpenFlexStakeTips()"
                        @click.stop
                      >
                        <!-- 按钮文字 -->
                        <span>Stake</span>
                      </div>
                    </div>

                    <div v-if="!ConnectWallet">
                      <!-- 点击按钮区域 -->
                      <div
                        class="enteramount"
                        style="cursor: pointer"
                        @click="showConnect()"
                      >
                        <!-- 按钮文字 -->
                        <span>Connect Wallet</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 当用户点击进入 有锁区域 -->
              <!-- 上面显示 可以进行质押操作区域 -->
              <div v-show="ButtonType == 1" class="LockedBlock">
                <!-- 需要根据用户点击的来展示按钮类型 -->
                <!-- 按钮外部矩形框样式 -->
                <div class="switchbtn">
                  <!-- 左边Locked外部按钮 -->
                  <!-- 判断用户点击 -->
                  <div class="lockbuttonactive" @click="ChangeLock()">
                    <!-- 图片显示 -->Locked
                    <!-- <div>
                                            <img src="../assets/brownlock.png" class="buttonicon" >
                                        </div>
                                        <span class="buttontext" style="color:#503C03;"></span> -->
                  </div>
                  <!-- 右边Flexible外部按钮 -->
                  <div class="flexbutton" @click="ChangeFlexible()">
                    <!-- 图片显示 -->Flexible
                    <!-- <div>
                                            <img src="../assets/whiteflex.png" class="buttonicon" >
                                        </div>
                                        <span class="buttontext" style="color:#FFF;"></span> -->
                  </div>
                </div>

                <!-- 文字区域 -->
                <!-- 文字介绍 -->
                <div class="lockedtext">
                  Locking your stake increases your token weight by almost up to
                  2x. Tokens that are locked cannot be withdrawn until the
                  designated lock time has been reached. Locking ranges from
                  1-52 weeks.
                </div>

                <!-- 进度条上面的数据区域 -->
                <div class="datablock">
                  <!-- 左边数据显示区域 -->
                  <div class="dataleft">
                    <!-- lacked for -->
                    <div>Lock for:</div>
                    <!-- 显示数据week -->
                    <span style="margin-left: 1rem; margin-right: 0.875rem">{{
                      lackforweek
                    }}</span>
                    <!-- weeks -->
                    <div>weeks</div>
                  </div>

                  <!-- 右边数据显示区域 -->
                  <div class="dataright">
                    <!-- Token Weight -->
                    <div>Token Weight:</div>
                    <!-- 显示数据token weight -->
                    <div class="weinum">{{ tokenweight }}</div>
                    <!-- 图标 -->
                    <el-tooltip class="item" effect="dark" placement="right">
                      <div
                        slot="content"
                        style="
                          font-size: 1rem;
                          font-family: 'CeraGR-Regular';
                          font-weight: 400;
                        "
                      >
                        For each additional week selected, the token <br />
                        weight will increase by about 0.02; for 52 weeks,<br />
                        the maximum token weight will be close to 2. <br />
                        Token weight affects APR.
                      </div>
                      <img src="../assets/desc.png" class="weiicon" />
                    </el-tooltip>
                  </div>
                </div>

                <!-- 进度条 -->
                <el-slider
                  style="margin: 0 0.5rem; height: 0.5rem"
                  v-model="lackforweek"
                  :max="maxWeek"
                  :min="1"
                ></el-slider>

                <div class="inputarea">
                  <!-- 输入框左半边区域 -->
                  <div style="display: flex; align-items: center">
                    <!-- 显示数据输入框 给予用户输入-->
                    <input
                      class="cartinput"
                      type="number"
                      v-model="inputlp"
                      :style="'width:' + inputWidth + 'px'"
                    />

                    <!-- cart 文字 -->
                    <span class="carttitle">LP</span>
                  </div>

                  <!-- 输入框右半边区域 -->
                  <div style="display: flex; align-items: center">
                    <!-- 我的余额 英文文字显示 -->
                    <span class="balancetext">My Balance:</span>

                    <!-- 显示余额数字 -->
                    <div class="balancenum">{{ lpBalance }} LP</div>

                    <!-- 点击Max按钮 -->
                    <div class="maxbutton" @click="MaxLpAmount()">Max</div>
                  </div>
                </div>

                <!-- 显示 apy -->
                <div class="apyarea">
                  <!-- apy文字样式 -->
                  <span>APR:</span>

                  <!-- apy数据显示 -->
                  <div>{{ apy }}</div>
                </div>

                <!-- 判断钱包是否处于连接状态 -->
                <div v-if="ConnectWallet">
                  <!-- 点击按钮区域 -->
                  <div
                    v-if="!isVal"
                    class="enteramount shinydarken"
                    style="cursor: not-allowed"
                  >
                    <!-- 按钮文字 -->
                    <span>Enter Amount</span>
                  </div>

                  <div
                    v-if="isVal && !isApprove && LockedStakeLoading"
                    class="enteramount"
                    style="cursor: not-allowed"
                  >
                    <!-- 按钮显示图片 -->
                    <img
                      src="../assets/loading_white.png"
                      class="loadingicon"
                    />

                    <!-- 按钮文字 -->
                    <span>Approve</span>
                  </div>

                  <div
                    v-if="isVal && !isApprove && !LockedStakeLoading"
                    class="enteramount"
                    style="cursor: pointer"
                    @click="approve"
                  >
                    <!-- 按钮文字 -->
                    <span>Approve</span>
                  </div>

                  <div
                    v-if="isVal && isApprove && LockedStakeLoading"
                    class="enteramount"
                    style="cursor: not-allowed"
                  >
                    <!-- 按钮显示图片 -->
                    <img
                      src="../assets/loading_white.png"
                      class="loadingicon"
                    />

                    <!-- 按钮文字 -->
                    <span>Stake</span>
                  </div>

                  <div
                    v-if="isVal && isApprove && !LockedStakeLoading"
                    class="enteramount"
                    style="cursor: pointer"
                    @click="OpenLockStakeTips()"
                    @click.stop
                  >
                    <!-- 按钮文字 -->
                    <span>Stake</span>
                  </div>
                </div>

                <div v-if="!ConnectWallet">
                  <!-- 点击按钮区域 -->
                  <div
                    class="enteramount"
                    style="cursor: pointer"
                    @click="showConnect()"
                  >
                    <!-- 按钮文字 -->
                    <span>Connect Wallet</span>
                  </div>
                </div>
              </div>

              <!-- farms页面有锁质押弹窗 -->

              <!-- 下面显示 可以进行质押操作区域 -->
              <div class="StakeBlock" v-if="ifStake">
                <!-- 标题栏目 -->
                <div class="StakeTitleBlock">
                  <!-- 标题 -->
                  <span class="staketitle">Staked</span>

                  <!-- 图标选择区 -->
                  <div class="radioblock">
                    <!-- 左边可点击区域 -->
                    <div class="radiobtnblock" @click="radioLocked">
                      <!-- 显示图标 -->
                      <img
                        v-if="RadioType == 0"
                        src="../assets/radio.png"
                        class="radioimg"
                      />
                      <img
                        v-if="RadioType == 1"
                        src="../assets/radioactive.png"
                        class="radioimg"
                      />
                      <!-- 显示locked文字 -->
                      <span class="Lockedword">Locked</span>
                    </div>

                    <!-- 右边可点击区域 -->
                    <div class="radiobtnblock" @click="radioFlexible">
                      <!-- 显示图标 -->
                      <img
                        v-if="RadioType == 1"
                        src="../assets/radio.png"
                        class="radioimg"
                      />
                      <img
                        v-if="RadioType == 0"
                        src="../assets/radioactive.png"
                        class="radioimg"
                      />
                      <!-- 显示locked文字 -->
                      <span class="Flexibleword">Flexible</span>
                    </div>
                  </div>
                </div>

                <!-- 表格区域 -->
                <div class="StakeTableBlock">
                  <!-- 在质押区域还需要判断选择的是有锁还是无锁 -->

                  <!-- 有锁区间 -->
                  <div v-if="RadioType == 1">
                    <!-- 展示质押区域 有锁状态下的数据表格信息 -->
                    <div class="Staketable" v-if="farmsLockarray.length != 0">
                      <template>
                        <table class="tableclass">
                          <tr>
                            <th>Amount</th>
                            <th>Weight</th>
                            <th>Unlock date</th>
                            <th>Unstake</th>
                          </tr>

                          <tr
                            v-for="(item, index) in farmsLockarray"
                            :key="index"
                            class="table_tr_v"
                          >
                            <th>{{ item.Locktokenamount }}</th>
                            <th>{{ item.Lockweight }}</th>
                            <th v-if="item.islocked">
                              <span style="display: flex; align-items: center">
                                <div
                                  style="
                                    width: 4.5rem;
                                    height: 0.88rem;
                                    background: #d8d8d8;
                                    border-radius: 0.44rem;
                                    margin-right: 0.69rem;
                                  "
                                >
                                  <div
                                    class="locked_width"
                                    :style="{
                                      width:
                                        (item.passtime / item.unlockdate) *
                                          100 +
                                        '%',
                                      minWidth: '1rem',
                                    }"
                                  >
                                    {{ item.passtime }}
                                  </div>
                                </div>
                                {{ item.unlockdate }}days

                                <!-- {{ item.passtime }}/{{ item.unlockdate }}days -->
                              </span>
                            </th>
                            <th v-else>
                              <div class="tabledate">
                                <img
                                  src="../assets/finished.png"
                                  style="margin-right: 0.69rem"
                                />
                                <span>{{ item.unlockdate }} days</span>
                              </div>
                            </th>
                            <th v-if="item.islocked">
                              <!-- 按钮区域 -->
                              <div class="tablebuttonblock">
                                <!-- 按钮外部样式 赎回-->
                                <div
                                  class="tablebutton lockbtn"
                                  style="background: #a8a8a8"
                                >
                                  <!-- <div>
                                    <img
                                      src="../assets/blacklock.png"
                                      class="lockicon"
                                    />
                                  </div> -->
                                  <span
                                    class="tablebuttonword"
                                    style="margin: auto"
                                    >Locked</span
                                  >
                                </div>
                              </div>
                            </th>
                            <th v-else>
                              <!-- 按钮区域 -->
                              <div class="tablebuttonblock">
                                <!-- 按钮外部样式 赎回-->
                                <div
                                  v-if="item.isLoading"
                                  class="tablebutton withdrawbtn"
                                  style="cursor: not-allowed"
                                >
                                  <div>
                                    <img
                                      src="../assets/loading_black.png"
                                      class="withdrawicon"
                                    />
                                  </div>
                                  <span class="tablebuttonword">Withdraw</span>
                                </div>

                                <!-- <div v-else class="tablebutton withdrawbtn" @click="Lockwithdraw(index, item.id)"> -->
                                <div
                                  v-else
                                  class="tablebutton withdrawbtn"
                                  @click="OpenLockWithdrawTips(index, item.id)"
                                  @click.stop
                                >
                                  <span class="tablebuttonword">Withdraw</span>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </table>
                      </template>
                    </div>
                  </div>

                  <!-- 无锁区间 -->
                  <div v-if="RadioType == 0">
                    <div class="Staketable" v-if="farmsFlexarray.length != 0">
                      <template>
                        <table class="tableclass">
                          <tr>
                            <th>Amount</th>
                            <th>Weight</th>
                            <th>Unstake</th>
                          </tr>
                          <tr
                            v-for="(item, index) in farmsFlexarray"
                            :key="index"
                            class="table_tr_v"
                          >
                            <th>{{ item.Flextokenamount }}</th>
                            <th>{{ item.Flexweight }}</th>
                            <th>
                              <!-- 按钮区域 -->
                              <div class="tablebuttonblock">
                                <!-- 按钮外部样式 赎回-->
                                <div
                                  v-if="item.isLoading"
                                  class="tablebutton withdrawbtn"
                                  style="cursor: not-allowed"
                                >
                                  <div>
                                    <img
                                      src="../assets/loading_black.png"
                                      class="withdrawicon"
                                    />
                                  </div>
                                  <span class="tablebuttonword">Withdraw</span>
                                </div>

                                <!-- <div v-else class="tablebutton withdrawbtn" @click="Flexwithdraw(item.id)"> -->
                                <div
                                  v-else
                                  class="tablebutton withdrawbtn"
                                  @click="OpenFlexWithdrawTips(item.id)"
                                  @click.stop
                                >
                                  <span class="tablebuttonword">Withdraw</span>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </table>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 下面显示 可以进行pending操作区域 -->

              <div class="PendingBlock" v-if="this.pengdingreward != 0">
                <!-- 展示pending -->
                <!-- 标题 -->
                <span class="pendingtitle" style="text-align: left"
                  >Pending Reward</span
                >

                <div class="PendingTableBlock">
                  <div class="Staketable">
                    <template>
                      <table class="tableclass">
                        <tr>
                          <th>Amount</th>
                          <th>Unstake</th>
                        </tr>

                        <tr>
                          <th>{{ this.pengdingreward }}</th>
                          <th>
                            <div class="tablebuttonblock">
                              <div
                                v-if="PengdingrewardLoading"
                                class="tablebutton withdrawbtn"
                                style="
                                  cursor: not-allowed;
                                  justify-content: center;
                                "
                              >
                                <div>
                                  <img
                                    src="../assets/loading_black.png"
                                    class="withdrawicon"
                                  />
                                </div>
                                <span class="tablebuttonword">Claim</span>
                              </div>

                              <div
                                v-else
                                class="tablebutton withdrawbtn"
                                style="justify-content: center"
                                @click="claim()"
                              >
                                <span class="tablebuttonword">Claim</span>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </table>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!-- 右半边部分 -->
            <div class="farmsblockright" :class="fixed ? 'fixed' : ''">
              <div v-if="rewardarrayLength != 0">
                <Rewards :rewardarray="rewardarray"></Rewards>
              </div>
              <div v-else>
                <NoRewards :isQuestion="true"></NoRewards>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AvatarDialog
      :bcart="bcart"
      :cartStaked="cartStaked"
      :cartAmount="cartAmount"
      :mynft="mynft"
    ></AvatarDialog>
    <SelectConnectPopup :connect="ConnectWallet"></SelectConnectPopup>
    <!-- farms页面无锁质押弹窗 -->
    <div v-if="tipsDialog == 'flexstake'" class="tipsDialog">
      <!-- 标题 -->
      <span class="tipsTitle">TIPS!</span>
      <!-- 文字和按钮区域 -->
      <div class="textbtnblock">
        <!-- 文字区域 -->
        <div class="tipsText">
          The smart contract will settle the rewards of pool so far for you at
          the same time!
        </div>
        <!-- 按钮 -->
        <div class="tipsButtonblock">
          <!-- 取消按钮外部样式 -->
          <div class="tipscancel" @click="hidedialog()" @click.stop>
            <span>cancel</span>
          </div>
          <!-- 提交按钮外部样式 -->
          <div class="tipsubmit" @click="FlexibleStake()">
            <span>confirm</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tipsDialog == 'lockstake'" class="tipsDialog">
      <!-- 标题 -->
      <span class="tipsTitle">TIPS!</span>
      <!-- 文字和按钮区域 -->
      <div class="textbtnblock">
        <!-- 文字区域 -->
        <div class="tipsText">
          The smart contract will settle the rewards of pool so far for you at
          the same time!
        </div>
        <!-- 按钮 -->
        <div class="tipsButtonblock">
          <!-- 取消按钮外部样式 -->
          <div class="tipscancel" @click="hidedialog()" @click.stop>
            <span>cancel</span>
          </div>
          <!-- 提交按钮外部样式 -->
          <div class="tipsubmit" @click="LockedStake()">
            <span>confirm</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 有锁区间赎回 -->
    <div v-if="tipsDialog == 'lockwithdraw'" class="tipsDialog">
      <!-- 标题 -->
      <span class="tipsTitle">TIPS!</span>
      <!-- 文字和按钮区域 -->
      <div class="textbtnblock">
        <!-- 文字区域 -->
        <div class="tipsText">
          The smart contract will settle the rewards of pool so far for you at
          the same time!www
        </div>
        <!-- 按钮 -->
        <div class="tipsButtonblock">
          <!-- 取消按钮外部样式 -->
          <div class="tipscancel" @click="hidedialog()" @click.stop>
            <span>cancel</span>
          </div>
          <!-- 提交按钮外部样式 -->
          <div
            class="tipsubmit"
            @click="Lockwithdraw(LockWithdrawIndex, LockWithdrawId)"
          >
            <span>confirm</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 无锁区间赎回 -->
    <div v-if="tipsDialog == 'flexwithdraw'" class="tipsDialog">
      <!-- 标题 -->
      <span class="tipsTitle">TIPS!</span>
      <!-- 文字和按钮区域 -->
      <div class="textbtnblock">
        <!-- 文字区域 -->
        <div class="tipsText">
          The smart contract will settle the rewards of pool so far for you at
          the same time!
        </div>
        <!-- 按钮 -->
        <div class="tipsButtonblock">
          <!-- 取消按钮外部样式 -->
          <div class="tipscancel" @click="hidedialog()" @click.stop>
            <span>cancel</span>
          </div>
          <!-- 提交按钮外部样式 -->
          <div class="tipsubmit" @click="Flexwithdraw(FlexWithdrawId)">
            <span>confirm</span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="popupBg"
      class="popupBg"
      @mousewheel.prevent
      @touchmove.prevent
    ></div>
  </div>
</template>

<script>
import Proxy from "../proxy.js";
import SelectConnectPopup from "@/components/SelectConnectPopup.vue";
import NaviTop from "@/components/NaviTop.vue";
import Rewards from "@/components/Rewards.vue";
import NoRewards from "@/components/NoRewards.vue";
import NoNFT from "@/components/NoNFT.vue";
import ProgressBarOne from "@/components/ProgressBarOne.vue";
import ProgressBarTwo from "@/components/ProgressBarTwo.vue";
import AvatarDialog from "@/components/AvatarDialog.vue";
import GoBack from "@/components/GoBack.vue";
import Earn from "@/components/Earn.vue";
import { NumberMul, NumberAdd, NumberSub, NumberDiv } from "@/math.js";
import { formatNum } from "./../decUtil.js";
import axios from "axios";
import NFTLoading from "@/components/NFTLoading.vue";
import { NETWORK_NAME } from "./../constants";
import BigNumber from "bignumber.js";
export default {
  name: "Farms",
  components: {
    NaviTop,
    Rewards,
    NoRewards,
    NoNFT,
    AvatarDialog,
    GoBack,
    Earn,
    SelectConnectPopup,
    ProgressBarOne,
    ProgressBarTwo,
    NFTLoading,
  },
  data() {
    return {
      // 最小屏幕高度初始化
      minHeight: 0,

      // 控制显示nonft的读取动画
      nonftloading: true,

      // 当多条nft信息时候获取页数
      pageAmount: 0,

      // 当多条nft的时候 表示当前页码
      currentPage: 1,

      // 弹窗产生后背景样式
      popupBg: false,

      // 控制进行质押和赎回时候弹窗显示的变量
      tipsDialog: "",

      // farms 有锁和无锁数组 用于显示并且渲染
      farmsFlexarray: [],
      farmsLockarray: [],

      // 点击有锁赎回弹窗传值的index索引和id
      LockWithdrawIndex: 0,
      LockWithdrawId: 0,

      // 点击无锁赎回弹窗传值的index索引和id
      FlexWithdrawIndex: 0,
      FlexWithdrawId: 0,

      // 存储的奖励数组 用于传值
      rewardlist: [],

      rewardtitle: "Rewards",

      // 用于提取无锁质押的金额
      FlexunstakeAmount: 0,

      // 用于提取有锁质押的金额
      LockunstakeAmount: 0,

      // 悬赏金额
      pengdingreward: 0,

      NFTSelectWeight: 0,
      NFTSelectTokenId: 0,
      NFTSelectName: "",

      // 获取的质押信息长度
      ifStake: 0,

      // 奖励数组
      rewardarray: [],

      // 奖励金额
      rewardarrayLength: 0,

      // lpBalance 用于展示在输入框中的
      lpBalance: 0,
      // 用户输入框绑定的v-model
      inputlp: 0,

      // 定义的最大质押金额
      maxCartPoolLp: 0,

      inputWidth: 0,

      // cartinreward: 在get Balance方法中获取，然后显示total reward
      cartinreward: null,

      // cartStaked: 在mystake函数中方法中获取，然后显示cart stakeding
      cartStaked: null,

      // bcart: 在mystake函数中方法中获取，然后显示bcart
      bcart: null,

      mynft: null,

      // 前端显示页面的数据
      cartAmount: null,

      // 按钮选择 有锁Locked和无锁Flexible radiotype同理
      // 1:Locked有锁
      // 0:Flexible无锁
      ButtonType: 1,
      RadioType: 1,

      // 点击按钮后的读取图标
      FlexibleStakeLoading: false,
      LockedStakeLoading: false,
      PengdingrewardLoading: false,

      // 判断质押步骤
      step: 1,

      // 年收益率
      apy: "0%",

      // 权重初始化为1.02
      tokenweight: 0,

      // 初始化周数为1
      lackforweek: 1,

      // 获取我的NFT数组
      MyNFTlist: [],

      started: false,

      // 控制是否处于质押状态
      staking: false,

      NFTAddress: "0x3AD503084f1bD8d15A7F5EbE7A038C064e1E3Fa1",

      approveCartAmount: 0,

      isApprove: false,

      isVal: false,

      CartCorePoolAdress: null,

      CartCorePool_LP_Address: null,

      NFTMockAdress: null,

      // earn 组件标题
      earnTitle: "UNI LP",

      // 显示tvl数据
      usdtPrices: 0,

      fixed: false,

      maxWeek: 52,
      poolTokenHistory: [],
      uniLpTotal: 0
    };
  },

  computed: {
    NaviTopType() {
      return this.$store.state.navMenu.NaviTopType;
    },
    ConnectWallet() {
      return this.$store.state.navMenu.ConnectWallet;
    },
    loginStatus() {
      return this.$store.state.navMenu.loginStatus;
    },
  },

  watch: {
    inputlp(val) {
      this.$nextTick(function () {
        let width = this.$refs.placeholder.offsetWidth;
        this.inputWidth = width + 10;
      });
      if (
        val == 0 ||
        val == "" ||
        val == null ||
        val > parseFloat(this.maxCartPoolLp)
      ) {
        this.isVal = false;
        this.getAPR();
      } else {
        this.isVal = true;
        this.getAPR();
      }

      if (parseFloat(this.approveCartAmount) >= parseFloat(val)) {
        this.isApprove = true;
      } else {
        this.isApprove = false;
      }
    },

    ButtonType() {
      this.getAPR();
    },
    NFTSelectTokenId() {
      this.getAPR();
    },
    async lackforweek() {
      this.tokenweight = await this.getTokenWeight();
      this.getAPR();
    },

    loginStatus(val) {
      if (val) {
        this.getData();
      } else {
        this.logout();
      }
    },
  },

  created() {
    var that = this;
    if (window.ethereum)
      window.ethereum.on("accountsChanged", function (accounts) {
        that.getData();
      });

    this.$store.commit("updateNaviTopType", "farms");
    if (this.loginStatus) {
      this.getData();
    }
  },

  activated() {
    this.minHeight = window.innerHeight;
  },

  mounted() {
    // 滚动条的获取
    window.addEventListener("scroll", this.handleScroll, true);
  },

  methods: {
    getPoolCardHistory() {
      let a = {
        type: "fram",
        account: this.account,
        is_del: 0,
      };
      axios.post(`/cartAPI/cart/queryBidingToken`, a).then((res) => {
        console.log(res);
        this.poolTokenHistory = res.data.result;
      });
    },
    poolCardHistory() {
      let a = {
        type: "fram",
        token_id: this.NFTSelectTokenId,
        account: this.account,
      };
      axios.post(`/cartAPI/cart/add`, a).then((res) => {
        console.log(res);
      });
    },
    handleScroll() {
      let top = this.$refs.backTop.getBoundingClientRect().top;
      if (top < 0) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },

    // 获取数据
    getData() {
      clearInterval(this.refreshPendingRewardNum);
      this.refreshPendingRewardNum = null;
      this.refreshCartNum = null;
      // 初始化数组 用于获取NFT
      this.MyNFTlist = [];

      Proxy.initWeb3Account(async (web3, acc) => {
        if (acc) {
          // 获取账号
          this.account = acc;

          // 初始化定义web3
          this.web3 = web3;

          let chainid = await web3.eth.net.getId();
          const networkName = NETWORK_NAME[chainid];

          let Cart = require(`../../deployments/${networkName}/Cart.json`);
          this.Cart_Adress = Cart.address;

          let CartCorePool_LP = require(`../../deployments/${networkName}/CartCorePool_LP.json`);
          this.CartCorePool_LP_Address = CartCorePool_LP.address;

          let NFTMock = require(`../../deployments/${networkName}/NFTMock.json`);
          this.NFTMockAdress = NFTMock.address;

          let CartPoolFactory = require(`../../deployments/${networkName}/CartPoolFactory.json`);
          this.CartPoolFactoryAdress = CartPoolFactory.address;

          let CartCorePool = require(`../../deployments/${networkName}/CartCorePool.json`);
          this.CartCorePoolAdress = CartCorePool.address;
          this.getPoolCardHistory();
          // 初始化合约
          this.initContact();

          // 获取Cart数据
          this.getCart();
        }
      });
    },

    // 初始化合约
    async initContact() {
      // 调用proxy中的函数获取数据等等
      this.token = await Proxy.getToken();
      this.cart = await Proxy.getToken();
      this.nft = await Proxy.getNFTMock();
      this.farmNft = await Proxy.getFarmNFT();
      this.factory = await Proxy.getCartPoolFactory();
      this.farmbCart = await Proxy.getFarmbCart();
      this.cartCorePool = await Proxy.getCartCorePool();
      this.helper = await Proxy.getHelper();

      this.cartPoolLP = await Proxy.getCartPoolLP();
      this.lptoken = await Proxy.getUNILP();
      this.uniToken = await Proxy.getUNI();
      this.tokenweight = await this.getTokenWeight();

      this.getAPR();

      this.getSelectWeek();

      this.getAllowance();

      // 获取合约地址余额
      this.getBalance();
      // 获取质押数据
      this.myStake();

      this.refreshPendingReward();

      // 获取用户质押信息
      this.getStakeInfo();

      // 获取复投信息
      this.getRewards();

      // 获取悬赏信息
      this.getPendingRewards();

      // 获取NFT数据
      this.myNfts();
      this.getLpAmount();
    },
    getLpAmount() {
      this.uniToken
        .getReserves({
          from: this.account,
        })
        .then((r) => {
          this.uniLpTotal =
            (BigNumber(r[1])
              .div(10 ** 6)
              .toNumber() * 2);
              
        });
    },
    // LP单独功能函数
    getAllowance() {
      // 获取已授权CART数量

      this.lptoken
        .allowance(this.account, this.CartCorePool_LP_Address)
        .then((r) => {
          let num = this.web3.utils.fromWei(r.toString());

          this.approveCartAmount = this.web3.utils.fromWei(r.toString());

          if (parseFloat(num) >= parseFloat(this.inputlp)) {
            this.isApprove = true;
          } else {
            this.isApprove = false;
          }
        });
    },

    // 共有函数 待封装
    refreshPendingReward() {
      var that = this;
      if (this.refreshPendingRewardNum) {
        return;
      } else {
        this.refreshPendingRewardNum = setInterval(function () {
          if (that.connect) {
            that.getPendingRewards();
          }
        }, 20000);
      }
    },

    // 获取余额
    getBalance() {
      // 获取cart余额
      this.cart.balanceOf(this.account).then((r) => {
        this.cartAmount = this.web3.utils.fromWei(r.toString());
        const amount = Proxy.formatDecimal(parseFloat(this.cartAmount), 2);

        if (parseInt(amount) == 0) {
          this.cartAmount = 0;
        } else {
          this.cartAmount = Proxy.formatDecimal(parseFloat(amount), 2);
        }
      });

      this.cart.balanceOf(this.CartCorePool_LP_Address).then((r) => {
        axios
          .get("https://api.coingecko.com/api/v3/coins/cryptoart-ai")
          .then((res) => {
            const usdtPerPrice = res.data.market_data.current_price.usd;
            this.poolLPAllStake(usdtPerPrice);
          })
          .catch((error) => {
            console.log("error -> ", error);
          });
      });

      // 获取nft余额
      this.nft.balanceOf(this.account).then((r) => {
        this.mynft = this.web3.utils.fromWei(r.toString());
      });

      // 获取token余额
      this.token.balanceOf(this.farmNft.address).then((r) => {
        this.cartinreward = this.web3.utils.fromWei(r);
      });

      this.lptoken.balanceOf(this.account).then((r) => {
        const balance = this.web3.utils.fromWei(r);
        if (balance == 0) {
          this.lpBalance = 0;
        } else {
          this.lpBalance = NumberDiv(parseFloat(balance), 1, 6);
        }
        this.maxCartPoolLp = this.lpBalance;
      });
    },

    // 获取cart数据 已经封装
    async getCart() {
      await Proxy.getToken()
        .then((r) => {
          return r.balanceOf(this.account);
        })
        .then((r) => {
          this.cartBalance = this.web3.utils.fromWei(r.toString());
        });
    },

    // 获取质押数据 bcart用于质押 cartStaked用于赎回 已经封装
    myStake() {
      this.farmbCart.myStake(this.account).then((r) => {
        /***************  赎回 cartStaked ***************/
        // 可用于赎回数据的string类型，进行了格式化，用于展示在页面中 原理：将数据进行千位处理，用逗号隔开
        this.cartStaked = formatNum(this.web3.utils.fromWei(r[0]), 2);

        // 可用于赎回数据的string类型，用于赋值到输入框中
        this.cartStakedNum = this.web3.utils.fromWei(r[0]);

        /***************  质押 bcart ***************/
        // 可用于质押数据的string类型，进行了格式化，用于展示在页面中 原理：将数据进行千位处理，用逗号隔开
        this.bcart = formatNum(this.web3.utils.fromWei(r[1]), 2);

        // 可用于质押数据的Number类型，用于赋值到输入框中
        this.bcartNum = this.web3.utils.fromWei(r[1]);
      });
    },
    formatNumber(num) {
      return num >= 1e3 ? (num / 1e3).toFixed(2) + "K" : num;
    },
    // 获取Lp池子总质押数量 获得的数据作为参数调用合约获取tvl
    poolLPAllStake(usdtPerPrice) {
      this.cartPoolLP
        .poolTokenReserve()
        .then((res) => {
          if (
            BigNumber(res)
              .div(10 ** 18)
              .toNumber() == 0
          ) {
            this.usdtPrices = 0;
          } else {
            this.helper
              .lptoTokenAmount(process.env.VUE_APP_LP_ADDRESS, res)
              .then((r) => {
                let poolLpStakeNum =
                  BigNumber(r.amount0)
                    .div(10 ** 18)
                    .toNumber() * 2;
                // 489082974574924223
                this.usdtPrices = NumberMul(poolLpStakeNum, usdtPerPrice);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getlpcartamount(amount) {
      return await this.helper
        .lptoTokenAmount(process.env.VUE_APP_LP_ADDRESS, amount)
        .then((r) => {
          let poolLpStakeNum =
            BigNumber(r.amount0)
              .div(10 ** 18)
              .toNumber() * 2;
          return poolLpStakeNum;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 获取质押信息 待封装
    getStakeInfo() {
      this.farmsFlexarray = [];
      this.farmsLockarray = [];
      this.ifStake = false;
      this.helper
        .getDepositsByIsYield(this.CartCorePool_LP_Address, this.account, false)
        .then((data) => {
          // 创建时间对象
          var time = new Date();

          // 获取当前时间戳 以秒为单位
          var nowTime = Date.parse(time) / 1000;
          var ids = data[0];
          var res = data[1];

          if (res.length != 0) {
            if (res[0].tokenAmount != 0) {
              var Flextokenamount = BigNumber(res[0].tokenAmount)
                .div(10 ** 18)
                .toNumber();
              this.FlexunstakeAmount = res[0].tokenAmount;
              var Flexweight = NumberDiv(
                BigNumber(res[0].weight)
                  .div(10 ** 24)
                  .toNumber(),
                1
              );
              var FlexdataArray = {
                Flextokenamount: Flextokenamount,
                Flexweight: Flexweight,
                id: ids[0],
                // id: parseInt(ids[i]),
                isLoading: false,
              };
              this.farmsFlexarray.push(FlexdataArray);

              for (var i = 1; i < res.length; i++) {
                if (res[i].tokenAmount != 0) {
                  var LockunstakeAmount = res[i].tokenAmount;

                  var Locktokenamount = BigNumber(res[i].tokenAmount)
                    .div(10 ** 18)
                    .toNumber();
                  var staketime =
                    parseInt(res[i].lockedUntil) - res[i].lockedFrom;
                  // 剩余时间
                  var surplustime = parseInt(res[i].lockedUntil) - nowTime;
                  // 过去时间
                  var passtime = parseInt(
                    (nowTime - res[i].lockedFrom) / (24 * 60 * 60)
                  );
                  // 总的时间
                  var unlockdate = parseInt(staketime / (24 * 60 * 60));
                  var Lockweight = NumberDiv(
                    BigNumber(res[i].weight)
                      .div(10 ** 24)
                      .toNumber(),
                    1
                  );
                  var LockdataArray = {
                    LockunstakeAmount: LockunstakeAmount,
                    staketime: staketime,
                    Locktokenamount: Locktokenamount,
                    Lockweight: Lockweight,
                    unlockdate: unlockdate,
                    id: ids[i],
                    // id: parseInt(ids[i]),
                    isLoading: false,
                    surplustime: surplustime,
                    passtime: passtime,
                    islocked: false,
                  };
                  if (surplustime > 0) {
                    LockdataArray.islocked = true;
                  }
                  this.farmsLockarray.push(LockdataArray);
                }
              }
              this.ifStake = true;
            } else {
              for (i = 1; i < res.length; i++) {
                if (res[i].tokenAmount != 0) {
                  LockunstakeAmount = res[i].tokenAmount;

                  Locktokenamount = BigNumber(res[i].tokenAmount)
                    .div(10 ** 18)
                    .toNumber();
                  staketime = parseInt(res[i].lockedUntil) - res[i].lockedFrom;
                  unlockdate = parseInt(staketime / (24 * 60 * 60));

                  Lockweight = NumberDiv(
                    BigNumber(res[i].weight)
                      .div(10 ** 24)
                      .toNumber(),
                    1
                  );
                  surplustime = parseInt(res[i].lockedUntil) - nowTime;
                  passtime = parseInt(
                    (nowTime - res[i].lockedFrom) / (24 * 60 * 60)
                  );
                  LockdataArray = {
                    LockunstakeAmount: LockunstakeAmount,
                    staketime: staketime,
                    Locktokenamount: Locktokenamount,
                    Lockweight: Lockweight,
                    unlockdate: unlockdate,
                    surplustime: surplustime,
                    id: ids[i],
                    // id: parseInt(ids[i]),
                    isLoading: false,
                    passtime: passtime,
                    islocked: false,
                  };
                  if (surplustime > 0) {
                    LockdataArray.islocked = true;
                  }
                  this.farmsLockarray.push(LockdataArray);
                  this.ifStake = true;
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 获取复投信息
    getRewards() {
      this.rewardarray = [];
      this.helper
        .getDepositsByIsYield(this.CartCorePoolAdress, this.account, true)
        .then((data) => {
          // 创建时间对象
          var time = new Date();

          // 获取当前时间戳 以秒为单位
          var nowTime = Date.parse(time) / 1000;

          var ids = data[0];
          var res = data[1];
          this.rewardlist = data[1];
          this.rewardarrayLength = res.length;
          for (var i = 0; i < res.length; i++) {
            var LockunstakeAmount = res[i].tokenAmount;

            var Locktokenamount = NumberDiv(
              BigNumber(res[i].tokenAmount)
                .div(10 ** 18)
                .toNumber(),
              1,
              2
            );

            var staketime = res[i].lockedUntil - res[i].lockedFrom;
            // 剩余时间
            var surplustime = parseInt(res[i].lockedUntil) - nowTime;

            var passtime = parseInt(
              (nowTime - res[i].lockedFrom) / (24 * 60 * 60)
            );

            var unlockdate = parseInt(staketime / (24 * 60 * 60));

            var Lockweight = NumberDiv(
              BigNumber(res[i].weight)
                .div(10 ** 24)
                .toNumber(),
              1
            );

            var RewarddataArray = {
              // Amount余额
              LockunstakeAmount: LockunstakeAmount,
              // 质押时间
              staketime: staketime,
              // Amount余额 显示用的
              Locktokenamount: Locktokenamount,
              Lockweight: Lockweight,
              // 总时长
              unlockdate: unlockdate,
              id: ids[i],
              // 剩余时间
              surplustime: surplustime,
              islocked: false,
              passtime: passtime,
              isLoading: false,
            };
            if (surplustime > 0) {
              RewarddataArray.islocked = true;
            }
            this.rewardarray.push(RewarddataArray);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 获取悬赏信息 待封装
    getPendingRewards() {
      this.helper
        .pendingYieldRewards(
          this.CartPoolFactoryAdress,
          this.CartCorePool_LP_Address,
          this.account
        )
        .then((res) => {
          if (res != null) {
            const pending = this.web3.utils.fromWei(res);
            this.pengdingreward = NumberDiv(parseFloat(pending), 1, 2);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 根据滑动条获取
    async getTokenWeight() {
      // 用户选择的质押时长
      var week = 7 * this.lackforweek * 24 * 60 * 60;

      this.staketime = week;

      var LockingWeight = Proxy.getLockingWeight(week);

      var Weight = NumberDiv(
        BigNumber(LockingWeight)
          .div(10 ** 24)
          .toNumber(),
        1
      );

      return Weight;
    },

    // 获取每日收益 预估的奖励数值 待封装
    async getAPR() {
      let amount = this.inputlp;

      let apy, daireward, apyNumber, apyString;

      if (this.ButtonType == 1) {
        // 周时间秒数
        const lockperiod = parseInt(this.lackforweek * 24 * 60 * 60);
        // 一天秒数
        const time = 86400 * 365;
        if (amount == 0 || amount == "") {
          amount = this.web3.utils.toWei("0.00001");
          apy = await this.helper.getPredictedRewards(
            this.CartPoolFactoryAdress,
            this.CartCorePool_LP_Address,
            amount,
            lockperiod,
            time,
            12,
            this.account,
            "0x0000000000000000000000000000000000000000",
            0
          );
          daireward = NumberDiv(this.web3.utils.fromWei(apy), 1);
          let cartamount = await this.getlpcartamount(amount);
          apyNumber = NumberDiv((daireward * 100) / cartamount, 1).toString();
          apyString = apyNumber + "%";
        } else {
          amount = this.web3.utils.toWei(this.inputlp.toString());
          apy = await this.helper.getPredictedRewards(
            this.CartPoolFactoryAdress,
            this.CartCorePool_LP_Address,
            amount,
            lockperiod,
            time,
            12,
            this.account,
            "0x0000000000000000000000000000000000000000",
            0
          );
          daireward = NumberDiv(this.web3.utils.fromWei(apy), 1);
          let cartamount = await this.getlpcartamount(amount);
          apyNumber = NumberDiv((daireward * 100) / cartamount, 1).toString();
          apyString = apyNumber + "%";
        }
        this.apy = apyString;
      } else {
        const time = 86400 * 365;

        if (amount == 0 || amount == "") {
          amount = this.web3.utils.toWei("0.00001");
          apy = await this.helper.getPredictedRewards(
            this.CartPoolFactoryAdress,
            this.CartCorePool_LP_Address,
            amount,
            0,
            time,
            12,
            this.account,
            this.NFTMockAdress,
            this.NFTSelectTokenId
          );
          daireward = NumberDiv(this.web3.utils.fromWei(apy), 1);
          let cartamount = await this.getlpcartamount(amount);
          apyNumber = NumberDiv((daireward * 100) / cartamount, 1).toString();
          if (this.NFTSelectTokenId) {
            apyString = apyNumber * 1.2 + "%";
          } else {
            apyString = apyNumber + "%";
          }
        } else {
          amount = this.web3.utils.toWei(this.inputlp.toString());
          apy = await this.helper.getPredictedRewards(
            this.CartPoolFactoryAdress,
            this.CartCorePool_LP_Address,
            amount,
            0,
            time,
            12,
            this.account,
            this.NFTMockAdress,
            this.NFTSelectTokenId
          );
          daireward = NumberDiv(this.web3.utils.fromWei(apy), 1);
          let cartamount = await this.getlpcartamount(amount);
          apyNumber = NumberDiv(
            (daireward * 100) / cartamount,
            1,
            5
          ).toString();
          if (this.NFTSelectTokenId) {
            apyString = apyNumber * 1.2 + "%";
          } else {
            apyString = apyNumber + "%";
          }
        }
        this.apy = apyString;
      }
    },

    // 待封装
    Flexwithdraw(id) {
      this.farmsFlexarray[0].isLoading = true;
      this.cartPoolLP
        .unstake(id, this.FlexunstakeAmount, { from: this.account })
        .on("transactionHash", () => {})
        .then(async () => {
          // 获取用户质押信息
          this.getStakeInfo();

          // 获取复投信息
          this.getRewards();

          // 获取悬赏信息
          this.getPendingRewards();
        })
        .catch((error) => {
          console.log("Flexwithdraw error", error);
          this.farmsFlexarray[0].isLoading = false;
        });
    },

    // 对于rewards 复投的提取 LP页面单独功能 给组件调用函数使用
    rewardclaim(index, id, amount) {
      this.rewardarray[index].isLoading = true;
      this.cartCorePool
        .unstake(id, amount, { from: this.account })
        .on("transactionHash", () => {})
        .then(() => {
          // 获取复投信息
          this.getRewards();
        })
        .catch((error) => {
          console.log("claim error", error);
          this.rewardarray[index].isLoading = false;
        });
    },

    // 待封装
    Lockwithdraw(index, id) {
      this.farmsLockarray[index].isLoading = true;
      this.cartPoolLP
        .unstake(id, this.farmsLockarray[index].LockunstakeAmount, {
          from: this.account,
        })
        .on("transactionHash", () => {})
        .then(async () => {
          // 获取用户质押信息
          this.getStakeInfo();

          // 获取复投信息
          this.getRewards();

          // 获取悬赏信息
          this.getPendingRewards();
        })
        .catch((error) => {
          console.log("Lockwithdraw error", error);
          this.farmsLockarray[index].isLoading = false;
        });
    },

    // 获取NFT函数 待封装
    async myNfts() {
      this.nonftloading = true;

      //0x0E51631F1E21bD22C591de48B273dF6139D89Ea7 nft数组地址
      //0xb7E3Ca370B099C0e9E250Feb313af77fbb5fC6ab 无nft地址
      //0x2816f3528AD324E6089714DA8E89455f58739e68 单条nft地址
      // ${this.account}

      const weightBN = await this.cartCorePool.getNFTWeight(this.NFTMockAdress);
      const weight = BigNumber(weightBN)
        .div(10 ** 18)
        .toString(10);

      let nftscan = {
        nft_address: "0x3AD503084f1bD8d15A7F5EbE7A038C064e1E3Fa1",
        page_index: "1",
        page_size: "100",
        // "user_address": "0x0E51631F1E21bD22C591de48B273dF6139D89Ea7"
        // "user_address": "0x2816f3528AD324E6089714DA8E89455f58739e68"
        user_address: `${this.account}`,
      };

      axios
        .get(
          `/gw/token?apiKey=${process.env.VUE_APP_API_ID}&apiSecret=${process.env.VUE_APP_API_SECRET}`
        )
        .then((r) => {
          // console.log('accessToken', r.data.data.accessToken)
          axios
            .post(
              `/api/v1/getNftByContractAndUserAddress?apiKey=${process.env.VUE_APP_API_ID}&apiSecret=${process.env.VUE_APP_API_SECRET}`,
              nftscan,
              {
                headers: {
                  "Access-Token": r.data.data.accessToken,
                },
              }
            )
            .then(async (res) => {
              var data = res.data.data.content;

              if (data) {
                // 获取vft数量
                let nftAmount = data.length;

                // 向上取整nft数量 获取所需要的页码数量
                this.pageAmount = nftAmount;
                // 设置当前页码为1
                this.currentPage = 1;

                for (let i = 0; i < data.length; i++) {
                  let tokenId = data[i].token_id;
                  let tokenInfo = { tokenNum: tokenId, ethAddress: "" };
                  await axios
                    .post(
                      "https://api.cryptoart.ai/api/artwork/token/detail",
                      tokenInfo
                    )
                    .then((res) => {
                      let imgURL;
                      let isVideo;

                      if (res.data.data.metadataContentType) {
                        isVideo =
                          res.data.data.metadataContentType.indexOf("video");
                        if (isVideo == -1) {
                          imgURL = res.data.data.shareUrl;
                        } else {
                          isVideo = 0;
                          imgURL = res.data.data.ossUrl;
                        }
                      } else {
                        isVideo = -1;
                        imgURL = res.data.data.shareUrl;
                      }
                      let nftdata = {
                        tokenId: tokenId,
                        isVideo: isVideo,
                        imgUrl: imgURL,
                        weight: weight,
                        name: data[i].nft_name,
                      };

                      this.MyNFTlist.push(nftdata);
                    });
                }
                this.nonftloading = false;
              } else {
                this.nonftloading = false;
              }
            });
        });
    },

    // 登出 待封装
    logout() {
      clearInterval(this.refreshPendingRewardNum);
      this.refreshPendingRewardNum = null;
      this.$store.commit("updateProviderType", "");
      this.$store.commit("updateConnectWallet", false);
      this.account = null;
      this.acc = null;
      this.web3 = null;
      this.farmsFlexarray = [];
      this.farmsLockarray = [];
      this.lpBalance = 0;
      this.pengdingreward = 0;
      this.ifStake = 0;
    },

    showConnect() {
      this.$store.commit("showSelectConnectPopup");
    },

    hidedialog() {
      this.$store.commit("showAvatarDialog", false);
      this.tipsDialog = "";
      this.popupBg = false;
    },

    //
    clearTiming() {
      this.started = true;
      clearInterval(this.timing);
    },

    // 跳过按钮的函数
    skip() {
      this.NFTSelectTokenId = 0;
      this.NFTSelectWeight = 0;
      this.NFTSelectName = "";
      this.step = 2;
      this.getAPR();
    },

    selectagain() {
      this.step = 1;
    },

    // 点击Locked按钮函数事件
    ChangeLock() {
      // 控制按钮显示的样式 点击Locked
      this.inputlp = 0;
      this.ButtonType = 1;
    },

    // 点击Flexible按钮函数事件
    ChangeFlexible() {
      // 控制按钮显示的样式 点击Flexible
      this.inputlp = 0;
      this.ButtonType = 0;
    },

    // 有锁质押弹窗
    OpenLockStakeTips() {
      this.tipsDialog = "lockstake";
      // 设置背景
      this.popupBg = true;
    },

    // 无锁质押弹窗
    OpenFlexStakeTips() {
      this.tipsDialog = "flexstake";
      // 设置背景
      this.popupBg = true;
    },

    // 有锁赎回弹窗
    OpenLockWithdrawTips(index, id) {
      this.LockWithdrawIndex = index;
      this.LockWithdrawId = id;
      this.tipsDialog = "lockwithdraw";
      // 设置背景
      this.popupBg = true;
    },

    // 无锁赎回弹窗
    OpenFlexWithdrawTips(id) {
      // this.FlexWithdrawIndex = index
      this.FlexWithdrawId = id;
      this.tipsDialog = "flexwithdraw";
      // 设置背景
      this.popupBg = true;
    },

    // 修改stake状态栏（页面左下方）显示的图标和区域
    radioLocked() {
      this.RadioType = 1;
    },

    radioFlexible() {
      this.RadioType = 0;
    },

    previousPage() {
      let currentPage = this.currentPage;
      if (currentPage > 1) {
        this.currentPage = currentPage - 1;
      }
    },

    nextPage() {
      let currentPage = this.currentPage;
      if (currentPage < this.pageAmount) {
        this.currentPage = currentPage + 1;
      }
    },

    MaxLpAmount() {
      this.inputlp = this.maxCartPoolLp;
    },

    async LockedStake() {
      this.LockedStakeLoading = true;
      var amount = BigNumber(this.inputlp).times(10 ** 18);
      this.cartPoolLP
        .stake(
          amount.toString(10),
          this.staketime,
          "0x0000000000000000000000000000000000000000",
          0,
          { from: this.account }
        )
        .on("transactionHash", () => {})
        .then(async () => {
          this.getAllowance();

          // 获取用户质押信息
          this.getStakeInfo();

          // 获取复投信息
          this.getRewards();

          // 获取悬赏信息
          this.getPendingRewards();

          this.getBalance();
          this.LockedStakeLoading = false;
        })
        .catch((error) => {
          this.LockedStakeLoading = false;
          console.log("LockedStake error", error);
        });
    },

    FlexibleStake() {
      this.FlexibleStakeLoading = true;
      var amount = BigNumber(this.inputlp).times(10 ** 18);
      this.cartPoolLP
        .stake(
          amount.toString(10),
          0,
          this.NFTMockAdress,
          this.NFTSelectTokenId,
          { from: this.account }
        )
        .on("transactionHash", () => {})
        .then(async (r) => {
          if (this.NFTSelectTokenId != 0) this.poolCardHistory();
          this.getAllowance();

          this.getStakeInfo();

          // 获取复投信息
          this.getRewards();

          // 获取悬赏信息
          this.getPendingRewards();

          this.getBalance();
          this.FlexibleStakeLoading = false;
        })
        .catch((error) => {
          this.FlexibleStakeLoading = false;
          console.log("FlexibleStake error", error);
        });
    },

    NFTSelect(id, weight, name) {
      this.NFTSelectTokenId = id;
      this.NFTSelectWeight = weight;

      this.NFTSelectName = name;
      this.step = 2;
      this.getAPR();
    },

    approve() {
      this.LockedStakeLoading = true;
      this.FlexibleStakeLoading = true;
      this.lptoken
        .approve(this.CartCorePool_LP_Address, "10000000000000000000000", {
          from: this.account,
        })
        .on("transactionHash", () => {})
        .then((r) => {
          this.getAllowance();
          this.LockedStakeLoading = false;
          this.FlexibleStakeLoading = false;
        })
        .catch((error) => {
          this.LockedStakeLoading = false;
          this.FlexibleStakeLoading = false;
          console.log("approve error", error);
        });
    },

    // 待封装
    claim() {
      this.PengdingrewardLoading = true;
      this.cartPoolLP
        .processRewards({ from: this.account })
        .on("transactionHash", () => {})
        .then((r) => {
          // 获取用户质押信息
          this.getStakeInfo();

          // 获取复投信息
          this.getRewards();

          // 获取悬赏信息
          this.getPendingRewards();

          this.PengdingrewardLoading = false;
        })
        .catch((error) => {
          console.log("claim error", error);
          this.PengdingrewardLoading = false;
        });
    },

    async getSelectWeek() {
      let endBlock = await this.factory.endBlock();
      let currentBlock = await this.web3.eth.getBlockNumber();
      let week = ((endBlock.toNumber() - currentBlock) * 12) / 604800;
      if (Math.ceil(week) < 52) {
        this.maxWeek = Math.ceil(week);
      }
    },
  },
};
</script>
<style scoped>
/* 导航栏以外整体布局背景色 */
.farmsbg {
  width: 100%;
  background: url("../assets/blockone-background.png") no-repeat;
  background-size: 100% 400%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.placeholder {
  display: flex;
  height: 0;
  color: transparent;
  font-size: 1.5rem;
  font-family: Arial-BoldMT, Arial;
}

/* 导航栏下方区域 */
.bottom {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* 导航栏下方区域 */
.farms {
  display: flex;
  flex-direction: column;
  max-width: 1420px;
}

.farmsbottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 返回按钮下方区域布局  */
.farmsblock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.5rem;
  width: 62.5rem;
  position: relative;
}

/* 返回按钮下方区域左半边 */
.farmsblockleft {
  display: flex;
  flex-direction: column;
  margin-right: 1.25rem;
}

/* 无锁时候左半边下面显示内容 */
.FlexibleBlock {
  width: 46.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.625rem;
  margin-top: 1.25rem;
  border-radius: 1.25rem;
  padding: 3.19rem 3rem 5rem 3rem;
  box-sizing: border-box;
  display: flex;
  backdrop-filter: blur(10px);
  flex-direction: column;
  margin-bottom: 1.25rem;
}

/* 有锁时候左半边下面展示内容 */
.LockedBlock {
  width: 46.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 1.25rem;
  padding: 3.19rem 3rem 5rem 3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  backdrop-filter: blur(10px);
  margin-bottom: 1.25rem;
}

/* 有锁无锁按钮区域 */
.switchbtn {
  height: 3.75rem;
  background: #0b0b0b;
  /* background: rgba(0, 0, 0, 0.3); */
  border-radius: 0.81rem;

  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
}

/* 按钮选中Locked 两个按钮的样式 */
/* 有锁按钮 包含锁图标和文字Locked*/
.lockbutton,
.flexbutton {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  border-radius: 0.81rem;
  cursor: pointer;
  align-items: center;
  font-size: 1rem;
  font-family: "CeraGR-Bold";
  font-weight: bold;
  color: #ffffff;
}

/* 被点击后的样式 */
.lockbuttonactive,
.flexbuttonactive {
  width: 50%;
  height: 100%;
  background: url("../assets/tabActive.png") no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background: linear-gradient(
    220deg,
    #fe58ca 0%,
    #00c7c3 5%,
    #5ab9f7 23%,
    #392efe 53%,
    #fd5c65 100%
  ); */
  border-radius: 0.81rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-family: "CeraGR-Bold";
  font-weight: bold;
  color: #ffffff;
}
/* 按钮的文字属性 */
.buttontext {
  font-size: 16px;
  font-family: ArialMT;
}

/* 按钮左边图片属性 */
.buttonicon {
  width: 1rem;
  object-fit: cover;
}

/* 按钮下方的文字显示 */
.flexibletext {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.75rem;
  margin-top: 2.94rem;
  text-align: left;
}

/* 按钮下方的文字显示 */
.lockedtext {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.75rem;
  margin-top: 2.94rem;
  text-align: left;
}

/* 滑动条上方的数据显示 */
.datablock {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.3125rem;
  margin-top: 3.69rem;
  align-items: center;
}

/* 数据左右两部分的布局 */
.dataleft,
.dataright {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dataleft div,
.dataright div {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #ffffff;
}

.dataleft > span,
.dataright > span {
  font-size: 1rem;
  font-family: "Gilroy-Regular";
  font-weight: 400;
  color: #ffffff;
}

/* 流程图显示区域布局 */
.progressPicBlock {
  margin-top: 3.44rem;
  margin-left: 6.54rem;
  margin-right: 7.04rem;
}
/* 没有nft区域 显示组件 */

/* 没有nft区域 显示组件 */
.nonftblock,
.nftloadingblock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.44rem;
  margin-bottom: 2.38rem;
}

/* 存在nft区域 显示组件 */
.nftblock {
  display: flex;
}

/* 跳过的文字按钮 */
.linkbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-family: ArialMT;
  color: #ffffff;
  margin-top: 2.9375rem;
}

/* 第一行文字区域 */
.textarea {
  display: flex;
  flex-direction: row;
  padding-left: 1.4375rem;
  padding-right: 0.6875rem;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 8.5625rem;
}

.textarea div {
  display: flex;
  align-items: center;
  height: 1.25rem;
}

/* 文字的左边区域 */
.textleft,
.textright {
  display: flex;
  flex-direction: row;
}

/* 左边第一段文字显示属性 */
.weighttext {
  font-size: 0.8125rem;
  font-family: ArialMT;
  color: #ffffff;
  margin-right: 0.5625rem;
}

/* select文字链接按钮属性 */
.selectbtn {
  font-size: 0.8125rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

/* 显示权重的标题 */
.weititle {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #ffffff;
}

/* 显示权重的数值 */
.weinum {
  font-size: 1rem;
  font-family: "Gilroy-Regular" !important;
  font-weight: 400;
  color: #ffffff;
  margin-left: 0.8rem;
  margin-right: 0.81rem;
}

/* 权重数值右边的图标属性 */
.weiicon {
  width: 1rem;
  height: 1rem;
  object-fit: cover;
}

/* 输入框 */
.inputarea {
  height: 4rem;

  background: rgba(255, 255, 255, 0.86);
  border-radius: 2.06rem;
  margin-top: 3.94rem;
  padding: 0.44rem 0.5rem 0.44rem 2.13rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexibleInputarea {
  margin-top: 1.3125rem;
}

/* 显示cart数据的文字属性 */
.cartinput {
  min-width: 1rem;
  font-size: 1.5rem;
  font-family: "Gilroy-SemiBold";
  font-weight: 600;
  color: #000;
  border: none;
  outline: none;
  background: transparent;
  text-align: left;
}

/* 控制输入框设置type=number 的时候不会出现滚动条选择数字 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/* 输入框左边 显示cart文字的属性 */
.inputarea .carttitle {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #000000;
  margin-left: 0.88rem;
}

/* My Balance 文字样式 */
.balancetext {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #000000;
}

/* Balance数字显示属性 */
.balancenum {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #000000;
}

/* Max最大按钮 */
.maxbutton {
  width: 5.19rem;
  height: 3.13rem;
  background: #000000;
  border-radius: 2.19rem;
  font-size: 1rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
  margin-left: 2.81rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 显示apy区域 */
.apyarea {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.19rem;
  margin-right: 2.06rem;
}

.apyarea span {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #ffffff;
  margin-right: 0.63rem;
}

.apyarea div {
  font-size: 1rem;
  font-family: "Gilroy-Regular";
  font-weight: 400;
  color: #ffffff;
}

/* enter按钮样式 */
.enteramount {
  height: 4.38rem;
  background: url("../assets/bigBoderBold.png") no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background: linear-gradient(90deg, #d44274 0%, #375ddc 100%); */
  border-radius: 0.75rem;
  margin-top: 4.31rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 按钮文字 */
.enteramount span {
  display: flex;
  height: 100%;
  font-size: 1rem;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  align-items: center;
  color: #ffffff;
}

/* enter按钮的图标 */
.loadingicon {
  width: 1rem;
  object-fit: cover;
  margin-right: 1rem;
  animation-name: loading;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* 读取图片动画 */
@keyframes loading {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

/* 查看和操作质押数据区域 左半边最下方 */
.StakeBlock,
.PendingBlock {
  width: 46.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  padding: 2.94rem 3rem 4.4rem 2.94rem;
  box-sizing: border-box;
  margin-bottom: 50px;
}

/* Stake 标题区域布局 */
.StakeTitleBlock {
  display: flex;
  justify-content: space-between;
}

/* Stake 表格区域布局 */
.StakeTableBlock,
.PendingTableBlock {
  display: flex;
  justify-content: center;
  margin-top: 1.875rem;
}
.tablebuttonblock {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* stake标题显示文字 */
.staketitle,
.pendingtitle {
  font-size: 1.5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0.0625rem;
  margin-left: 0.375rem;
}

/* 图标选择区的布局 */
.radioblock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* 左边可点击区域 和右边可点击区域 */
.radiobtnblock {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

/* 单选按钮的图片属性 */
.radioimg {
  width: 1.5rem;
  object-fit: cover;
  margin-right: 0.4375rem;
}

/* 显示Locked和flexible文字属性 */
.Lockedword {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #ffffff;
  margin-right: 2.31rem;
}

.Flexibleword {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #ffffff;
}

/* 背景暗度变低 */
.popupBg {
  z-index: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(34px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* 滑动条的按钮 */
/deep/ .el-slider__button {
  width: 0.6875rem;
  height: 0.9375rem;
  background: #ffd166;
  border: none;
  border-radius: 0;
}
/* 滑动条的按钮 */
/deep/ .el-slider__button {
  width: 1.38rem;
  height: 1.38rem;
  background: rgba(111, 111, 111, 0.9);
  box-shadow: 0rem 0.06rem 0.25rem 0rem rgba(0, 0, 0, 0.5);
  border-radius: 1.38rem;
}

/* 滑动条滑过的颜色 */
/deep/ .el-slider__bar {
  background: rgba(60, 60, 60, 0.9);
}

/deep/ .el-slider__runway {
  margin: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}

/* 轮播图 */
.carousel_container {
  margin-top: 3.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1.5625rem;
  box-sizing: border-box;
}

.carousel_container > img {
  width: 2.625rem;
  object-fit: cover;
}

.carouselPic {
  width: 12.1875rem;
  height: 17.5rem;
  object-fit: cover;
  border: 0.1875rem solid black;
}

.carousel_img {
  /* width: 26.5625rem; */
  width: 12.7812rem;
  overflow: hidden;
}

.carousel_img > div {
  display: flex;
  /* transform: translate(-28rem, 0px); */
  transform: translate(0, 0);
  transition: all 1.6s;
}

.carousel_img > div > div {
  margin-right: 1.4375rem;
  position: relative;
}

.carousel_img > div > div video,
.carousel_img > div > div img {
  border-radius: 1rem;
}

.carousel_img .NFTWhight {
  position: absolute;
  right: 3px;
  top: 3px;
  width: 5.5rem;
  height: 2rem;
  border-top-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  background-color: #fbd165;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: #000;
  font-weight: 500;
}

.carousel_img .NFTButton {
  position: absolute;
  width: 6.25rem;
  height: 2.375rem;
  bottom: 1.125rem;
  background-color: #000;
  color: #fff;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  cursor: pointer;
  font-weight: 500;
}

.carousel_img > div > div:hover img,
.carousel_img > div > div:hover video {
  border: 3px solid #fce169;
}

.carousel_img > div > div:hover .NFTButton {
  background-color: #fbd165;
  color: #000;
}

/* 表格样式 */
.Staketable {
  width: 40.56rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 表格整体样式 */
.tableclass {
  width: 40.56rem;
  /* 如果设置collapse属性 圆角属性会冲突 */
  /* border-collapse:collapse; */
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 0.625rem;
  /* width:100%;
    min-height: 8.4375rem; */
}

/* 奇数行背景颜色 */
.tableclass tr:nth-child(2n + 1) {
  background: rgba(255, 255, 255, 0.06);
}

/* 偶数行背景颜色 */
.tableclass tr:nth-child(2n) {
  background: rgba(255, 255, 255, 0.12);
}

.table_tr_v th {
  font-family: "Gilroy-Regular" !important;
}
.locked_width {
  height: 0.88rem;
  background: linear-gradient(
    220deg,
    #fe58ca 0%,
    #00c7c3 13%,
    #5ab9f7 23%,
    #392efe 53%,
    #fd5c65 100%
  );
  border-radius: 0.44rem;
  font-size: 0.75rem;
  font-family: "Gilroy-Regular";
  font-weight: 400;
  color: #ffffff;
}
/* 表格每格文字显示 */
.tableclass th {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #ffffff;
  padding: 1.25rem;
  box-sizing: border-box;
}

/* 显示天数区域 */
.tabledate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* 表格显示时间数据的图片属性  */
.tabledate img {
  width: 1.25rem;
  object-fit: cover;
  margin-right: 0.25rem;
}

/* 右侧pending reward数据和按钮区域 */
.databuttonblock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 3.93rem);
}

/* 表格里面的按钮基本样式 */
.tablebutton {
  width: 6.875rem;
  height: 1.75rem;
  border-radius: 0.875rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* 表格圆角属性 */
.tableclass tr:first-child th:first-child {
  border-top-left-radius: 0.625rem;
}

.tableclass tr:first-child th:last-child {
  border-top-right-radius: 0.625rem;
}
.tableclass tr:last-child th:first-child {
  border-bottom-left-radius: 0.625rem;
}

.tableclass tr:last-child th:last-child {
  border-bottom-right-radius: 0.625rem;
}

/* 赎回按钮样式 */
.withdrawbtn {
  width: 7.06rem !important;
  height: 2.25rem !important;
  background: url("../assets/tableBorder.png") no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 1.25rem !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.claimbtn {
  background: #ffd166;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 上锁按钮样式 */
.lockbtn {
  background: #a8a8a8;
  width: 7.06rem !important;
  height: 2.25rem !important;
}

.lockicon {
  width: 1rem;
  margin-right: 0.3125rem;
  object-fit: cover;
}
/* 表格里面的图标样式 */
.withdrawicon {
  width: 1rem;
  margin-right: 0.3125rem;
  object-fit: cover;
  animation-name: loadingBlack;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* 读取图片动画 */
@keyframes loadingBlack {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

/* 表格里面的按钮样文字式 */
.tablebuttonword {
  font-size: 1rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
}

.fixed {
  position: fixed;
  margin-left: 47.75rem;
  top: 0;
}
.tipsDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -17.25rem;
  margin-top: -8.7rem;
  z-index: 10;
  width: 34.5rem;
  height: 17.5rem;
  background: #ffffff;
  border-radius: 1.25rem;
  padding: 2.5rem 3.4rem 2.63rem 3.4rem;
  border: 0.0625rem solid #797979;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* 标题文字样式 */
.tipsTitle {
  color: #000;
  font-family: "CeraGR-Bold";
  font-weight: bold;
  line-height: normal;
  text-align: left;
  font-size: 1.38rem;
}

/* 提示文字和按钮区域 */
.textbtnblock {
  padding: 1.2rem 0 0 0;
  font-weight: 500;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 提示文字样式 */
.tipsText {
  font-family: "CeraGR-Medium";
  color: #333333;
  text-align: left;
  font-size: 1rem;
}

/* 按钮区域布局 */
.tipsButtonblock {
  margin-top: 3.38rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* 提示取消按钮外部样式 */
.tipscancel {
  width: 13.38rem;
  height: 3.75rem;
  background: url("../assets/whiteBoderTips.png") no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0.81rem !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 取消按钮文字样式 */
.tipscancel span {
  font-size: 1rem;
  font-family: "CeraGR-Bold";
  font-weight: bold;
  color: #000;
  text-align: center;
  line-height: normal;
}

/* 提示确定按钮外部样式 */
.tipsubmit {
  width: 13.38rem;
  height: 3.75rem;
  background: url("../assets/colorTips.png") no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0.81rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

/* 提交按钮文字样式 */
.tipsubmit span {
  font-size: 1rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
}
</style>