<template>
  <div class="rewards">
    <!-- 文字和图案提示区域 -->
    <div class="tipsblock">
      <!-- 文字 -->
      <div class="rewardstitle">Rewards</div>
      <!-- 图案 -->
      <div>
        <el-tooltip class="item" effect="dark" placement="top">
          <div
            slot="content"
            style="
              font-size: 1rem;
              font-family: 'CeraGR-Regular';
              font-weight: 400;
            "
          >
            Rewards are calculated from the date of acquisition and unlocked
            after 1 year. <br />After unlocking, you can claim it by yourself.
          </div>
          <img src="../assets/desc.png" class="descicon"  />
        </el-tooltip>
      </div>
    </div>

    <!-- rewards文字下方数据展示区域 -->
    <div class="rewardarea">
      <div class="rectangle" v-for="(item, index) in rewardarray" :key="index">
        <!-- 显示图标、数据和按钮区域 -->
        <div class="rewardblock">
          <!-- 显示图标区域 -->
          <div class="rewardblockleft">
            <img src="../assets/rewardicon.png" class="rewardicon" />
          </div>
          <!-- 显示数据 按钮区域 -->
          <div class="rewardblockright">
            <!-- 显示数据和文字区域-->
            <div class="datawordblock">
              <div class="Rewardesdata">
                <div>
                  <span class="cartdata">{{ item.Locktokenamount }}</span>
                  <span class="carttitle">CART</span>
                </div>
                <div>
                  <span class="lockword">Locked {{ item.passtime }} days</span>
                </div>
              </div>
              <!-- 显示锁仓时间和按钮 -->
              <div class="locktimebtnblock">
                <div v-if="item.islocked" class="tablebuttonblock">
                  <!-- 按钮外部样式 赎回-->
                  <div
                    class="tablebutton lockbtn"
                    style="background: #a8a8a8; height: 1.45rem"
                  >
                    <div style="display: flex">
                      <img src="../assets/blacklock.png" class="lockicon" />
                    </div>
                    <span class="tablebuttonword">Locked</span>
                  </div>
                </div>
                <div v-else class="tablebuttonblock">
                  <!-- 按钮外部样式 赎回-->
                  <div
                    v-if="item.isLoading"
                    class="tablebutton withdrawbtn"
                    style="cursor: not-allowed; height: 1.45rem"
                  >
                    <div style="display: flex">
                      <img
                        src="../assets/loading_black.png"
                        class="withdrawicon"
                      />
                    </div>
                    <span class="tablebuttonword">Claim</span>
                  </div>

                  <div
                    v-else
                    class="tablebutton withdrawbtn"
                    style="height: 1.45rem"
                    @click="rewardclaim(index, item.id, item.LockunstakeAmount)"
                  >
                    <span class="tablebuttonword">Claim</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 虚线 -->
        <div class="rewardslink"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import Proxy from "./../proxy.js";
// import { formatNum } from "./../decUtil.js";
export default {
  name: "Rewards",
  data() {
    return {};
  },
  props: ["rewardarray"],
  computed: {},

  watch: {},

  created() {},

  activated() {},

  methods: {
    rewardclaim(index, id, amount) {
      this.$parent.rewardclaim(index, id, amount);
    },
  },
};
</script>

<style scoped>
/* 组件整体布局 */
.rewards {
  width: 27.25rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 1.25rem;
  /* height: 24.3125rem; */
backdrop-filter: blur(10px);
  padding: 2.69rem 1.06rem 1.38rem 1.06rem;
  box-sizing: border-box;
}

/* 文字和图案提示区域 */
.tipsblock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 1.8125rem 0.4375rem;
}

/* */
.rewardarea {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1.25rem;
  padding-top:1.88rem;
  padding-bottom: 2rem;
}
/* rewards里面的矩形框 */
.rectangle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.125rem 0rem;
  
  margin-top: 1.88rem;
  box-sizing: border-box;
}
.rectangle:first-child{
    margin-top: 0;
}
.rectangle:last-child .rewardslink {
  display: none;
}

/* .rectangle:last-child{
    padding-top:0rem;
} */

/* rewards 标题提示 */
.rewardstitle {
  font-size: 1.5rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
  /* line-height: 1.75rem; */
  letter-spacing: 0.0625rem;
  margin-right: 0.75rem;
  /* margin-left: 1.81rem; */
}

/* rewards标题右边图案显示 */

/* reward中间区域虚线 */
.rewardslink {
    margin-top: 1.88rem;
    width: 100%;
  height: 0.06rem;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.descicon {
  width: 1rem;
  object-fit: cover;
  margin-top: 0.6875rem;
}

.rewardblock {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
}
.rewardblockleft {
  display: flex;
  align-items: center;
}
.rewardblockright{
    width: calc(100% - 3rem);
}
/* 显示数据和文字区域整体布局 */
.datawordblock {
  display: flex;    width: 100%;justify-content: space-between;
}

.Rewardesdata {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

/* 锁仓时间和按钮的布局 */
.locktimebtnblock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* reward区域内图标的显示 */
.rewardicon {
  width: 3rem;
  height: 3rem;
  margin-right: 0.9375rem;
  object-fit: cover;
}

/* 第二行文字属性 */
.lockword {
  font-size: 0.88rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #999999;
  text-align: left;
  /* line-height: 1.75rem; */
}

/* 显示cart数据的文字属性 */
.cartdata {
  font-size: 1.38rem;
  font-family: "Gilroy-SemiBold";
  font-weight: 600;
  color: #ffffff;
  line-height: 1.69rem;
  letter-spacing: 1px;
}

/* 显示cart标题的文字属性 */
.carttitle {
  font-size: 1rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
  margin-left: 0.5rem;
}

/* claim按钮样式 */

/* 显示天数区域 */
.tabledate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* 表格显示时间数据的图片属性  */
.tabledate img {
  width: 1rem;
  object-fit: cover;
  margin-left: 0.25rem;
}

/* claim按钮样式 */
.claimbutton {
  width: 5.6875rem;
  height: 1.75rem;
  background: #ffd166;
  border-radius: 0.875rem;
  padding: 0.375rem 1.25rem 0.3125rem 0.625rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

/* Claim文字样式 */
.claimword {
  font-size: 1rem;
  font-family: ArialMT;
  color: #4d3d11;
  /* line-height: 1.125rem; */
}

/* 按钮左边的图标显示 */
.claimicon {
  width: 1rem;
  object-fit: cover;
}

/* 表格里面的按钮区域 */
.tablebuttonblock {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 表格里面的按钮基本样式 */
.tablebutton {
  width: 6.875rem;
  height: 1.75rem;
  border-radius: 0.875rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* 上锁按钮样式 */
.lockbtn {
  background: #a8a8a8;
  justify-content: center;
}

/* 赎回按钮样式 */
.withdrawbtn {
  width: 7.06rem !important;
  height: 2.25rem !important;
  background: url("../assets/tableBorder.png") no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 1.25rem !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

/* 表格里面的图标样式 */
.lockicon {
  width: 1rem;
  object-fit: cover;
  margin-right: 0.1875rem;
}

/* 表格里面的按钮样文字式 */
.tablebuttonword {
  font-size: 1rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
}

/* 表格里面的图标样式 */
.withdrawicon {
  width: 1rem;
  margin-right: 0.3125rem;
  object-fit: cover;
  animation-name: loadingBlack;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* 读取图片动画 */
@keyframes loadingBlack {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}
</style>