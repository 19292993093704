import Vue from 'vue'
import Router from 'vue-router'
import Info from './components/Info.vue'
import CAApprove from './components/CAApprove.vue'
import Pagination from './components/Pagination.vue'
import Home from './views/Home.vue'
import Cart from './views/Cart.vue'
import Farms from './views/Farms.vue'
import Pool from './views/Pool.vue'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/info',
      name: 'Info',
      component: Info
    },
    {
      path: '/',
      name: 'Cart',
      component: Cart
    },
    {
      path: '/fixapprove',
      name: 'CAApprove',
      component: CAApprove
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/pagination',
      name: 'Pagination',
      component: Pagination
    },
    {
      path: '/pool',
      name: 'Pool',
      component: Pool
    },
    {
      path: '/farms',
      name: 'Farms',
      component: Farms
    }
  ]
  }

)

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



