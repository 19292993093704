<template>
  <div class="home" @click="popupHide()">
    <!-- 以下是弹窗部分 -->
    <div class="DialogBackground" v-show="showDialog"></div>
    <div class="DialogRectangle" v-show="showDialog">
      <!-- 第一行区域块 -->
      <div class="DialogBlock">
        <!-- 左半边部分 -->
        <div style="display: flex">
          <!-- 显示logo -->
          <div>
            <img src="../assets/DialogLogo.png" class="DialogLogo" />
          </div>
          <!-- 显示标题文字 -->
          <div style="margin-left: 1rem" class="DialogTextBlockOne">
            <div style="margin-top: 0.18rem">
              <span class="textone">CART</span>
            </div>
            <div>
              <span v-if="textState" class="texttwo"
                >Stake CART - Earn bCART</span
              >
              <span v-if="!textState" class="texttwo">Withdraw CART</span>
            </div>
          </div>
        </div>

        <!-- 右半边部分 -->
        <div class="switchbutton">
          <div
            :class="['switch_btn', switchOption == 1 && 'active_switch_btn']"
            @click="switchstake"
          >
            Stake
          </div>
          <div
            :class="['switch_btn', switchOption == 2 && 'active_switch_btn']"
            @click="switchwithdraw"
          >
            Withdraw
          </div>
          <!-- <div
            v-if="switchOption == 1"
            class="switchbtn switchstake"
            style="background: #1461f1"
            @click="switchstake"
          >
            <div class="switchstaketext">STAKE</div>
          </div>
          <div
            v-if="switchOption == 2"
            class="switchbtn switchstake"
            @click="switchstake"
          >
            <div class="switchstaketext">STAKE</div>
          </div>

          <div
            v-if="switchOption == 2"
            class="switchbtn switchwithdraw"
            style="background: rgba(0, 0, 0, 0.5);"
            @click="switchwithdraw()"
          >
            <div class="switchwithdrawtext">Withdraw</div>
          </div>

          <div
            v-if="switchOption == 1"
            class="switchbtn switchwithdraw"
            @click="switchwithdraw()"
          >
            <div class="switchwithdrawtext">Withdraw</div>
          </div> -->
        </div>
      </div>
      <!-- 第二行区域块 -->
      <div class="DialogBlockTwo">
        <!-- 上半部分区域 显示数字和CART Available-->
        <div class="available">
          <div v-if="switchOption == 1" class="availableNumber">
            {{ cartAmount }}
          </div>
          <div v-if="switchOption == 2" class="availableNumber">
            {{ cartStakedNum }}
          </div>
          <div class="availableTitle">CART Available</div>
        </div>
        <!-- 下半部分 -->
        <div class="textnumbutton">
          <div v-if="switchOption == 1" class="number">
            <input
              class="DialogInput"
              name="stakeAmount"
              v-model="stakeAmount"
              type="number"
            />
            <!--
                <div class="minprompt">Min 2000 CART</div>
              -->
          </div>
          <div v-if="switchOption == 2" class="number">
            <input
              class="DialogInput"
              name="withdrawAmount"
              v-model="withdrawAmount"
              type="number"
            />
          </div>
          <div class="textbutton">
            <div class="cartTitle">CART</div>
            <div
              v-if="switchOption == 1"
              class="maxbutton"
              @click="MaxStakeAmount()"
            >
              Max
            </div>
            <div
              v-if="switchOption == 2"
              class="maxbutton"
              @click="MaxWithdrawAmount()"
            >
              Max
            </div>
          </div>
        </div>

        <div v-if="prompt" class="minprompt">Min 2000 CART</div>
        <!--
         <div v-else class="minprompt"></div>
        -->
      </div>

      <!-- 第三行区域块 -->
      <div class="DialogBlockThree">
        <!-- 第一行显示每日收入等等信息 -->
        <div class="dailyIncomeblock">
          <div v-if="numberState" class="DailyIncomeTitle">Daily Income:</div>
          <div v-if="numberState" class="DailyIncomeNumber">
            {{ DailyIncome }}
          </div>
          <div v-if="numberState" class="bCARTTitle">bCART</div>

          <div v-if="!numberState" class="DailyIncomeTitle">Withdraw:</div>
          <div v-if="!numberState" class="DailyIncomeNumber">
            {{ withdrawAmount }}
          </div>
          <div v-if="!numberState" class="bCARTTitle">CART</div>
        </div>

        <!-- Contract联系的文字图标区域 -->
        <div class="contract">
          <!-- view contract文字属性 -->
          <div @click="toContract" class="contracttext">
            View Contract
            <img src="../assets/contract.png" class="contractPic" />
          </div>
          <!-- 图片属性 -->
        </div>
      </div>
      <!-- 第四行区域块 -->
      <div class="DialogBlockFour">
        <!-- 左边 取消按钮样式 -->
        <div class="dialogBottombtn dialog_btn_cancel" @click="cancelDialog">
          <!-- 取消按钮文字样式 -->
          <div class="dialogBottombtntext shinydarken" style="color: #000">
            Cancel
          </div>
        </div>
        <!-- 右边 确定按钮样式 -->
        <div
          v-if="switchOption == 1"
          class="dialogBottombtn dialog_btn_confrim shinydarken"
          @click="ConfirmStake()"
        >
          <!--  确定按钮文字样式 -->
          <div class="dialogBottombtntext" style="color: #ffffff">Confirm</div>
        </div>

        <div
          v-if="switchOption == 2"
          class="dialogBottombtn dialog_btn_confrim shinydarken"
          @click="ConfirmWithdraw()"
        >
          <!--  确定按钮文字样式 -->
          <div class="dialogBottombtntext" style="color: #ffffff">Confirm</div>
        </div>
      </div>
    </div>
    <!-- 以上是弹窗部分 -->

    <div></div>
    <!-- 页面第一块区域布局 :style="'min-height:' + minHeight + 'px'"-->
    <div class="block blockone">
      <!-- 上半部分背景图片设置 -->
      <!-- <img src="../assets/blockone-background.png" class="backgroundPic" > -->

      <!-- 页面顶部导航栏区域 -->
      <div class="nav">
        <NaviTop></NaviTop>
      </div>

      <div class="IntroduceTop">
        <!-- 上半部分所显示的部分 -->
        <!-- 左边部分 标题、文字、按钮、链接等元素-->
        <div class="IntroduceLeft">
          <!-- 标题 -->
          <div class="title">Stake CART Tokens</div>

          <!-- 文字介绍 -->
          <div class="text">
            Staking 2000 tokens in the pools earns you roughly 1.92 bCARTS per
            day.<br />
            With enough bCARTS, you can mint an exclusive…<br />
            Minimum deposit amount is 2000 CARTS and you can have multiple<br />
            deposits. Earnings every moment, withdraw instantly any time.

            <!-- <a href="www.baidu.com" style="color: #fff"
              >learn more
              <img src="../assets/learnmore.png" class="learnmore" />
            </a> -->
          </div>
          <div class="other_mess">
            <div class="other_mess_ground"></div>
            <div class="other_mess_block">
              <div>
                <div class="other_mess_block_count">
                  <!-- {{ cartStaked }} -->
                  {{ formatNumber(stakedAmount) }}
                  <!-- {{ cartStaked ? formatNumber(cartStaked) : 0 }} -->
                  <!-- <span v-if="formatter(cartStaked, 'number') > 100000"
                    >K+</span
                  > -->
                </div>
                <div class="other_mess_block_title">CART STAKING</div>
              </div>
              <div class="">
                <div class="other_mess_block_count">
                  {{ formatNumber(totalreward) }}

                  <!-- {{  }} -->
                  <!-- {{ totalreward ? formatNumber(totalreward) : 0 }} -->
                  <!-- <span v-if="totalreward > 100000">K+</span> -->
                </div>
                <div class="other_mess_block_title">TOTAL REWARD</div>
              </div>
              <div class="">
                <div class="other_mess_block_count">
                  {{ formatNumber(cartinreward) }}

                  <!-- {{ cartinreward }} -->
                  <!-- {{ cartinreward ? formatNumber(cartinreward) : 0 }} -->
                  <!-- <span v-if="cartinreward > 100000">K+</span> -->
                </div>
                <div class="other_mess_block_title">CART IN REWARD</div>
              </div>
            </div>
          </div>
          <!-- 按钮区域 弹性布局-->
          <div style="display: flex" class="btn_area">
            <!-- 设置stake按钮区域位置 -->
            <div style="margin-right: 1.5rem">
              <!-- 设置按钮样式 -->
              <div
                v-if="ConnectWallet"
                class="stakebtn shinydarken"
                style="cursor: pointer"
                @click="stake"
              >
                <div class="stakebtntext">STAKE</div>
              </div>

              <div
                v-else
                class="stakebtn shinydarken"
                style="cursor: not-allowed"
              >
                <div class="stakebtntext">STAKE</div>
              </div>
            </div>

            <!-- 设置buy cart按钮区域位置 -->
            <a
              class="buycartBtn shinydarken"
              href="https://www.gate.io/cn/trade/CART_USDT"
              target="_blank"
            >
              <div class="buycartBtntext">BUY CART</div>
              <!-- <a href="https://www.gate.io/cn/trade/CART_USDT" target="_blank"
                ><img src="../assets/buycart.png" class="buycart"
              /></a> -->
            </a>
          </div>

          <!-- 链接介绍部分 弹性布局-->
        </div>
        <div class="IntroduceRight">
          <img src="../assets/banner.png" alt="" />
        </div>
        <!-- 右边部分 轮播图片 style="width:22.8125rem;height:23.75rem;" -->
        <!-- <div class="carousel">
          <div class="carousel_banner">
            <img @click="next()" src="../assets/carousel.png" />
            <div :class="carousel_item_1">
              <img src="../assets/mint-1.jpg" />
            </div>
            <div :class="carousel_item_2">
              <img src="../assets/mint-2.jpg" />
            </div>
            <div :class="carousel_item_3">
              <img src="../assets/mint-3.jpg" />
            </div>
          </div>

          <div class="carousel_index">
            <div
              @mouseover="carouselSwitch(1)"
              @mouseleave="carouselStart()"
              :class="carousel_active_index == 1 ? 'active' : ''"
            ></div>
            <div
              @mouseover="carouselSwitch(2)"
              @mouseleave="carouselStart()"
              :class="carousel_active_index == 2 ? 'active' : ''"
            ></div>
            <div
              @mouseover="carouselSwitch(3)"
              @mouseleave="carouselStart()"
              :class="carousel_active_index == 3 ? 'active' : ''"
            ></div>
          </div>
        </div> -->
      </div>
      <div class="lineBack">
        <div class="linkblock">
          <!-- 链接外部按钮样式 -->
          <div class="linkblock_content">
            <div class="linkbutton">
              <!-- 链接文字 样式设置 -->
              <img src="../assets/new_home.png" alt="" class="new_home" />
              <!-- <div class="newtext">New:</div> -->
              <div class="timetext">
                Join us and add to the community of CryptoArt.Ai !
              </div>
              <img src="../assets/gotolink.png" class="gotolink" />
            </div>

            <!-- 推特等三个图标 -->
            <a href="https://t.me/cryptoartai" target="_blank"
              ><img src="../assets/telegram_top.png" class="contracticon"
            /></a>
            <a href="https://twitter.com/CryptoArt_Ai" target="_blank"
              ><img src="../assets/twitter_top.png" class="contracticon"
            /></a>
            <a href="https://twitter.com/CryptoArt_Ai" target="_blank"
              ><img src="../assets/ins_top.png" class="contracticon"
            /></a>
            <a href="https://discord.com/invite/CEUbwjJKeQ " target="_blank"
              ><img src="../assets/discord_top.png" class="contracticon"
            /></a>
          </div>
        </div>
      </div>

      <!-- 页面底部的红色显示部分 -->
      <!-- <div class="blockone-bottom">
        <div class="bottomcontent"></div>
        <div class="bottomcontent"></div>
        <div class="bottomcontent"></div>
      </div> -->
    </div>

    <!-- 页面新增布局 -->
    <div class="block_new_two">
      <div class="block_new_two_left">
        <div
          class="block_new_two_left_title divtest animated animation-delay-2"
          data-animation="fadeInUp"
        >
          01/LEARN
        </div>
        <div
          class="
            block_new_two_left_description
            divtest
            animated
            animation-delay-2
          "
          data-animation="fadeInUp"
        >
          What are the relations between CART, bCART and CARD?
        </div>
        <!-- <div
          class="block_new_two_left_time divtest animated animation-delay-2"
          data-animation="fadeInUp"
        >
          03/15
        </div> -->
      </div>
      <div
        class="block_new_two_right divtest animated animation-delay-2"
        data-animation="fadeInUp"
      >
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide>
            <img src="../assets/about-1.png" alt="" />
            <div class="img_description">
              How does bCART calculate?<br />
              You will need to pledge at least 2000 CART for each participating
              activity. Revenue<br />
              will start counting the day after purchasing. Users can check the
              revenue of bCART<br />
              every day.<br />
              After initiates the redemption, you can withdraw the principal
              CART staked and the generated bCART, and the revenue of staking
              will stop calculating on the same day..<br />

              <span
                style="
                  color: #ffffff;
                  font-family: 'CeraGR-Bold';
                  font-size: 1rem;
                "
                >Y=z*(x/30) 〖^((1/4))〗 </span
              ><br /><br />
              <span
                style="
                  color: #ffffff;
                  font-family: 'CeraGR-Bold';
                  font-size: 1rem;
                "
                >X=staked cart ,y=bcart/day,z is set 0.675 to normalize </span
              ><br /><br />
              Tip: The more tokens you staked, the quicker to earn bCART, the
              faster the rare cards <br />
              can be redeemed.
            </div>
          </swiper-slide>

          <swiper-slide
            ><img src="../assets/about-2.png" alt="" />
            <div class="img_description">
              Minting new card with acquired bCART.<br />

              Different types of cards require different bCART, and the rarer it
              is, the more bCART it takes. All kinds of cards will only be
              issued 360 copies in total.
            </div>
          </swiper-slide>
          <swiper-slide
            ><img src="../assets/about-3.png" alt="" />
            <div class="img_description">
              Redeeming the card to earn more CART!<br />

              You will receive a random number of CART awards ranging from 150
              to 2,000 when redeemed. The rarer the cards are, the more chances
              you will receive additional awards!
              <span
                style="
                  color: #ffffff;
                  font-family: 'CeraGR-Bold';
                  font-size: 1rem;
                "
                >Users redeemed at 360th, 359th, 358th and 357th can participate
                in the redemption lottery, which will divide up tens of
                thousands of CART reward pools together!</span
              >
              <br /><br />
              Tip: If users trade cards, they will no longer be qualified to
              participate in the redemption lottery. Only the initial user
              account of bCART used for accessing card is qualified.
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>

    <!-- 页面第二块布局 -->
    <div class="block_new_three">
      <div class="block_new_three_left">
        <img src="../assets/finance.gif" class="financeImg" />
      </div>
      <div class="block_new_three_right">
        <div
          class="block_new_three_right_title divtest animated animation-delay-2"
          data-animation="fadeInUp"
        >
          02/ABOUT
        </div>
        <div
          class="
            block_new_three_right_description
            divtest
            animated
            animation-delay-2
          "
          data-animation="fadeInUp"
        >
          Instruction of proce
        </div>
      </div>
    </div>

    <div class="block_new_four">
      <div class="block_new_four_header">
        <div>
          <div
            :class="['nav_item', activeNewFour === 1 && 'nav_item_active']"
            @click="activeNewFour = 1"
          >
            TYPE
          </div>
          <div
            :class="[
              'nav_item_border',
              activeNewFour === 1 && 'nav_item_border_active',
            ]"
          ></div>
        </div>
        <div>
          <div
            :class="['nav_item', activeNewFour === 2 && 'nav_item_active']"
            @click="activeNewFour = 2"
          >
            RECENT DROP
          </div>
          <div
            :class="[
              'nav_item_border',
              activeNewFour === 2 && 'nav_item_border_active',
            ]"
          ></div>
        </div>
        <div>
          <div
            :class="['nav_item', activeNewFour === 3 && 'nav_item_active']"
            @click="activeNewFour = 3"
          >
            MY CARD
          </div>
          <div
            :class="[
              'nav_item_border',
              activeNewFour === 3 && 'nav_item_border_active',
            ]"
          ></div>
        </div>
      </div>
      <div class="block_new_four_content">
        <!-- :options="swiperMintOption" -->
        <div
          class="prev_btn"
          @click="prevSwiper"
          v-if="
            (activeNewFour === 1 && MintPic.length) ||
            (activeNewFour === 2 && recentDrop.length) ||
            (activeNewFour === 3 && myCollects.length)
          "
        >
          <img src="../assets/arrow_left.png" alt="" />
        </div>
        <swiper
          ref="swiper2"
          :options="swiperMintOption"
          v-if="activeNewFour === 1"
        >
          <swiper-slide v-for="item in MintPic" :key="item.id" class="movie">
            <div class="movie__card item_border">
              <img :src="item.PicView" alt="" />
              <div class="new_mess">
                <div class="new_mess_item">
                  <div class="new_mess_item_content">
                    {{
                      item.id == 1
                        ? commonLeft
                        : item.id == 2
                        ? rareLeft
                        : lengendaryLeft
                    }}
                  </div>
                  <div class="new_mess_item_title">Left</div>
                </div>
                <div class="new_mess_item">
                  <div class="new_mess_item_content">{{ item.value }}</div>
                  <div class="new_mess_item_title">bCART</div>
                </div>
                <div class="new_mess_item">
                  <div class="new_mess_item_content">{{ item.supply }}</div>
                  <div class="new_mess_item_title">Supply</div>
                </div>
              </div>
              <div
                class="item_btn"
                style="cursor: pointer"
                @click.stop
                v-if="ConnectWallet && parseFloat(bcartNum) >= item.value"
                @click="showApprovePopup(item.id)"
              >
                MINT
              </div>
              <div
                class="item_btn"
                v-if="ConnectWallet && parseFloat(bcartNum) < item.value"
                style="cursor: not-allowed"
              >
                MINT
              </div>
              <div
                class="item_btn"
                v-if="!ConnectWallet"
                style="cursor: not-allowed"
              >
                MINT
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <swiper
          ref="swiper2"
          :options="swiperMintOption"
          v-if="activeNewFour === 2 && recentDrop.length"
        >
          <swiper-slide
            v-for="item in recentDrop"
            :key="item.tokenId + 'recentDrop'"
            class="movie"
          >
            <div class="movie__card item_border">
              <video
                class="picblock"
                muted
                webkit-playsinline
                preload
                playsinline
                v-if="item.isVideo != -1"
                :src="item.img"
              ></video>
              <img class="picblock" v-else :src="item.img" />
              <div class="item_btn" @click="makeoffer(item.tokenId)">VIEW</div>
            </div>
          </swiper-slide>
        </swiper>
        <div v-if="activeNewFour === 2 && !Droploading">
          <div
            v-if="activeNewFour === 2 && !recentDrop.length"
            class="NFTBlock"
          >
            <img src="../assets/NoNFTfound.png" class="Exclamation" />
            <div class="NFTText">
              No Kitty card drops recently, let's mint the first one!
            </div>
            <div
              v-if="ConnectWallet"
              class="MyNFTButton"
              style="cursor: pointer"
              @click="activeNewFour = 1"
            >
              GET
            </div>
            <div v-else class="MyNFTButton" style="cursor: not-allowed">
              GET
            </div>
          </div>
        </div>
        <div
          v-if="activeNewFour === 2 && Droploading"
          class="loading"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 22.75rem;
          "
        >
          <div><img src="../assets/loding.png" /></div>
        </div>
        <swiper
          ref="swiper2"
          :options="swiperMintOption"
          v-if="activeNewFour === 3 && myCollects.length"
        >
          <swiper-slide
            v-for="item in myCollects"
            :key="item.tokenId + 'myCollects'"
            class="movie"
          >
            <div class="movie__card item_border">
              <video
                class="picblock"
                muted
                webkit-playsinline
                preload
                playsinline
                v-if="item.isVideo != -1"
                :src="item.img"
              ></video>
              <img class="picblock" v-else :src="item.img" />
              <div class="item_btn" @click="redeem(item.tokenId)">Redeem</div>
            </div>
          </swiper-slide>
        </swiper>
        <div v-if="activeNewFour === 3 && !myCollects.length" class="NFTBlock">
          <img src="../assets/NoNFTfound.png" class="Exclamation" />
          <div class="NFTText">
            You currently have no redeemed nft，You can get rewards by staking
            cart
          </div>
          <div
            v-if="ConnectWallet"
            class="MyNFTButton"
            style="cursor: pointer"
            @click="stake"
          >
            STAKE
          </div>
          <div v-else class="MyNFTButton" style="cursor: not-allowed">
            STAKE
          </div>
        </div>
        <div
          class="prev_btn"
          @click="nextSwiper"
          v-if="
            (activeNewFour === 1 && MintPic.length) ||
            (activeNewFour === 2 && recentDrop.length) ||
            (activeNewFour === 3 && myCollects.length)
          "
        >
          <img src="../assets/arrow_right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="block_new_five">
      <div class="block_new_five_left">
        <div
          class="block_new_five_left_title divtest animated animation-delay-2"
          data-animation="fadeInUp"
        >
          03/Q&A
        </div>
        <div
          class="
            block_new_five_left_description
            divtest
            animated
            animation-delay-2
          "
          data-animation="fadeInUp"
        >
          Have question about<br />
          earning CART?
        </div>
      </div>
      <div
        class="block_new_five_right divtest animated animation-delay-2"
        data-animation="fadeInUp"
      >
        <collapse v-model="activeNewFive" accordion>
          <collapse-item name="7" class="block_new_five_list">
            <template slot="title">
              What is the formula for APR of pool/farm？
              <img
                src="@/assets/jian_new.png"
                style="width: 1rem; height: 0.17rem"
                alt=""
                v-if="activeNewFive == 7"
              /><img src="@/assets/add_new.png" alt="" v-else />
            </template>
            <div>
              <span
                style="
                  color: #ffffff;
                  font-family: 'CeraGR-Bold';
                  font-size: 1rem;
                "
              >
                APR= ((daily revenue * 365)/amount * 100 )%*Token Weight</span
              ><br />
              *Amount is the number of tokens staked. Daily revenue mainly
              depends on the proportion of the pledged to the total pledge pool
              and the staking time.
              <br />
              <span
                style="
                  color: #ffffff;
                  font-family: 'CeraGR-Bold';
                  font-size: 1rem;
                "
                >The calculation formula is: daily revenue = share divided per
                second * the number of tokens awarded per second for the pool *
                pledge time in seconds.</span
              ><br />
              <br /><br />
              Daily earnings=Personal stake amount/Total stake amount*The actual
              length of the deposit
            </div>
          </collapse-item>
          <collapse-item name="1" class="block_new_five_list">
            <template slot="title">
              What's the different token weight of locked and unlocked staking?
              <img
                src="@/assets/jian_new.png"
                style="width: 1rem; height: 0.17rem"
                alt=""
                v-if="activeNewFive == 1"
              /><img src="@/assets/add_new.png" alt="" v-else />
            </template>
            <div>
              1. Locked Stake:<br />
              Token Weight= Amount * ((lock period * weight multiplier) / 365
              days + weight multiplier),where lock Period is the lock time and
              Amount is the number of token pledged.
              <br />
              <br />
              2.Current (not locked) Stake :<br />
              Token Weight=Amount * (weight multiplier + nft_weight * weight
              multiplier), where nft_weight is the card addition weight, and
              Amount is the number of tokens pledged. * weight multiplier=1e24,
              which guarantees that the calculation result is an integer.
            </div>
          </collapse-item>

          <collapse-item name="4" class="block_new_five_list">
            <template slot="title">
              What is the impact of Token Weight on my staking?
              <img
                src="@/assets/jian_new.png"
                style="width: 1rem; height: 0.17rem"
                alt=""
                v-if="activeNewFive == 4"
              /><img src="@/assets/add_new.png" alt="" v-else />
            </template>
            <div>
              The higher the weight, the higher the reward available under the
              pledge of tokens of equal value.
            </div>
          </collapse-item>
          <collapse-item name="6" class="block_new_five_list">
            <template slot="title">
              Can I receive additional token awards after the staking expires?
              <img
                src="@/assets/jian_new.png"
                style="width: 1rem; height: 0.17rem"
                alt=""
                v-if="activeNewFive == 6"
              /><img src="@/assets/add_new.png" alt="" v-else />
            </template>
            <div>
              The no lock pledge award will be distributed before the end of the
              pool staking and will stop the distribution after the closure of
              the pool. But users can continue to receive the previous award
              after the closure.
            </div>
          </collapse-item>
          <collapse-item name="5" class="block_new_five_list">
            <template slot="title">
              Why can't I collect the awards pledged by Locked Staking directly?
              <img
                src="@/assets/jian_new.png"
                style="width: 1rem; height: 0.17rem"
                alt=""
                v-if="activeNewFive == 5"
              /><img src="@/assets/add_new.png" alt="" v-else />
            </template>
            <div>
              The reward of locked staking will continue to being locked for one
              year, and during when you will also enjoy the revenue from the
              lock-up and get more tokens!
            </div>
          </collapse-item>
          <collapse-item name="3" class="block_new_five_list">
            <template slot="title">
              When collecting the locked staking award, the single currency
              award also shows in my account, and why is that?
              <img
                src="@/assets/jian_new.png"
                style="width: 1rem; height: 0.17rem"
                alt=""
                v-if="activeNewFive == 3"
              /><img src="@/assets/add_new.png" alt="" v-else />
            </template>
            <div>
              Whenever a single currency is lock staked or when you collect the
              LP award, your previous total token award will be calculated and
              transferred to you to save an unnecessary gas fee.
            </div>
          </collapse-item>
        </collapse>
      </div>
    </div>

    <!-- 页面底部 弹性布局-->
    <div class="block blockfour">
      <div class="footer">
        <!-- 页面底部 左半边 -->
        <div>
          <div style="text-align: initial">
            <img
              src="../assets/logo-m.png"
              class="footerlogo"
              @click="goHome"
            />
          </div>
          <div
            class="footertext divtest animated animation-delay-2"
            data-animation="fadeInUp"
          >
            Art Evolves With Time,Asia’s largest cryptoart trading market for
            you to sell and collect authentic artworks.
          </div>
        </div>

        <!-- 页面底部 中间部分 -->
        <div>
          <div class="SocialTitle">SOCIAL</div>
          <div style="margin-top: 1.63rem">
            <a href="https://t.me/cryptoartai" target="_blank"
              ><img
                src="../assets/icon-1.png"
                class="icon"
                style="margin-right: 1.3125rem"
            /></a>
            <a href="https://twitter.com/CryptoArt_Ai" target="_blank"
              ><img
                src="../assets/icon-2.png"
                class="icon"
                style="margin-right: 1.375rem"
            /></a>
            <a href="https://www.instagram.com/cryptoart.ai/" target="_blank"
              ><img
                src="../assets/icon-3.png"
                class="icon"
                style="margin-right: 1.4375rem"
            /></a>
            <a href="https://discord.com/invite/CEUbwjJKeQ " target="_blank"
              ><img
                src="../assets/icon-4.png"
                class="icon"
                style="margin-right: 1.3125rem"
            /></a>
            <a href="https://cryptoart-ai.medium.com/" target="_blank"
              ><img src="../assets/icon-5.png" class="icon"
            /></a>
          </div>
        </div>

        <!-- 页面底部右边部分 -->
        <div style="text-align: left; width: 13.4375rem">
          <div class="EtherscanTitle">Etherscan</div>
          <div>
            <el-link
              :underline="false"
              href="https://etherscan.io/token/0x3AD503084f1bD8d15A7F5EbE7A038C064e1E3Fa1"
              target="_blank"
              class="nftlink"
              >NFT</el-link
            >
          </div>
          <div>
            <el-link
              :underline="false"
              href="https://etherscan.io/token/0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e"
              target="_blank"
              class="cartlink"
              >CART</el-link
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 点击蓝色mint按钮产生的弹窗 -->
    <div
      v-if="approvePopup"
      class="approvePopup"
      @click.stop
      @mousewheel.prevent
      @touchmove.prevent
    >
      <div>
        <img @click="popupHide" src="../assets/x_min.svg" />
      </div>
      <div>Approve Contacts spent bCART to mint</div>
      <div style="color: #fff" @click="mint()">APPROVE</div>
    </div>

    <!-- 提示已经挖过保存在MyNFT中 -->
    <div
      v-if="mintPopup"
      class="mintPopup"
      @click.stop
      @mousewheel.prevent
      @touchmove.prevent
    >
      <div>
        <img @click="popupHide" src="../assets/x_min.svg" />
      </div>
      <div>You have already minted a collection in MyNFT</div>
      <div style="color: #fff" @click="mintPopupHide">SUCCESS</div>
    </div>

    <!-- 提示已经拥有 -->
    <div
      v-if="redeemPopup"
      class="mintPopup"
      @click.stop
      @mousewheel.prevent
      @touchmove.prevent
    >
      <div>
        <img @click="popupHide" src="../assets/x_min.svg" />
      </div>
      <div>You have already earned {{ redeemAmount }} CART</div>
      <div @click="redeemPopupHide">SUCCESS</div>
    </div>

    <!-- 提示交易成功 -->
    <div
      v-if="transactionPopup"
      class="transactionPopup"
      @mousewheel.prevent
      @touchmove.prevent
    >
      <div class="hidetransaction">
        <img @click="transactionPopupHide" src="../assets/x_min.svg" />
      </div>
      <div class="started">
        <img src="../assets/clock-1.png" />
        <span v-if="started">Transaction Submitted</span>
        <span v-else>Your transaction has started</span>
      </div>
      <div>
        <span>{{ time }}</span>
        <span> - </span>
        <img src="../assets/clock-2.png" />
        <span> {{ seconds }} sec</span>
      </div>
    </div>

    <!-- 点击mint蓝色按钮挖矿 主页面背景变暗-->
    <div
      v-if="popupBg"
      class="popupBg"
      @mousewheel.prevent
      @touchmove.prevent
    ></div>
    <!-- 弹窗连接MetaMask -->
    <SelectConnectPopup :connect="ConnectWallet"></SelectConnectPopup>
    <AvatarDialog
      :bcart="bcart"
      :cartAmount="cartAmount"
      :cartStaked="cartStaked"
      :mynft="mynft"
    ></AvatarDialog>
  </div>
</template>

<script>
import SelectConnectPopup from "@/components/SelectConnectPopup.vue";
import AvatarDialog from "@/components/AvatarDialog.vue";
import NaviTop from "@/components/NaviTop.vue";
import { Collapse, CollapseItem } from "element-ui";
import Proxy from "./../proxy.js";
import axios from "axios";
import { formatNum } from "./../decUtil.js";
import { NumberMul, NumberAdd, NumberSub, NumberDiv } from "@/math.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import $ from "jquery";
import "../assets/js/jquery.hover3d.js";
import { isScroll } from "../assets/js/isScroll.js";
import BigNumber from "bignumber.js";
import "../assets/style/animate.min.css";
// import "@/assets/js/jquery-2.1.1.min";
// import $ from '@/assets/js/jquery-2.1.1.min';
// import 'swiper/dist/css/swiper.css'
export default {
  name: "Cart",
  components: {
    SelectConnectPopup,
    AvatarDialog,
    NaviTop,
    Swiper,
    SwiperSlide,
    Collapse,
    CollapseItem,
  },
  data() {
    return {
      MyNFTNum: 1,
      DropTotalNum: 1,
      // 控制弹窗是否展示
      showDialog: false,

      carousel_active_index: 2,
      carousel_item_1: "carousel_left",
      carousel_item_2: "carousel_center",
      carousel_item_3: "carousel_right",
      swiperOptions: {
        autoplay: {
          autoplay: 4000,
          disableOnInteraction: false, //放置触摸后自动轮播停止
        },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
      },
      swiperMintOption: {
        // pagination: '.swiper-pagination',
        slidesPerView: 3,
        // slidesPerColumn: 2,
        // paginationClickable: true,
      },
      activeNewFour: 1,
      activeNewFive: 0,
      // 没有连接到钱包所显示的文字
      connecttext: "Connect Wallet",

      // 控制分页组件当前的显示
      currentPage: 1,

      // 控制下方按钮样式显示
      // 1:mint按钮样式显示红色 其余两个按钮为默认样式
      // 2:drop按钮样式显示红色 其余两个按钮为默认样式
      // 3:mynft按钮样式显示红色 其余两个按钮为默认样式
      buttonflag: 1,
      // 控制第三块区域显示内容
      blockType: "mint",

      // 账户cart余额
      cartBalance: null,

      // 界面最小高度
      minHeight: 0,

      // 是否处于已连接钱包状态
      // connect:false,

      // 质押数据 cartStaked用于显示在第二块区域上方
      totalreward: 132102,

      //显示cart余额
      cartAmount: null,

      // cartinreward: 在get Balance方法中获取，然后显示total reward
      cartinreward: null,

      // cartStaked: 在mystake函数中方法中获取，然后显示cart stakeding
      cartStaked: 0,

      // bcart: 在mystake函数中方法中获取，然后显示bcart
      bcart: null,

      bcartNum: null,

      // 显示nft余额
      mynft: null,

      // 点击stake按钮产生的弹窗中数据显示 可以赎回的数据
      cartStakedNum: 0,

      // 在Recent Drop区域显示图片三个指数用来代表三种图片的索引
      commonIndex: -1,
      rareIndex: -1,
      lengendaryIndex: -1,

      // 分页页码总数
      pageTotal: 1,

      // 点击弹窗后，主页面背景发生改变
      DialogIndexBackground: false,

      // 我的收藏
      myCollects: [],
      myCollectsTotal: [],

      // 以下是挖矿 弹窗显示属性
      // 控制下面点击挖矿产生的弹窗
      approvePopup: false,

      // 控制提示用户已经挖矿成功进入MyNFT的弹窗
      mintPopup: false,

      // 控制显示 提示已经earn挖矿 的弹窗
      redeemPopup: false,

      // 控制提示用户交易开始的弹窗
      transactionPopup: false,

      // 控制stake质押界面的弹窗
      popupShow: false,

      // 控制 主页面第三块区域 页面切换的读取
      Droploading: true,
      MyNFTloading: true,

      recentDrop: [],
      recentDropTotal: [],

      // 挖矿显示图片
      MintPic: [
        {
          id: 1,
          PicView: require("@/assets/mint-1.jpg"),
          value: 75,
          supply: 300,
        },
        {
          id: 2,
          PicView: require("@/assets/mint-2.jpg"),
          value: 150,
          supply: 50,
        },
        {
          id: 3,
          PicView: require("@/assets/mint-3.jpg"),
          value: 450,
          supply: 10,
        },
        // { id: 4, PicView: require("@/assets/mint-3.jpg"), value: 450 },
        // { id: 5, PicView: require("@/assets/mint-3.jpg"), value: 450 },
      ],

      // 总共获取到用户 MyNFT的数据
      NFTPicTotal: [],

      // 以下是弹窗的内容

      // 初始化每日收入以及撤回资金的数据
      DailyIncome: (0.675 * Math.pow(2000 / 30, 0.25)).toFixed(4),
      WithdrawNumber: 2000,

      // 用来判断第三块区域显示的文字内容
      // true:显示 Daily income
      // false:显示 Withdraw
      textState: true,

      // 用来判断第三块区域显示的数字内容
      // true:显示 Daily income每日收入数据
      // false:显示 Withdraw撤回资金数据
      numberState: true,

      // 用来判断switch开关选项
      // 1:stake选项
      // 2:withdraw选项
      switchOption: 1,

      // 用来控制点击挖矿的索引
      rerity: null,

      // 获取时间所需要的参数
      started: false,
      seconds: 0,
      time: 0,
      // 控制点击mint蓝色按钮后背景暗度的变化
      popupBg: false,

      // 控制 stake弹窗质押时候提示用户最小的文字提示 是否显示
      prompt: false,

      // 控制质押状态
      staking: false,

      // 质押弹窗中 输入框中用户可以质押的数据
      stakeAmount: 2000,
      // 定义的最大质押金额
      maxstakeAmount: 0,

      // 质押弹窗中 输入框中用户可以赎回的数据
      withdrawAmount: 0,
      // 定义的最大赎回金额
      maxwithdrawAmount: 0,
      poolLpStakeNum: 0,
      poolStakeNum: 0,
      commonLeft: 300,
      rareLeft: 50,
      lengendaryLeft: 10,
      cartCount: 0,
      stakedAmount:0
    };
  },
  created() {
    var that = this;
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        that.getData(true);
      });
    }

    this.$store.commit("updateNaviTopType", "nft");
    this.carouselInterval();
    // if(this.ConnectWallet){
    //   this.getData(true)
    // }
    if (this.loginStatus) {
      this.getData(true);
    }
    this.$nextTick(() => {
      setTimeout(() => {
        // require("@/assets/js/jquery.hover3d.js");
        console.log(isScroll);
        $(".movie").hover3d({
          selector: ".movie__card",
          shine: true,
          sensitivity: 20,
        });
      }, 3000);
    });
  },

  watch: {
    stakeAmount(val) {
      this.getDailyIncome();

      if (val > parseFloat(this.maxstakeAmount)) {
        this.stakeAmount = parseFloat(this.maxstakeAmount);
      }

      if (val >= 2000) {
        this.prompt = false;
      } else {
        this.prompt = true;
      }
    },

    withdrawAmount(val) {
      if (val > parseFloat(this.maxwithdrawAmount)) {
        this.withdrawAmount = parseFloat(this.maxwithdrawAmount);
      }
    },

    loginStatus(val) {
      if (val) {
        this.getData();
      } else {
        this.logout();
      }
    },
  },

  activated() {
    var innerHeight = window.innerHeight;
    this.minHeight = innerHeight;
  },
  mounted() {
    isScroll.init(".divtest");

    this.swiper.slideTo(3, 1000, false);
    (this.swiper.el.onmouseover = function () {
      //鼠标放上暂停轮播
      this.swiper.autoplay.stop();
    }),
      (this.swiper.el.onmouseleave = function () {
        this.swiper.autoplay.start();
      });
    // this.swiper2.slideTo(3, 1000, false);
  },
  computed: {
    swiper2() {
      return this.$refs.swiper2.$swiper;
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    NaviTopType() {
      return this.$store.state.navMenu.NaviTopType;
    },
    ConnectWallet() {
      return this.$store.state.navMenu.ConnectWallet;
    },
    loginStatus() {
      return this.$store.state.navMenu.loginStatus;
    },
  },
  methods: {
    formatNumber(num) {
      return num >= 1e3 && num < 1e6
        ? (num / 1e3).toFixed(1) + "K"
        : num >= 1e6
        ? (num / 1e6).toFixed(1) + "M"
        : num;
    },

    prevSwiper() {
      this.swiper2.slidePrev();
    },
    nextSwiper() {
      this.swiper2.slideNext();
    },
    // formatNumber(num) {
    //   console.log(num);
    //   if (num == 0.0) return 0;
    //   if (num.toString().indexOf(",") > -1) {
    //     return this.formatter(num);
    //   }

    //   if (num > 100000) {
    //     return "100";
    //   }
    //   if (num >= 1e3) {
    //     return parseInt(num / 1e3).toFixed(0) + "K";
    //   }
    //   // else if (num >= 1e4) {
    //   //   return parseInt(num / 1e4).toFixed(0) + "w";
    //   // } else {
    //   //   return num;
    //   // }
    // },
    formatter(num, s) {
      if (num) {
        let str = "";
        num
          .toString()
          .split(",")
          .forEach((n, i) => (str += n));
        if (s === "number") {
          return parseInt(str);
        }
        return this.formatNumber(str);
      }
    },
    // 获取数据
    getData() {
      clearInterval(this.refreshCartNum);
      this.refreshCartNum = null;
      this.myCollectsTotal = [];
      Proxy.initWeb3Account((web3, acc) => {
        // console.log("my account address:" + acc)
        if (acc) {
          // 如果账号存在，已连接钱包

          this.account = acc;
          // 获取账号前八个字符作为显示
          var accLeft = acc.substring(0, 8);

          //获取账号后四个字符作为显示
          var accRight = acc.substr(-4);

          // 显示账号
          this.acc = accLeft + "...." + accRight;

          //初始化定义web3
          this.web3 = web3;
          // 初始化合约
          this.initContact();
          // 获取Cart数据
          this.getCart();
        }
        // else {
        //   // 表示未连接数据
        //   this.connect = false
        // }
      });
    },

    // makeoffer按钮触发事件
    makeoffer(id) {
      window.open(`https://cryptoart.ai/gallery/token?id=${id}`, "_blank");
    },

    // 点击头像触发事件
    checkinfo() {
      this.$store.commit("showAvatarDialog");
    },

    // 初始化合约
    async initContact() {
      // 调用proxy中的函数获取数据等等
      this.token = await Proxy.getToken();
      this.cart = await Proxy.getToken();
      //  this.nft =  await Proxy.getERC721TokenAt("0x5FbDB2315678afecb367f032d93F642f64180aa3");
      this.nft = await Proxy.getNFTMock();
      this.farmNft = await Proxy.getFarmNFT();
      this.farmbCart = await Proxy.getFarmbCart();
      this.cartCorePool = await Proxy.getCartCorePool();
      this.cartPoolLP = await Proxy.getCartPoolLP();
      this.helper = await Proxy.getHelper();

      // 获取合约地址余额
      this.getBalance();
      // 获取质押数据
      this.myStake();

      this.numInfo(true);
      //
      this.myNfts();
      this.refreshCart();
    },

    // 获取余额
    getBalance() {
      // 获取cart余额
      this.cart.balanceOf(this.account).then((r) => {
        this.cartAmount = this.web3.utils.fromWei(r.toString());
        const amount = Proxy.formatDecimal(parseFloat(this.cartAmount), 2);

        if (parseInt(amount) == 0) {
          this.cartAmount = 0;
          this.maxstakeAmount = 0;
        } else {
          this.cartAmount = Proxy.formatDecimal(parseFloat(amount), 2);
          this.maxstakeAmount = Proxy.formatDecimal(parseFloat(amount), 2);
        }
      });

      // 获取nft余额
      this.nft.balanceOf(this.account).then((r) => {
        this.mynft = this.web3.utils.fromWei(r.toString());
      });

      // 获取token余额
      this.token.balanceOf(this.farmNft.address).then((r) => {
        console.log(this.web3.utils.fromWei(r));
        this.cartinreward = this.web3.utils.fromWei(r);
      });
      this.token.balanceOf(this.farmbCart.address).then((r) => {
        this.stakedAmount = this.web3.utils.fromWei(r);
      })
    },
   
    // 获取质押数据 bcart用于质押 cartStaked用于赎回
    myStake() {
      this.farmbCart.myStake(this.account).then((r) => {
        /***************  赎回 cartStaked ***************/
        // 可用于赎回数据的string类型，用于展示在页面中 原理：将数据进行千位处理，用逗号隔开
        // this.cartStaked += parseInt(this.web3.utils.fromWei(r[0]));
        // this.cartCorePool.poolTokenReserve().then(async (res) => {
        //   this.poolStakeNum = parseInt(this.web3.utils.fromWei(res));
        //   if (!this.cartCount) this.cartStaked += this.poolStakeNum;
        //   this.cartPoolLP.poolTokenReserve().then((res) => {
        //     if (
        //       BigNumber(res)
        //         .div(10 ** 18)
        //         .toNumber() == 0
        //     ) {
        //       this.poolLpStakeNum = 0;
        //     } else {
        //       const LPAllStake = BigNumber(res)
        //         .div(10 ** 18)
        //         .toNumber();
        //       const tkamount = this.web3.utils.toWei(LPAllStake.toString());
        //       // this.CartCorePool_LP_Adress
        //       this.helper
        //         .lptoTokenAmount(
        //           "0x90977dcc364f9806d7eda3ac2bdd17cbe2a7dfde",
        //           tkamount
        //         )
        //         .then(async (res) => {
        //           this.poolLpStakeNum = parseInt(
        //             BigNumber(res.amount0)
        //               .div(10 ** 18)
        //               .toNumber() 
        //           );
        //           if (!this.cartCount) this.cartStaked += this.poolLpStakeNum;
        //           this.cartCount = 1;
        //         });
        //     }
        //   });
        // });

        // 可用于赎回数据的String类型，用于赋值到输入框中 获取到的原数据
        this.cartStakedNum = this.web3.utils.fromWei(r[0]);

        // 将可用于赎回的Number类型数据赋值到输入框中
        this.maxwithdrawAmount = this.cartStakedNum;

        /***************  质押 bcart ***************/
        // 可用于质押数据的string类型，用于展示在页面中 原理：将数据进行千位处理，用逗号隔开
        this.bcart = formatNum(this.web3.utils.fromWei(r[1]), 2);

        // 可用于质押数据的String类型，用于赋值到输入框中 获取到的原数据
        this.bcartNum = this.web3.utils.fromWei(r[1]);
      });
    },

    // 计算每日收益
    getDailyIncome() {
      this.DailyIncome = (
        0.675 * Math.pow(this.stakeAmount / 30, 0.25)
      ).toFixed(4);
    },

    redeem(tokenId) {
      var cartBalance = this.cartBalance;
      this.nft
        .safeTransferFrom(this.account, this.farmNft.address, tokenId, {
          from: this.account,
        })
        .on("transactionHash", () => {
          this.getTime();
        })
        .then(async () => {
          // 更新余额, TODO: 对比余额动画
          this.clearTiming();
          this.numInfo();
          this.myStake();
          this.getBalance();
          await this.getCart();
          this.redeemAmount = this.cartBalance - cartBalance;
          this.popupBg = true;
          this.redeemPopup = true;
          for (let i = 0; i < this.myCollectsTotal.length; i++) {
            console.log(this.myCollectsTotal[i].tokenId);
            if (this.myCollectsTotal[i].tokenId == tokenId) {
              this.myCollectsTotal.splice(i, 1);
            }
          }
        });
    },

    // 获取cart数据
    async getCart() {
      await Proxy.getToken()
        .then((r) => {
          return r.balanceOf(this.account);
        })
        .then((r) => {
          this.cartBalance = this.web3.utils.fromWei(r.toString());
        });
    },

    // 获取NFT
    numInfo(recentNft) {
      this.farmNft.numInfo().then((r) => {
        if (recentNft) {
          this.recentDrop = [];
          this.recentDropTotal = [];
          var refreshNft = [];
          this.recentNft(
            r[0].toNumber() - 1,
            r[1].toNumber() - 1,
            r[2].toNumber() - 1,
            refreshNft
          );
        }
        this.commonLeft = 300 - r[0].toNumber();
        this.rareLeft = 50 - r[1].toNumber();
        this.lengendaryLeft = 10 - r[2].toNumber();
      });
    },

    // 用于排列显示Recent Drop图片
    async recentNft(commonIndex, rareIndex, lengendaryIndex, refreshNft) {
      this.Droploading = true;
      let token1Ids = await this.farmNft.tokenIds(1);
      let token2Ids = await this.farmNft.tokenIds(2);
      let token3Ids = await this.farmNft.tokenIds(3);

      if (lengendaryIndex >= 0) {
        refreshNft.push(await this.getLengendary(lengendaryIndex, token3Ids));
        this.lengendaryIndex = lengendaryIndex - 1;
      }

      if (rareIndex >= 0 && refreshNft.length < 3) {
        refreshNft.push(await this.getRare(rareIndex, token2Ids));
        this.rareIndex = rareIndex - 1;
        if (this.rareIndex >= 0 && refreshNft.length < 3) {
          refreshNft.push(await this.getRare(this.rareIndex, token2Ids));
          this.rareIndex = this.rareIndex - 1;
          if (this.rareIndex >= 0 && refreshNft.length < 3) {
            refreshNft.push(await this.getRare(this.rareIndex, token2Ids));
            this.rareIndex = this.rareIndex - 1;
          }
        }
      }

      if (commonIndex >= 0) {
        var i = 10 - refreshNft.length;
        this.commonIndex = commonIndex;
        for (i > 0; i--; ) {
          if (this.commonIndex >= 0) {
            refreshNft.push(await this.getCommon(this.commonIndex, token1Ids));
            this.commonIndex = this.commonIndex - 1;
          }
        }
      }
      if (
        refreshNft.length < 3 &&
        (this.commonIndex >= 0 ||
          this.lengendaryIndex >= 0 ||
          this.rareIndex >= 0)
      ) {
        this.recentNft(
          this.commonIndex,
          this.rareIndex,
          this.lengendaryIndex,
          refreshNft
        );
      } else if (
        this.commonIndex >= 0 ||
        this.lengendaryIndex >= 0 ||
        this.rareIndex >= 0
      ) {
        this.recentDropTotal = this.recentDropTotal.concat(refreshNft);
        var lodingNft = [];
        this.recentNft(
          this.commonIndex,
          this.rareIndex,
          this.lengendaryIndex,
          lodingNft
        );
      } else {
        this.recentDropTotal = this.recentDropTotal.concat(refreshNft);
        this.DropTotalNum = this.recentDropTotal.length;
        this.pagination(1);
      }
    },

    // 登出
    logout() {
      clearInterval(this.refreshCartNum);
      this.refreshCartNum = null;
      this.$store.commit("updateProviderType", "");
      this.$store.commit("updateConnectWallet", false);
      this.account = null;
      this.acc = null;
      this.web3 = null;
      this.myCollectsTotal = [];
      this.cartStaked = 0;
      this.bcart = 0;
      this.bcartInt = 0;
      this.balanceOfAward = 0;
    },

    // 获取recentDrop数组图片
    getRecentDropNftImg() {
      let recentDrop = this.recentDrop;
      for (let i = 0; i < recentDrop.length; i++) {
        let data = {
          tokenNum: recentDrop[i].tokenId.toNumber(),
          ethAddress: "",
        };
        axios
          .post("https://api.cryptoart.ai/api/artwork/token/detail", data)
          .then((res) => {
            if (res.data.data.metadataContentType) {
              let isVideo = res.data.data.metadataContentType.indexOf("video");
              if (isVideo == -1) {
                this.recentDrop[i].img = res.data.data.shareUrl;
              } else {
                this.recentDrop[i].isVideo = isVideo;
                this.recentDrop[i].img = res.data.data.ossUrl;
              }
            } else {
              // isVideo = -1
              this.recentDrop[i].img = res.data.data.shareUrl;
            }
          });
      }
      this.recentDrop.sort();
      this.Droploading = false;
    },

    pagination(index) {
      this.Droploading = true;
      this.recentDrop = [];
      this.recentDrop = this.recentDropTotal.slice((index - 1) * 3, index * 3);
      this.getRecentDropNftImg();
    },

    myNFTpagination(index) {
      this.myCollects = [];
      this.myCollects = this.myCollectsTotal.slice((index - 1) * 3, index * 3);
    },

    recentDropChange(val) {
      this.pagination(val);
    },

    myNFTChange(val) {
      this.myNFTpagination(val);
    },

    // 获取Lengendary级别物品列表
    async getLengendary(lengendaryIndex, tokenIds) {
      let recentLengendarytokenId = tokenIds[lengendaryIndex];
      let owner = await this.nft.ownerOf(recentLengendarytokenId);
      let ownerFormat = this.ownerFormat(owner);
      // return {"tokenId": recentLengendarytokenId, "img": this.getNftImg(recentLengendarytokenId)}
      // try {
      //     let recentLengendaryNft = await this.getNFTImg(tokenIds[lengendaryIndex]);
      //     return {"tokenId": recentLengendarytokenId, "img": recentLengendaryNft, 'owner': ownerFormat}
      // } catch {
      return {
        tokenId: recentLengendarytokenId,
        img: require("../assets/loding-bg.png"),
        owner: ownerFormat,
        isVideo: -1,
      };
      // }
    },

    // 获取稀有级别物品列表
    async getRare(rareIndex, tokenIds) {
      let recentRaretokenId = tokenIds[rareIndex];
      let owner = await this.nft.ownerOf(recentRaretokenId);
      let ownerFormat = this.ownerFormat(owner);
      // return {"tokenId": recentRaretokenId, "img": this.getNftImg(recentRaretokenId)}
      // try {
      //     let recentRareNft = await this.getNFTImg(tokenIds[rareIndex])
      //     return {"tokenId": recentRaretokenId, "img": recentRareNft, 'owner': ownerFormat}
      // } catch {
      return {
        tokenId: recentRaretokenId,
        img: require("../assets/loding-bg.png"),
        owner: ownerFormat,
        isVideo: -1,
      };
      // }
    },

    // 获取普通级别物品列表
    async getCommon(commonIndex, tokenIds) {
      let recentCommontokenId = tokenIds[commonIndex];
      // 调用函数获取owner
      let owner = await this.nft.ownerOf(recentCommontokenId);

      // 将通过函数调用获取的所有者信息进行一定格式输出 格式参考ownerFormat函数
      let ownerFormat = this.ownerFormat(owner);
      // return {"tokenId": recentCommontokenId, "img": this.getNftImg(recentCommontokenId)}
      // try {
      //     let recentCommonNft = await this.getNFTImg(tokenIds[commonIndex])
      //     return {"tokenId": recentCommontokenId, "img": recentCommonNft, 'owner': ownerFormat}
      // } catch {
      return {
        tokenId: recentCommontokenId,
        img: require("../assets/loding-bg.png"),
        owner: ownerFormat,
        isVideo: -1,
      };
      // }
    },

    // 对owner字符进行一定格式输出
    ownerFormat(owner) {
      // 获取owner前四位字符
      var accLeft = owner.substring(0, 4);

      // 获取owner后四位字符
      var accRight = owner.substr(-4);

      // 将获取的八个字符拼在一起构成字符串来返回
      var ownerFormat = accLeft + "...." + accRight;
      return ownerFormat;
    },

    // 获取MyNFT图片
    myNfts() {
      this.MyNFTloading = true;
      this.farmNft.myNfts(this.account).then(async (nfts) => {
        for (let i = nfts.length - 1; i >= 0; i--) {
          let nftInfo = await this.farmNft.nftInfos(nfts[i]);
          if (!nftInfo.redeemed) {
            await this.myCollectsTotal.push({
              tokenId: nfts[i],
              detail: "https://cryptoart.ai/gallery/token?id=" + nfts[i],
              isVideo: -1,
            });
          }
        }
        for (let i = 0; i < this.myCollectsTotal.length; i++) {
          this.listMyNft(this.myCollectsTotal[i].tokenId, "fatch", i);
        }
        this.MyNFTNum = this.myCollectsTotal.length;
        this.MyNFTloading = false;
        this.myNFTpagination(1);
      });
    },

    // 获取MyNFT列表
    async listMyNft(tokenId, type, index) {
      let isNew = this.isNewTokenId(tokenId);
      let data = { tokenNum: tokenId.toNumber(), ethAddress: "" };

      await axios
        .post("https://api.cryptoart.ai/api/artwork/token/detail", data)
        .then((res) => {
          let imgURL;
          let isVideo;
          if (res.data.data.metadataContentType) {
            isVideo = res.data.data.metadataContentType.indexOf("video");
            if (isVideo == -1) {
              imgURL = res.data.data.shareUrl;
            } else {
              imgURL = res.data.data.ossUrl;
            }
          } else {
            isVideo = -1;
            imgURL = res.data.data.shareUrl;
          }

          if (type == "fatch") {
            this.myCollectsTotal[index].img = imgURL;
            this.myCollectsTotal[index].isVideo = isVideo;
            this.myCollectsTotal[index].isNew = isNew;
            this.myCollectsTotal.sort();
          } else if (type == "unshift") {
            this.myCollectsTotal.unshift({
              tokenId: tokenId,
              img: imgURL,
              isNew: isNew,
              detail: "https://cryptoart.ai/gallery/token?id=" + tokenId,
              isVideo: isVideo,
            });
            this.myCollectsTotal.sort();
          }
        });
    },

    // 重新刷新cart数据
    refreshCart() {
      var that = this;
      if (this.refreshCartNum) {
        return;
      } else {
        // 重新刷新的数据设置定时器 20min为限
        this.refreshCartNum = setInterval(function () {
          if (that.ConnectWallet) {
            // 如果钱包处于连接状态 则获取质押的数据
            that.myStake();
          }
        }, 20000);
      }
    },

    // 蓝色按钮点击关闭 隐藏弹窗函数
    popupHide() {
      // 隐藏赎回弹窗
      this.redeemPopup = false;

      // 隐藏提示mint MyNFT弹窗
      this.mintPopup = false;

      // 隐藏挖矿弹窗
      this.approvePopup = false;

      // 隐藏stake质押界面 弹窗
      this.popupShow = false;

      // 隐藏 弹窗界面中提示质押最小cart文字的显示
      this.prompt = false;

      // 控制弹窗出现时候，主界面暗度恢复
      this.popupBg = false;

      // 关闭头像弹窗
      this.$store.commit("showAvatarDialog", false);
    },

    // 隐藏挖矿函数按钮
    mintPopupHide() {
      // 恢复主页面暗度
      this.popupBg = false;

      // 隐藏mint挖矿弹窗
      this.mintPopup = false;
    },

    // 隐藏赎回函数按钮
    redeemPopupHide() {
      // 恢复主页面暗度
      this.popupBg = false;

      // 隐藏赎回弹窗
      this.redeemPopup = false;
    },

    // 隐藏交易提示窗口
    transactionPopupHide() {
      this.transactionPopup = false;
    },

    // 挖矿
    mint() {
      // 隐藏该窗口
      this.approvePopup = false;

      // 将主页面暗度恢复正常
      this.popupBg = false;

      this.farmNft
        .mint(this.rerity, { from: this.account })
        .on("transactionHash", () => {
          // 获取挖矿时间
          this.getTime();
        })
        .then((r) => {
          this.clearTiming();

          // 将主页面暗度调低
          this.popupBg = true;

          // 显示弹窗（弹窗提示用户挖矿有的已经进入MyNFT）
          this.mintPopup = true;
          // 获取信息
          this.numInfo();

          // 获取质押数据
          this.myStake();

          // listMyNFT函数内调用接口函数获取图片或者视频等等
          this.listMyNft(r.logs[0].args.tokenId, "unshift");
        });
    },

    // 获取时间
    getTime() {
      var m;
      var date = new Date();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var that = this;
      this.seconds = 0;
      this.started = false;
      this.transactionPopup = true;
      if (hours > 12) {
        m = "PM";
        hours = hours - 12;
      } else {
        m = "AM";
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      this.time = hours + ":" + minutes + m;

      this.timing = setInterval(function () {
        that.seconds = that.seconds + 1;
      }, 1000);
    },

    //
    clearTiming() {
      this.started = true;
      var that = this;
      setTimeout(function () {
        that.transactionPopupHide();
      }, 3000);
      clearInterval(this.timing);
    },

    // 判断挖矿出来的是否为最新的
    isNewTokenId(tokenId) {
      // 从客户端获取tokenId的数值
      let show = localStorage.getItem("SHOWED_" + tokenId);

      // 如果判断数值为1 则返回false 表示不是最新的
      if (show) {
        return false;
      }
      // 如果判断数值不为1 则将该tokenId 设置数值为1 同时返回true 表示是最新的
      localStorage.setItem("SHOWED_" + tokenId, 1);
      return true;
    },

    // 弹窗中点击Max按钮 （使得输入框数据变为最大质押数据）
    MaxStakeAmount() {
      // 将获取到的最大质押数据赋值给输入框model绑定的数值
      this.stakeAmount = this.maxstakeAmount;
    },

    // 弹窗中点击Max按钮 （使得输入框数据变为最大赎回数据）
    MaxWithdrawAmount() {
      // 将获取到的最大赎回数据赋值给输入框model绑定的数值
      this.withdrawAmount = this.maxwithdrawAmount;
    },

    // 点击主页面stake后 弹窗中 switch开关选项为stake
    ConfirmStake() {
      if (this.stakeAmount < 2000) {
        // 提示用户质押总额小于2000
        this.prompt = true;
      } else {
        // 设置质押状态
        this.staking = true;

        // 从指定地址发送代币
        this.cart
          .send(
            this.farmbCart.address,
            this.web3.utils.toWei(this.stakeAmount.toString()),
            "0x",
            { from: this.account }
          )
          .on("transactionHash", () => {
            // 获取时间
            this.getTime();
            // 质押按钮的背景
            // this.popup();

            // 钱包插件点击确定后 隐藏弹窗
            this.showDialog = false;
          })
          .catch(() => {
            // 将质押状态取消
            this.staking = false;
          })
          .then(() => {
            this.stakeAmount = 2000;
            this.clearTiming();
            // 获取质押数据
            this.myStake();
            this.getBalance();
            // 将质押状态取消
            this.staking = false;
            //  this.notify.close()
          });
      }
      // this.showDialog = false
    },

    // 点击主页面stake后 弹窗中 switch开关选项为withdraw
    ConfirmWithdraw() {
      // 设置质押状态
      this.staking = true;
      this.farmbCart
        .withdraw(this.web3.utils.toWei(this.withdrawAmount.toString()), {
          from: this.account,
        })
        .on("transactionHash", () => {
          // 获取时间
          this.getTime();

          // 点击撤回或者质押执行popup函数来控制显示或者隐藏
          // this.popup();

          // 钱包插件点击确定后 隐藏弹窗
          this.showDialog = false;
        })
        .catch(() => {
          // 改变质押状态
          this.staking = false;
        })
        .then(() => {
          this.withdrawAmount = 0;
          this.clearTiming();

          // 获取质押数据
          this.myStake();
          this.getBalance();
          // 改变质押状态
          this.staking = false;
        });
      // this.showDialog = false
    },

    // 点击质押或者赎回 调用的函数
    popup() {
      clearInterval(this.timing);
      // 隐藏 或者 显示 stake界面弹窗
      this.popupShow = !this.popupShow;

      // 如果弹窗是显示的
      if (this.popupShow) {
        // 如果产生弹窗则主页面背景暗度变暗
        this.popupBg = true;

        // this.selectRatio(this.ratio)
      } else {
        // 隐藏（质押窗口提示用户的最小质押金额）文字
        this.prompt = false;
        // 主界面的暗度恢复正常
        this.popupBg = false;
      }
    },

    // 点击红色mint按钮
    checkMINT() {
      this.blockType = "mint";
    },

    // 分页上一页函数
    pagepervious() {
      this.$refs.pageGroup.prev();
    },

    // 分页下一页函数
    pagenext() {
      this.$refs.pageGroup.next();
    },

    // 点击图片下方的mint按钮 产生挖矿事件
    showApprovePopup(index) {
      // 产生弹窗 将主页面背景变暗
      this.popupBg = true;

      // 将所需要挖矿的图片id传入
      this.rerity = index;

      // 点击挖矿产生的弹窗
      this.approvePopup = true;
    },

    // 查看最近drop函数
    checkDROP() {
      this.blockType = "recentDrop";
    },

    //查看MyNFT函数
    checkMyNFT() {
      this.blockType = "myNFT";
    },

    // cancel取消按钮 不展示弹窗
    cancelDialog() {
      this.showDialog = false;
      this.stakeAmount = 2000;
      this.withdrawAmount = 0;
    },

    // stake质押按钮函数 展示弹窗
    stake() {
      this.showDialog = true;
    },

    // 弹窗中stake质押按钮函数
    switchstake() {
      (this.textState = true),
        (this.numberState = true),
        (this.switchOption = 1);
    },

    // 弹窗中withdraw按钮函数
    switchwithdraw() {
      (this.textState = false),
        (this.numberState = false),
        (this.switchOption = 2),
        (this.prompt = false);
    },

    // 点击图标切换到主页面
    goHome() {
      this.$router.push("/");
      this.$store.commit("updateNaviTopType", "nft");
    },

    // 点击connect按钮
    showConnect() {
      this.$store.commit("showSelectConnectPopup");
    },

    // 轮播定时器
    carouselInterval() {
      var that = this;
      this.intervalFun = setInterval(function () {
        that.carousel();
      }, 5000);
    },

    // 轮播方法
    carousel() {
      if (this.carousel_active_index == 1) {
        this.transform("carousel_left", "carousel_center", "carousel_right", 2);
      } else if (this.carousel_active_index == 2) {
        this.transform("carousel_right", "carousel_left", "carousel_center", 3);
      } else {
        this.transform("carousel_center", "carousel_right", "carousel_left", 1);
      }
    },

    // 轮播动画
    transform(item_1, item_2, item_3, index) {
      this.carousel_item_1 = item_1;
      this.carousel_item_2 = item_2;
      this.carousel_item_3 = item_3;
      this.carousel_active_index = index;
    },

    // 轮播下一张按钮
    next() {
      this.carousel();
    },

    // 轮播下标hover
    carouselSwitch(index) {
      clearInterval(this.intervalFun);
      if (index == 1) {
        this.transform("carousel_center", "carousel_right", "carousel_left", 1);
      } else if (index == 2) {
        this.transform("carousel_left", "carousel_center", "carousel_right", 2);
      } else {
        this.transform("carousel_right", "carousel_left", "carousel_center", 3);
      }
    },

    // 轮播下标hover
    carouselStart() {
      this.carouselInterval();
    },

    toContract() {
      window.open(
        "https://etherscan.io/address/0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e",
        "_blank"
      );
    },
  },
};
</script>

<style scoped lang="stylus">
.new_mess {
  margin-top: 1.06rem;
  width: 15.06rem;
  display: flex;
  justify-content: space-between;

  .new_mess_item {
    // height: 2.25rem;
    width: 33.33%;
    border-right: 0.03rem solid rgba(151, 151, 151, 0.5);

    &:last-child {
      border: none;
    }

    .new_mess_item_title {
      font-size: 1rem;
      font-family: 'CeraGR-Regular';
      font-weight: 400;
      color: #6F6F6F;
    }

    .new_mess_item_content {
      font-size: 1.13rem;
      font-family: 'Gilroy-Medium';
      font-weight: 500;
      color: #FD5C65;
    }
  }
}

.animation-delay-2 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animation-delay-5 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animated {
  opacity: 0;
}

.movie {
  float: left;
}

.movie-list:after {
  content: ' ';
  display: block;
  clear: both;
}

.movie__card {
  position: relative;
  width: 250px;
  height: 370px;
}

.movie__card.hover-in {
  transition: 0.3s ease-out;
}

.movie__card.hover-out {
  transition: 0.3s ease-in;
}

.movie [class*='layer'] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
}

.movie .shine {
  border-radius: 10px;
}

.home {
  min-width: 100%;
  background: url('../assets/blockone-background.png') no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.blockone {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.popupBg {
  z-index: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(34px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.block {
  display: flex;
  width: 100%;
}

/* 导航栏整体 */
.nav {
  width: 100%;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  position: relative;
}

.navi {
  width: 100%;
  max-width: 1420px;
  height: 6.25rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

/* 整个导航栏长方形 */
.NavBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #141212;
  /* opacity: 0.4; */
  z-index: 1;
}

/* 导航栏右边布局 */
.navRight {
  display: flex;
  margin-top: 1.4375rem;
  margin-bottom: 1.6875rem;
  position: relative;
  z-index: 2;
}

/* 文字和下划线区域布局 */
.titlebtnblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

/* pool文字属性 */
.poolword, .farmsword, .nftword {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

/* 导航栏左上角Logo图案区域 */
.navLogoblock {
  margin: 1.9375rem 0 2.125rem 3.5625rem;
  z-index: 2;
}

/* 导航栏左上角图标 */
.navLogo {
  width: 13.9375rem;
  object-fit: cover;
  cursor: pointer;
}

/* 蓝色按钮中钱包图案属性 */
.bag {
  width: 2.0625rem;
  height: 1.875rem;
  object-fit: cover;
  margin: 0.625rem 0.8125rem 0.625rem 1rem;
}

/* 头像样式 */
.avatar {
  width: 3.125rem;
  object-fit: cover;
  margin-right: 3.5rem;
  cursor: pointer;
}

.IntroduceTop {
  // max-width: 1420px;
  // width: 100%;
  // position: absolute;
  // top: 50%;
  // transform: translate(0, -50%);
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 7rem);
  // justify-content: center;
  // padding: 0rem 7.5rem 0 6.25rem;
  box-sizing: border-box;
}

.IntroduceTop .IntroduceLeft {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -6.5rem;
}

.IntroduceRight {
  width: 46.07rem;
  margin-top: 1.69rem;
  margin-left: -8rem;

  // height: 40.73rem;
  img {
    width: 100%;
  }
}

/* 文字介绍区域 */
.introduce {
  display: flex;
  justify-content: space-between;
  padding: 0 7.5rem 0 6.25rem;
  position: relative;
  z-index: 2;
}

/* 上半部分背景 */
.backgroundPic {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* 账号文字显示 */
.accounttext {
  width: 7.6875rem;
  height: 1.375rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.375rem;
  margin: 0.875rem 1.4375rem 0.875rem 0;
}

/* 标题文字属性 */
.title {
  font-size: 3rem;
  font-family: 'CeraGR-Black';
  font-weight: 900;
  color: #ffffff;
  line-height: 3rem;
  text-align: left;
}

/* 文字介绍 */
.text {
  width: 39.38rem;
  height: 7.25rem;
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #ffffff;
  line-height: 1.75rem;
  text-align: left;
  margin-top: 1.88rem;
}

.text a {
  position: relative;
}

/* 点击更多 */
.learnmore {
  width: 0.9375rem;
  height: 0.9375rem;
  object-fit: cover;
  position: absolute;
  top: 0.3125rem;
  right: -1.25rem;
}

.other_mess {
  margin-top: 3.69rem;
  width: 35rem;
  height: 7.63rem;
  position: relative;

  .other_mess_ground {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
  }

  .other_mess_block {
    width: calc(100% - 4rem);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;

    &> div {
      // border-right: 0.03rem solid rgba(255,255,255,.5);
      width: 33.3%;

      .other_mess_block_count {
        font-size: 2.25rem;
        font-family: 'Gilroy-Bold';
        font-weight: bold;
        color: #FFFFFF;
        line-height: 2.06rem;
        text-align: center;

        span {
          color: rgba(168, 168, 168, 1);
          font-family: 'CeraGR-Medium';
          font-weight: unset;
          margin-left: -8px;
        }
      }

      .other_mess_block_title {
        margin-top: 0.81rem;
        font-size: 0.88rem;
        font-family: 'CeraGR-Medium';
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        line-height: 1rem;
      }
    }
  }
}

.btn_area {
  margin-top: 5.81rem;
}

/* stake按钮样式 */
.stakebtn {
  width: 10.25rem;
  height: 3.63rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(220deg, #FE58CA 0%, #00C7C3 5%, #5AB9F7 23%, #392EFE 53%, #FD5C65 100%);
  // background: linear-gradient(220deg, #FE58CA 0%, #00C7C3 5%, #5AB9F7 23%, #392EFE 53%, #FD5C65 100%);
  border-radius: 0.63rem;
  // filter: blur(0px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stake按钮文字样式 */
.stakebtntext {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #FFFFFF;
}

.buycartBtn {
  width: 10.25rem;
  height: 3.63rem;
  background: url('../assets/buyborder.png') no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0.63rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .buycartBtntext {
    font-size: 1rem;
    font-family: 'CeraGR-Medium';
    font-weight: 500;
    color: #FFFFFF;
  }
}

/* buycart按钮样式 */
.buycart {
  /* background: #131212;
  border-radius: 10px; */
  cursor: pointer;
  width: 10.25rem;
  height: 3.625rem;
}

.lineBack {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  width: 100%;
  // margin-top: 4rem;
  margin-top: -6.5rem;
}

/* 链接区域布局 */
.linkblock {
  display: flex;
  height: 7.5rem;
  align-items: center;
  justify-content: flex-start;
  width: 80.92rem;
  margin: auto;

  .linkblock_content {
    // max-width: 1420px;
    display: flex;
  }
}

.linkblock a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.13rem;
  height: 3.13rem;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3.13rem;
  margin-left: 1.31rem;
  transition: transform 0.8s;

  &:hover {
    transform: translateY(-10px);
  }
}

/* 中间部分推特等三个联系方式图标 */
.contracticon {
  width: 1.5rem;
}

/* 链接区域一 */
.linkbutton {
  // width: 33.06rem;
  height: 3.13rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1.56rem;
  display: flex;
  align-items: center;
  margin-right: 1.57px;

  .new_home {
    width: 3.63rem;
    margin-left: 1.81rem;
    height: 1.25rem;
  }
}

/* 文字链接属性 */
.newtext {
  margin: 0.6875rem 0.6875rem 0.875rem 1.75rem;
  width: 2.8125rem;
  height: 1.375rem;
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.375rem;
}

.timetext {
  font-size: 1.13rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
  color: #FFFFFF;
  margin-right: 0.63rem;
  margin-left: 0.63rem;
}

/* 链接跳转图标 */
.gotolink {
  width: 1.38rem;
  height: 0.94rem;
  margin-right: 1.75rem;
  // margin: 1.1875rem 1.0625rem 1.1875rem 0;
}

/* carouselImage */
.carouselImage {
  max-width: 100%;
  max-height: 100%;
}

/* 第一块页面底部区域布局 */
.blockone-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1420px;
  z-index: 2;
  position: relative;
  padding: 0 6.875rem;
  box-sizing: border-box;
}

/* 第一块页面底部内容（粉色框） */
.bottomcontent {
  width: 20rem;
  height: 0.4375rem;
  background: #f93b5d;
  border-radius: 0.9375rem 0.9375rem 0rem 0rem;
}

.block_new_two {
  margin-top: 5.25rem;
  margin-bottom: 4.5rem;
  width: 100%;
  display: flex;
  justify-content: center;

  .block_new_two_left {
    text-align: left;

    .block_new_two_left_title {
      font-size: 1.88rem;
      font-family: 'Gilroy-Regular';
      font-weight: 400;
      color: #FFFFFF;
    }

    .block_new_two_left_description {
      width: 25.31rem;
      font-size: 1.88rem;
      font-family: 'CeraGR-Black';
      font-weight: 900;
      color: #FFFFFF;
      line-height: 2.5rem;
      margin-top: 2.25rem;
    }

    .block_new_two_left_time {
      margin-top: 1rem;
      font-size: 1.56rem;
      font-family: 'Gilroy-Medium';
      font-weight: 500;
      color: #FFFFFF;
    }
  }

  .block_new_two_right {
    width: 43.44rem;
    margin-left: 6rem;

    img {
      width: 43.44rem;
      height: 19.69rem;
      border-radius: 0.63rem;
    }

    /deep/ .swiper-pagination {
      margin-top: 4.81rem;
      position: relative;
      text-align: left;
    }

    /deep/ .swiper-pagination-bullet {
      width: 3rem;
      height: 0.44rem;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.22rem;
    }

    /deep/ .swiper-pagination-bullet-active {
      width: 8.81rem;
      height: 0.44rem;
      background: #D8D8D8;
      border-radius: 0.22rem;
      border: 0.06rem solid #979797;
    }

    .img_description {
      width: 43.44rem;
      font-size: 1rem;
      font-family: 'CeraGR-Regular';
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 1.75rem;
      margin-top: 2.88rem;
      text-align: left;
    }
  }
}

.block_new_three {
  width: 100%;
  display: flex;
  height: 42.63rem;
  justify-content: flex-end;
  align-items: center;
  // background: rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(10px);
  margin-left: -0.3rem;
  margin-right: -0.3rem;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .block_new_three_right {
    // margin-left: 6.5rem;
    text-align: right;
    position: absolute;
    right: 12rem;

    .block_new_three_right_title {
      font-size: 1.88rem;
      font-family: 'Gilroy-Regular';
      font-weight: 400;
      color: #FFFFFF;
    }

    .block_new_three_right_description {
      font-size: 1.88rem;
      font-family: 'CeraGR-Black';
      font-weight: 900;
      color: #FFFFFF;
      line-height: 2.88rem;
    }
  }
}

.block_new_four {
  width: 100%;

  .block_new_four_header {
    margin-top: 7.06rem;
    display: flex;
    justify-content: center;

    &>div {
      &:first-child {
        margin-left: 0;
      }

      margin-left: 4.5rem;
    }

    .nav_item {
      font-size: 1.5rem;
      font-family: 'CeraGR-Medium';
      font-weight: 500;
      color: #999999;
      line-height: 1.88rem;
      cursor: pointer;
    }

    .nav_item_active {
      font-size: 1.5rem;
      font-family: 'CeraGR-Black';
      font-weight: 900;
      color: #FFFFFF;
    }

    .nav_item_border {
      margin-top: 0.94rem;
      width: 100%;
      height: 0.13rem;
      filter: blur(0px);
    }

    .nav_item_border_active {
      background: linear-gradient(254deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 51%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .block_new_four_content {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    /deep/ .shine {
      border-radius: 16px;
    }

    /deep/ .swiper-container {
      width: 62.04rem;
      margin-left: unset;
      margin-right: unset;
    }

    /deep/ .swiper-slide {
      margin-top: 4px;
      margin-bottom: 4px;
      width: auto !important;
    }

    .prev_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 4.5rem;
      box-shadow: inset 0.06rem 0.13rem 0.31rem 0rem rgba(255, 255, 255, 0.5);
      cursor: pointer;

      img {
        width: 1.88rem;
        height: 0.52rem;
      }

      &:first-child {
        margin-right: 1.94rem;
      }
    }

    .item_border {
      width: 18.74rem;
      height: 26.25rem;
      border-radius: 1.25rem;
      background: url('../assets/artwork_border.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-right: 1.94rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img, video {
        width: 15.06rem;
        height: 15.06rem;
        border-radius: 1rem;
        object-fit: cover;
      }

      .item_btn {
        width: 11.19rem;
        height: 2.37rem;
        // background: rgba(19, 18, 18, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../assets/item_btn_border.png') no-repeat;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 1.13rem;
        font-size: 1rem;
        font-family: 'CeraGR-Bold';
        border-radius: 1.38rem;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}

.block_new_five {
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  margin-top: 5.88rem;
  padding-top: 9.38rem;
  padding-bottom: 5.5rem;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(10px);
  margin-left: -5px;
  margin-right: -5px;

  .block_new_five_left {
    text-align: left;
    margin-right: 10.75rem;

    .block_new_five_left_title {
      font-size: 1.88rem;
      font-family: 'Gilroy-Regular';
      font-weight: 400;
      color: #FFFFFF;
      text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    }

    .block_new_five_left_description {
      margin-top: 2.16rem;
      font-size: 1.88rem;
      font-family: 'CeraGR-Black', CeraGR;
      font-weight: 900;
      color: #FFFFFF;
      line-height: 2.88rem;
      text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    }
  }

  .block_new_five_right {
    width: 45.5rem;
    margin-top: -3.2rem;

    .expendList {
      max-height: 100rem;
      transition: max-height 2s linear;
    }

    .noExpendList {
      max-height: 6.75rem;
      transition: max-height 3s linear;
    }

    /deep/ .el-collapse {
      border: none;
    }

    /deep/ .block_new_five_list {
      width: 100%;
      border-bottom: 0.06rem solid rgba(255, 255, 255, 0.3);
      overflow: hidden;

      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      .el-collapse-item__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 6.75rem;
        background-color: unset;
        border: none;
        font-size: 1.25rem;
        font-family: ' CeraGR-Medium';
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
        text-align: left;

        .el-collapse-item__arrow {
          display: none;
        }

        img {
          width: 1rem;
          height: 1rem;
        }
      }

      .el-collapse-item__wrap {
        background: unset;
        border: none;

        .el-collapse-item__content {
          padding: 0;
          text-align: left;
          font-size: 1rem;
          font-family: 'CeraGR-Regular';
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 2.88rem;
        }
      }

      .block_new_five_list_left {
        font-size: 1.25rem;
        font-family: ' CeraGR-Medium';
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
      }

      .block_new_five_list_right {
        img {
          width: 1rem;
          height: 1rem;
        }
      }

      .block_new_five_list_content {
        text-align: left;
        font-size: 1rem;
        font-family: 'CeraGR-Regular';
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 2.88rem;
      }
    }
  }
}

/* 第二块区域布局 */
.blocktwo {
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  /* background-color: #06172D; */
}

.blockTwo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

/* 第二块区域顶部区域布局 */
.blocktwo-top {
  width: 100%;
  max-width: 1420px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6.875rem;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

/* 第二块页面顶部内容（黑色框） */
.topcontent {
  width: 20rem;
  height: 10.9375rem;
  background: #1f2231;
  border-radius: 0 0 0.625rem 0.625rem;
}

/* 中间显示的totalreward数值属性 */
.TotalReward {
  width: 9.75rem;
  height: 3.1875rem;
  font-size: 2.25rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #ffffff;
  line-height: 3.1875rem;
  letter-spacing: 0.0625rem;
  margin: 2.75rem 5.125rem 0.125rem 5.125rem;
}

/* 中间显示的totalreward文字属性 */
.TotalRewardTitle {
  width: 7.1875rem;
  height: 1.0625rem;
  font-size: 0.875rem;
  font-family: AppleSystemUIFont;
  color: #ffffff;
  line-height: 1.0625rem;
  margin: 0 6.5625rem 3.8125rem 6.25rem;
}

/* 中间显示的cartinreward数值属性 */
.CartInReward {
  width: 9.75rem;
  height: 3.1875rem;
  font-size: 2.25rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #ffffff;
  line-height: 3.1875rem;
  letter-spacing: 0.0625rem;
  margin: 2.75rem 5.125rem 0.125rem 5.125rem;
}

/* 中间显示的totalreward文字属性 */
.CartInRewardTitle {
  width: 7.875rem;
  height: 1.0625rem;
  font-size: 0.875rem;
  font-family: AppleSystemUIFont;
  color: #ffffff;
  line-height: 1.0625rem;
  margin: 0 6.3125rem 3.8125rem 5.8125rem;
}

/* 中间显示的cartinreward数值属性 */
.CartStakeding {
  width: 9.75rem;
  height: 3.1875rem;
  font-size: 2.25rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #ffffff;
  line-height: 3.1875rem;
  letter-spacing: 0.0625rem;
  margin: 2.75rem 5.125rem 0.0625rem 5.125rem;
}

/* 中间显示的totalreward文字属性 */
.CartStakedingTitle {
  width: 8.25rem;
  height: 1.25rem;
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.25rem;
  margin: 0 5.9375rem 3.6875rem 5.8125rem;
}

/* 中间视频属性设置 */
.financeImg {
  width: 100%;
  // height: 42.63rem;
}

/* 第三块区域 */
.blockthree {
  flex-direction: column;
  align-items: center;
  /* background-color: #06172D; */
  position: relative;
  z-index: 2;
}

/*  */
.TitlePic {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

/* 标题CRYPTOART KITTY文字样式 */
.kittyTitle {
  margin: 9.0625rem 0 5.5rem;
  font-size: 2.25rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #ffffff;
  line-height: 3.1875rem;
  letter-spacing: 0.0625rem;
}

/* 挖矿按钮区域布局 */
.mintbtnblock {
  display: flex;
  position: absolute;
  height: 2.75rem;
  top: -1.375rem;
  left: 50%;
  transform: translate(-50%, 0);
  box-sizing: border-box;
}

/* 挖矿等三个按钮基础样式 */
.inactivebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #95888b;
  border-radius: 2rem;
  cursor: pointer;
  padding: 0 1.25rem;
  min-width: 7.5rem;
  box-sizing: border-box;
  color: #fff;
}

.inactivebtn:nth-child(2) {
  margin: 0 1.875rem;
}

.mintbtnblock .active {
  background-color: #f93b5d;
}

/* 图片和按钮区域 需要制作css动画来进行页面的横向切换 */
.picbuttonblock > div {
  display: flex;
  margin-top: 4.875rem;
  overflow: hidden;
}

.picbuttonblock .blockButton {
  width: 100%;
  height: 3.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.4375rem;
  background-color: #3063f1;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.875rem;
  cursor: pointer;
}

/* 已连接钱包状态下 蓝色mint按钮可以挖矿 */
.mintcursor {
  cursor: pointer;
}

.picbuttonblock > div > div {
  display: flex;
  transform: translateZ(0);
  transition: all 1.5s;
  backface-visibility: hidden;
}

.picbuttonblock > div .mint {
  transform: translate(0, 0);
}

.picbuttonblock > div .recentDrop {
  transform: translate(-66.9375rem, 0px);
}

.picbuttonblock > div .myNFT {
  transform: translate(-133.875rem, 0px);
}

.picitems {
  width: 61.25rem;
  display: flex;
  justify-content: space-between;
  margin-right: 5.625rem;
  flex-wrap: wrap;
  padding: 0 2px;
  box-sizing: border-box;
}

/* css伪类 用于第三区域图片的显示 */
.picitems:after {
  content: '';
  width: 16.6875rem;
}

/* 中间区域 */
.picblock {
  width: 16.6875rem;
  height: 23.625rem;
  object-fit: cover;
  border-radius: 20px;
}

/* elementButton样式 */
.mintelbutton {
  width: 16.5625rem;
  height: 3.4375rem;
  border-radius: 3.375rem;
}

/* 挖矿等三个按钮文字样式 */
.mintbtntext {
  width: 2.375rem;
  height: 1.25rem;
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.25rem;
  padding: 0.75rem 2.5625rem;
}

.dropbtntext {
  width: 6.8125rem;
  height: 1.25rem;
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.25rem;
  padding: 0.75rem 1.3125rem 0.75rem 1.25rem;
}

.mynftbtntext {
  width: 3.625rem;
  height: 1.25rem;
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.25rem;
  padding: 0.75rem 1.9375rem;
}

/* 浅蓝色矩形框 */
.rectangle {
  width: 75rem;
  height: 39rem;
  /* background: #102648; */
  border-radius: 0.625rem;
  position: relative;
  z-index: 2;
  padding: 0 6.875rem;
  margin: 0 auto;
  box-sizing: border-box;
}

/* 点击按钮MyNFT后产生的界面显示 */
.NFTBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 61.25rem;
  height: 23.3rem;
}

.NFTBlock .NFTText {
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.NFTBlock .MyNFTButton {
  width: 10.25rem;
  height: 3.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.75rem;
  background: url('../assets/btn_back.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background: linear-gradient(220deg, #FE58CA 0%, #00C7C3 13%, #5AB9F7 23%, #392EFE 53%, #FD5C65 100%); */
  border-radius: 0.81rem;
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #FFFFFF;
}

/* 按钮产生的图片 */
.Exclamation {
  width: 7.1875rem;
  object-fit: cover;
  padding-bottom: 2rem;
}

/* 点击MyNFT按钮产生的文字大小 */
.NFTtext {
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.375rem;
}

/* 底部栏 */
.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 75rem;
}

.blockfour {
  justify-content: center;
  /* background-color:#06172D; */
}

/* 底部栏左下角文字设置 */
.footertext {
  text-align: left;
  width: 28.25rem;
  height: 2.75rem;
  margin-top: 1.81rem;
  margin-bottom: 5.5rem;
  font-size: 1rem;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: rgba(255, 255, 255, 0.64);
  line-height: 1.375rem;
}

/* 底部栏左下角图标设置 */
.footerlogo {
  width: 17.25rem;
  margin-top: 8rem;
  object-fit: cover;
  cursor: pointer;
}

/* 底部图标大小 */
.icon {
  width: 3.0625rem;
  height: 3.0625rem;
}

/* 右下角etherscan的标题文字属性 */
.EtherscanTitle {
  width: 6.5625rem;
  height: 1.4375rem;
  font-size: 1rem;
  font-family: 'Arial-Black';
  font-weight: 900;
  color: #ffffff;
  text-align: initial;
  margin-top: 8.44rem;
}

/* Social标题 */
.SocialTitle {
  width: 4.5rem;
  height: 1.4375rem;
  font-size: 1rem;
  font-family: 'Arial-Black';
  font-weight: 900;
  color: #ffffff;
  line-height: 1.4375rem;
  margin-top: 8.06rem;
}

/* NFT链接 */
.nftlink {
  width: 2.125rem;
  height: 1.375rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.375rem;
  margin-top: 1.125rem;
}

/* cart链接 */
.cartlink {
  width: 2.9375rem;
  height: 1.375rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.375rem;
  margin-top: 0.8125rem;
}

/* 轮播图的样式 */
.carousel {
  display: flex;
  flex-direction: column;
}

.carousel_index {
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
}

.carousel_index div {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.625rem;
  background-color: #807b83;
  cursor: pointer;
}

.carousel_index div:nth-child(2) {
  margin: 0 0.3125rem;
}

.carousel_index .active {
  width: 3.125rem;
  border-radius: 0.625rem;
  background-color: #d8d8d8;
}

.carousel_banner {
  position: relative;
  width: 23.125rem;
  height: 23.625rem;
  z-index: 0;
}

.carousel_banner > div {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 0, 0);
  transition: all 1.6s;
  width: 16.6875rem;
  height: 100%;
}

.carousel_banner .carousel_left {
  z-index: 1;
  transform: translate3d(-80%, 0, 0) scale3d(0.7, 0.7, 1);
}

.carousel_banner .carousel_center {
  z-index: 3;
  transform: translate3d(-50%, 0) scale3d(1, 1, 1);
}

.carousel_banner .carousel_right {
  transform: translate3d(-20%, 0, 0) scale3d(0.7, 0.7, 1);
}

.carousel_banner > img {
  position: absolute;
  width: 3.125rem;
  object-fit: cover;
  left: 24.375rem;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.carousel_banner div img {
  width: 16.6875rem;
  height: 23.625rem;
  object-fit: cover;
  border-radius: 20px;
}

/* 以下是弹窗的所有样式 */
/* 弹窗整体大小设置 */
.DialogRectangle {
  z-index: 3;
  width: 34.5rem;
  height: 30.63rem;
  background: #FFFFFF;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  padding: 2.56rem 2.19rem 2.06rem 1.56rem;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 弹窗出现以后主页背景变化 */
.DialogBackground {
  z-index: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* 弹窗中每块区域的布局 */
.DialogBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.38rem;
}

/* 第二块区域布局 */
.DialogBlockTwo {
  margin-top: 2.88rem;
  height: 8.25rem;
  background: #F3F3F3;
  border-radius: 0.75rem;
  padding: 1.06rem 1.25rem 1.06rem 1.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* switch开关按钮 */
.switchbutton {
  width: 13.75rem;
  height: 3rem;
  background: rgba(102, 102, 102, 0.8);
  border-radius: 1.5rem;
  cursor: pointer;
  padding: 0.13rem 0.19rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .switch_btn {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: 'CeraGR-Bold';
    font-weight: bold;
    color: #FFFFFF;
  }

  .active_switch_btn {
    background: linear-gradient(220deg, #FE58CA 0%, #00C7C3 13%, #5AB9F7 23%, #392EFE 53%, #FD5C65 100%);
    border-radius: 1.38rem;
    filter: blur(0px);
    border-radius: 1.38rem;
  }
}

/* switch开关上的stake按钮 */
.switchstake {
  padding: 0.1875rem 0 0.1875rem 0.1875px;
}

/* switch开关基本样式 */
.switchbtn {
  width: 5.625rem;
  height: 2.5rem;
  border-radius: 1.375rem;
  background: #102648;
}

/* switch开关上的withdraw按钮 */
.switchwithdraw {
  width: 6.88rem;
  height: 2.75rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1.38rem;
  text-align: center;
}

/* switch开关上stake按钮的文字属性 */
.switchstaketext {
  width: 2.625rem;
  height: 1.25rem;
  font-size: 0.875rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.25rem;
  padding: 0.625rem 1.5rem;
}

/* switch开关上withdraw按钮的文字属性 */
.switchwithdrawtext {
  width: 3.75rem;
  height: 1.0625rem;
  font-size: 0.75rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.0625rem;
  padding: 0.75rem 0.9375rem;
}

/* 弹窗第一块区域的文字信息 */
.DialogTextBlockOne {
  display: flex;
  flex-direction: column;
  text-align: left;
  // width: 10.4375rem;
  // height: 1.0625rem;
}

/* 左上角图标设置 */
.DialogLogo {
  width: 3.38rem;
  height: 3.38rem;
  object-fit: cover;
}

/* 图标右边上方文字显示 */
.textone {
  font-size: 1.38rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
  color: #000000;
  letter-spacing: 1px;
}

/* 图标右边下方文字显示 */
.texttwo {
  margin-top: 0.38rem;
  font-size: 0.88rem;
  font-family: 'CeraGR-Regular', CeraGR;
  font-weight: 400;
  color: #666666;
}

/* 第二块区域第一行布局 */
.available {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-items: center;
}

/* 显示空闲金额数字 */
.availableNumber {
  margin-right: 0.63rem;
  font-size: 1.25rem;
  font-family: 'Gilroy-SemiBold';
  font-weight: 600;
  color: #000000;
  letter-spacing: 1px;
}

/* 显示空闲金额标题 */
.availableTitle {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #000000;
}

/* 第二块区域 */
.number {
  width: 4.375rem;
  height: 2.0625rem;
  font-size: 1.5rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color: #111010;
  line-height: 2.0625rem;
  letter-spacing: 0.0625rem;
}

/* 第二部分 文字、数字和按钮区域 */
.textnumbutton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.625rem;
}

/* 提示最小质押数值 */
.minprompt {
  font-size: 0.5rem;
  font-weight: 700;
  font-family: Arial-Black, Arial;
  text-align: left;
  min-height: 1.0625rem;
}

/* 控制输入框设置type=number 的时候不会出现滚动条选择数字 */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/* 输入框 */
.DialogInput {
  border: none;
  background: #f3f3f3;
  outline: none;
  font-size: 1.38rem;
  font-family: 'Gilroy-Bold';
  font-weight: bold;
  color: #000;
  line-height: 2.0625rem;
  letter-spacing: 0.0625rem;
  width: 15rem;
}

/* 第二部分 文字和按钮区域 */
.textbutton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* cart字样样式 */
.cartTitle {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #000000;
  margin-right: 0.75rem;
}

/* max按钮样式 */
.maxbutton {
  width: 4.13rem;
  height: 2.25rem;
  background: #333333;
  border-radius: 0.5rem;
  background: url('../assets/borderBold.jpg') no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
}

/* 第三块区域 */
.DialogBlockThree {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1.75rem;
  width: 100%;
}

/* 读取样式 */
.loading {
  width: 61.25rem;
  height: 100%;
  margin-right: 5.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 读取加载图片的样式 */
.loading img {
  width: 3rem;
  object-fit: cover;
  animation-name: loading;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* 读取图片动画 */
@keyframes loading {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

/* 显示每日收入区域布局 */
.dailyIncomeblock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* cart文字属性 */
.DailyIncomeTitle {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #000000;
  margin-right: 0.5rem;
}

/* 每日收入数字属性 */
.DailyIncomeNumber {
  font-size: 1.38rem;
  font-family: 'Gilroy-SemiBold';
  font-weight: 600;
  color: #000000;
  letter-spacing: 1px;
  margin-right: 0.44rem;
}

/* 每日收入单位文字属性 */
.bCARTTitle {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #000000;
}

/* 联系contract区域布局 */
.contract {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.56rem;
}

/* 联系文字样式 */
.contracttext {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #4A76FB;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* 联系图标样式 */
.contractPic {
  width: 1.01rem;
  height: 1rem;
  object-fit: cover;
  margin-left: 0.63rem;
}

/* 第四块区域布局 */
.DialogBlockFour {
  display: flex;
  justify-content: space-between;
  margin-top: 3.44rem;
  width: 100%;
}

/* 弹窗底部两个按钮的基本样式 颜色不同 */
.dialogBottombtn {
  width: 15rem;
  height: 3.75rem;
  border-radius: 0.75rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dialog_btn_cancel {
  font-size: 1rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
  color: #000;
  background: url('../assets/borderWhite.jpg') no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.dialog_btn_confrim {
  background: linear-gradient(220deg, #FE58CA 0%, #00C7C3 13%, #5AB9F7 23%, #392EFE 53%, #FD5C65 100%);
  font-size: 1rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
  color: #FFFFFF;
}

/* 弹窗底部两个按钮的基本样式 颜色不同 */
.dialogBottombtntext {
  font-size: 1rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
}

/* 点击蓝色挖矿按钮产生弹窗的样式 */
.approvePopup, .mintPopup {
  width: 18.75rem;
  height: 11.25rem;
  background-color: #ffffff;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  left: 50%;
  top: 50%;
  margin-left: -9.375rem;
  margin-top: -5.625rem;
  border-radius: 0.625rem;
  z-index: 10;
  padding: 0.5rem;
  box-sizing: border-box;
}

.approvePopup div:first-child, .mintPopup div:first-child {
  display: flex;
  justify-content: flex-end;
  margin-right: -15rem;
  cursor: pointer;
}

.approvePopup div:nth-child(2), .mintPopup div:nth-child(2) {
  width: 11.875rem;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-top: 0.5rem;
}

.approvePopup div:last-child, .mintPopup div:last-child {
  width: 13.5625rem;
  height: 2.6875rem;
  line-height: 2.6875rem;
  font-size: 0.875rem;
  font-weight: 900;
  margin-top: 1.5rem;
  background: #1363f1;
  border-radius: 2.6875rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

/* 交易成功弹窗样式 */
.transactionPopup {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 2.5rem;
  bottom: 2.8125rem;
  width: 18.8125rem;
  height: 6.0625rem;
  border-radius: 0.625rem;
  background-color: #fff;
  z-index: 3;
  align-items: center;
  padding: 0.3rem;
  box-sizing: border-box;
}

/* 交易完成弹窗 关闭图标的样式 */
.hidetransaction {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.transactionPopup div:last-child {
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  color: #808080;
  /* margin-left: 3.25rem; */
  margin-top: 0.4375rem;
  align-items: flex-end;
}

.transactionPopup div:last-child span:nth-child(2) {
  margin: 0 0.3125rem;
}

.transactionPopup div:last-child span {
  margin-top: 0px;
}

.transactionPopup div:last-child img {
  margin-right: 0.1875rem;
  width: 1rem;
}

/*  */
.started {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: #1363f1;
  /* margin-left: 1.125rem; */
  font-family: din_black;
}

.started img {
  width: 1.375rem;
}

.started span {
  margin-left: 0.6875rem;
}

/* 分页组件整体样式 */
.PageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.7rem;
}

/* 分页饿了么组件 */
.PageContainer img {
  cursor: pointer;
}

.PageContainer .disabled {
  cursor: not-allowed;
}

/deep/ .el-pagination {
  font-size: 1.25rem;
  padding: 0 2.125rem;
  display: flex;
}

/deep/ .el-pager li {
  background-color: #95888b;
  font-size: 1.25rem;
  font-family: din_light;
  font-weight: 600;
  color: #fff;
  min-width: initial;
  width: 3rem;
  height: 3rem;
  padding: 0;
  border-radius: 3rem;
  line-height: 3rem;
  margin: 0 0.625rem;
}

/deep/ .el-pager li.active {
  background-color: #3063f1;
}
</style>
