<template>
  <div class="goback" >
    <!-- 显示图标 -->
    <div>
      <img src="../assets/GoBack.png" class="gobackicon" @click="goBack()"/>
    </div>

    <!-- 显示文字 -->
    <span class="gobacktext" @click="goBack()">Go back</span>
  </div>
</template>

<script>
export default {
  name: "GoBack",
  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  activated() {},

  methods: {
    goBack() {
      this.$router.push("/");
      this.$store.commit("updateNaviTopType", "nft");
    },
  },
};
</script>

<style scoped>
/* 返回组件整体样式 */
.goback {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
 
}

/* 返回图标属性 */
.gobackicon {
  width: 0.46rem;
  height: 0.88rem;
   cursor: pointer;
}

/* 返回文字属性 */
.gobacktext {
  font-size: 1.13rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #ffffff;
  margin-left: 2.13rem;
   cursor: pointer;
}
</style>