<template>
<div>
  <h1> 修复授权艺术家问题 </h1>
    <div class="row-center">
      <input v-model="artist" placeholder="艺术家地址" />
      <button @click="setAllowedArtist">许可艺术家</button>
      <button @click="isEnabledForAccount">查询: {{ approved }}</button>
    </div>
</div>
</template>

<script>
/* eslint-disable */

import Proxy from "../proxy.js";

export default {
  name: 'CAApprove',
  data() {
    return {
      account: null,
      approved: null,
      artist: null,
    }
  },

  created() {
    Proxy.initWeb3Account((web3, acc) => {
      this.account = acc
      this.web3 = web3
      this.init()
    });
  },

  methods: {
    async init() {
      this.seviceAccessControl = await Proxy.getSelfServiceAccessControls();
      console.log("seviceAccessControl addr:" + this.seviceAccessControl.address)

    },

    setAllowedArtist() {
      this.seviceAccessControl.setAllowedArtist(this.artist, true, {from: this.account} );
    },

    isEnabledForAccount() {
      this.seviceAccessControl.isEnabledForAccount(this.artist, {from: this.account} ).then((r) => {
        this.approved = r;
      })
    }

  }

}
</script>
