<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import Proxy from "./proxy.js";
export default {
  name: "App",
  async created() {
    
    this.getData();
    await this.switchChain();
    const _self = this;
    window.ethereum.on("networkChanged", function (networkIDstring) {
      if(networkIDstring!=1){
        // _self.switchChain();
      }
    });
  },

  computed: {},

  methods: {
    getData() {
      if (!window.ethereum) {
        window.open("https://metamask.io/download/", "_blank");
      }
    },
    async switchChain() {
      window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        })
        .then(() => {
          Proxy.initWeb3Account((web3, acc) => {
            if (acc) {
              // console.log('连接成功')
            }
          });
        });
    },
  },
};
</script>

<style  lang="stylus">
.shinydarken {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: -120%;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(184deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    transform: skewx(42deg);
    -webkit-transform: skewx(42deg);
  }

  &:hover:after {
    transition: all 0.5s ease;
    left: 100%;
  }

  @keyframes shine {
    0% {
      background-position-x: 400%;
    }

    50% {
      background-position-x: 0%;
    }

    100% {
      background-position-x: -400%;
    }
  }

  @keyframes shine {
    0% {
      background-position-x: 400%;
    }

    50% {
      background-position-x: 0%;
    }

    100% {
      background-position-x: -400%;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body, h1, h2, h3, h4, h5, h6, footer {
  margin: 0;
  padding: 0;
}

/* /deep/ .el-table__header{

    width:100%!important;
}

/deep/ .el-table__body{

    width:100%!important;
}
 */
/* .el-table__row{
    background-color: yellowgreen!important;
}

.el-table__row--striped{
    background: red!important;
} */
/* .outComponent .el-table__row{
    background-color: yellowgreen!important;
}

.outComponent .el-table__row--striped{
    background-color: red!important;
} */
/* .outComponent /deep/ .el-table__row{
    background-color: yellowgreen!important;
}

.outComponent /deep/ .el-table__row--striped{
    background-color: red!important;
} */
</style>
