<template>
<div>
  <h1> 辅助管理信息： </h1>
    <p> 我的余额: {{ myBalance }} CART  </p>
    <p> 质押总量: {{ stakedAmount }} CART </p>

    <p> 合约 持有的 NFT 数量: {{ nftCount }} </p>
    <p> 已挖出 NFT 数量, Common: {{ commonMined }} , Rare: {{ rareMined }} , lengendary: {{ lengendaryMined }} </p>
    <p> 已赎回 NFT 数量, Common: {{ commonRedeemed }} , Rare: {{ rareRedeemed }} , lengendary: {{ lengendaryRedeemed }} </p>

    <!-- <p> Common NFT 有: {{ commonTokenIDs }} </p>
    <p> Rare NFT 有: {{ rareTokenIDs }} </p>
    <p> Lengendary NFT 有: {{ lengendaryTokenIDs }} </p> -->

    <p> 合约 CART 余额: {{ balanceOfAward }} CART </p>

    <div class="row-center">
      <el-button size="medium" type="primary" @click="approveall">NFT 授权</el-button>
    </div>

    <div class="row-center">
      <el-input v-model="transAmount" placeholder="输入转入量"></el-input>
      <div style="height: 3px"></div>
      <el-button size="medium" type="primary" @click="transferStake">存入到合约</el-button>
      <el-button size="medium" type="primary" @click="withdraw">管理员取回CART</el-button>
    </div>

    <div class="row-center">
      <el-input v-model="tokenIDs" placeholder="输入tokenId, 以逗号分隔"></el-input>
      <div style="height: 3px"></div>
      <el-button size="medium" type="primary" @click="withdraw">管理员取回NFT</el-button>
    </div>

    <div class="row-center">
      <el-input v-model="tokenID" placeholder="输入tokenId, 以逗号分隔"> </el-input>
      <div style="height: 3px"></div>
      <el-button size="medium" type="primary" @click="tokenURI">查 URI </el-button>
      <el-button size="medium" type="primary" @click="ownerOf">查 ownerOf </el-button>
      <el-button size="medium" type="primary" @click="exists">查存在？</el-button>
      <label> {{ result }} </label>
    </div>

    <div class="row-center">
      <el-input v-model="address" placeholder="输入地址查询质押CART与bCART"></el-input>
      <div style="height: 3px"></div>
      <el-button size="medium" type="primary" @click="inquiry">查询</el-button>
      <div style="height: 3px"></div>
      <div>bCART余额: {{ bCART }}</div>
      <div>CART STAKED: {{ cartStaked }}</div>
    </div>


  <div>
    <h3>测试说明:</h3>
    <p> 1. 安装 MetaMask 插件钱包。</p>
     <p>2. 当前部署在heco测试网， 因此MetaMask钱包需要添加一个heco测试网络， 在MetaMask网络处点击添加一个 自定义RPC </p>
     <p>3. 自定义RPC配置如下：网络名称：Heco test,
       RPC URL: https://http-testnet.hecochain.com,
       链 ID： 256,
       符号： HT,
       浏览器 URL :https://testnet.hecoinfo.com/ </p>
  </div>


</div>
</template>

<script>
/* eslint-disable */

import Proxy from "../proxy.js";
import { formatNum } from "./../decUtil.js";

export default {
  name: 'HOME',
  data() {
    return {
      account: null,
      myBalance: null,
      nftCount: null,
      stakedAmount: null,
      balanceOfAward: null,

      commonTokenIDs: null,
      rareTokenIDs: null,
      lengendaryTokenIDs: null,

      commonMined: null,
      rareMined: null,
      lengendaryMined: null,
      tokenID: null,
      result: null,
      

      commonRedeemed: null,
        rareRedeemed: null,
  lengendaryRedeemed: null,

      transAmount: null,
      tokenIDs: null,
      address: null,
      bCART: null,
      cartStaked: null,
    }
  },

  created() {
    Proxy.initWeb3Account((web3, acc) => {
      this.account = acc
      this.web3 = web3
      this.init()
    });
  },

  methods: {
    async init() {
      this.token = await Proxy.getToken();
      console.log("token addr:" + this.token.address)

      this.stake = await Proxy.getFarmbCart();
      this.farmNft = await Proxy.getFarmNFT();
      console.log("farmNft addr:" + this.farmNft.address)

      this.nft = await Proxy.getNFTMock();
      console.log("nft addr:" + this.nft.address)

      this.getBalance();
      // this.getNtfTokens();
      this.numInfo();
    },

    approveall() {
      this.nft.setApprovalForAll(this.farmNft.address, true, {from: this.account} );
    },

    tokenURI() {
      this.nft.tokenURI(this.tokenID).then(r => {
        this.result = r;
        console.log(r)
      }); 
    },

    ownerOf() {
      this.nft.ownerOf(this.tokenID).then(r => {
        console.log(r)
        this.result = r;
      }); 
    },

    exists() {
      this.nft.exists(this.tokenID).then(r => {
        console.log(r)
        this.result = r;
      }); 
    },
    

    numInfo() {
      this.farmNft.numInfo().then(r => {
        this.commonMined = r[0].toNumber();
        this.rareMined = r[1].toNumber();
        this.lengendaryMined = r[2].toNumber();

        this.commonRedeemed = r[3].toNumber();
        this.rareRedeemed = r[4].toNumber();
        this.lengendaryRedeemed = r[5].toNumber();
      })
    },

    getBalance() {
      this.token.balanceOf(this.account).then((r) => {
        this.myBalance = this.web3.utils.fromWei(r);
      })

      this.token.balanceOf(this.stake.address).then((r) => {
        this.stakedAmount = this.web3.utils.fromWei(r);
      })

      this.token.balanceOf(this.farmNft.address).then((r) => {
        this.balanceOfAward = this.web3.utils.fromWei(r);
      })

      this.nft.balanceOf(this.farmNft.address).then((r) => {
        this.nftCount = r.toNumber();
      })
      
    },

    getNtfTokens() {
      this.farmNft.tokenIds(1).then((r) => {
        this.commonTokenIDs = r;
      })

      this.farmNft.tokenIds(2).then((r) => {
        this.rareTokenIDs = r;
      })

      this.farmNft.tokenIds(3).then((r) => {
        this.lengendaryTokenIDs = r;
      })
    },

    transferStake() {
      let amount = this.web3.utils.toWei(this.transAmount);
      this.token.transfer(this.farmNft.address, amount, {from: this.account} ).then(() => {
        this.getBalance();
      });
    },

    withdrawNFT() {
      let idArr =  this.tokenIDs.split(',');
      this.farmNft.withdraw(idArr, this.account, {from: this.account});
    },

    withdraw() {
      let amount = this.web3.utils.toWei(this.transAmount);
      this.farmNft.withdrawCart(amount, this.account, {from: this.account}).then(() => {
        this.getBalance();
      });
    },

    inquiry() {
      this.bCART = null 
      this.cartStaked = null
      this.stake.myStake(this.address).then(r => {
        this.bCART = formatNum(this.web3.utils.fromWei(r[1]), 2);
        this.cartStaked = formatNum(this.web3.utils.fromWei(r[0]), 2);
      })
    },

  }

}
</script>

<style scoped>
  .row-center {
    margin-bottom: 10px;
  }

  /deep/ .el-input__inner {
    width: 80% !important;
  }
</style>
