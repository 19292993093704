<template>
    <div class="progressbar">
        <!-- 流程图 -->
        <div class="progressPic">
            <!-- 1 -->
            <div class="circle circle_active" >1</div>
            <!-- 第一根连接线 -->
            <div class="prolink prolink_active" ></div>
            <!-- 2 -->
            <div class="circle circle_active" >2</div>
            <!-- 第二根连接线 -->
            <div class="prolink" ></div>
            <!-- 3 -->
            <div class="circle">3</div>
        </div>
        <!-- 文字 -->
        <div class="textblock">
            <div class="progressText">Select NFT</div>
            <div class="progressText">Stake</div>
            <div class="progressText">Rewards+</div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ProgressBarTwo',
  data() {
    return {

    }
  },

  created() {},

  methods: {},
}
</script>

<style scoped>
/* 整个流程图的样式布局 */
.progressbar {
  display: flex;
  flex-direction: column;
}

/* 流程图案一行的布局 */
.progressPic {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0 0.5rem;
    box-sizing: border-box; */
}

/* 文字区域 */
.textblock {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  justify-content: space-between;
}

/* 流程图中圆形的样式显示 */
.circle {
  width: 4.63rem;
  height: 4.63rem;
  box-shadow: inset 0.06rem 0.13rem 0.31rem 0rem rgba(255, 255, 255, 0.5);
  border-radius: 4.63rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.25rem;
  font-family: "Gilroy-Bold";
  font-weight: bold;
  color: #ffffff;
}
.circle_active {
  color: #ffffff;
  background: url("../assets/active_process.png") no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
/* 流程图的连接线样式 */
.prolink {
  width: 4.38rem;
  height: 0.13rem;

  border-radius: 0.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.prolink_active {
  background: linear-gradient(
    220deg,
    #fe58ca 0%,
    #00c7c3 13%,
    #5ab9f7 23%,
    #392efe 53%,
    #fd5c65 100%
  );
}
/* 流程图圆圈下面文字属性 */
.progressText {
  font-size: 1rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #666666;
}

/* enter按钮的图标 */
.loadingicon {
  width: 1rem;
  object-fit: cover;
}
</style>