<template>
  <div class="top">
    <div class="NaviTop">
      <div class="navLogoblock">
        <img class="navLogo" src="../assets/logo-m.png" @click="backHome" />
      </div>
      <div class="navRight">
        <!-- 三个文字按钮 -->
        <div class="titlebtnblock" @click="backHome">
          <div class="nftword">NFT</div>
          <div
            class="navtitlelink"
            :class="NaviTopType == 'nft' ? '' : 'nodisplay'"
          ></div>
        </div>
        <div class="titlebtnblock" @click="gotoPool">
          <div class="poolword">Pool</div>
          <div
            class="navtitlelink"
            :class="NaviTopType == 'pool' ? '' : 'nodisplay'"
          ></div>
        </div>
        <div class="titlebtnblock" @click="gotoFarms">
          <div class="farmsword">Farm</div>
          <div
            class="navtitlelink"
            :class="NaviTopType == 'farms' ? '' : 'nodisplay'"
          ></div>
        </div>

        <!-- 钱包 -->
        <!-- 如果钱包处于连接状态 -->
        <div v-if="ConnectWallet" class="connectbtn">
          <img class="bag" src="../assets/bbagM.png" />
          <span class="accounttext">{{ account }}</span>
        </div>

        <!-- 显示头像 -->
        <div v-if="ConnectWallet" @click="clickAvatar()" @click.stop>
          <img class="avatar" src="../assets/avatar.png" />
        </div>

        <div
          v-if="!ConnectWallet"
          class="connectbtn"
          style="cursor: pointer"
          @click="showConnect()"
        >
          <img class="bag" src="../assets/bbagM.png" />
          <span class="accounttext">{{ connecttext }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Proxy from "./../proxy.js";
export default {
  name: "NaviTop",
  // props: ['account'],
  data() {
    return {
      // 初始化钱包账号显示
      acc: null,

      // 没有连接到钱包所显示的文字
      connecttext: "Connect Wallet",
    };
  },

  computed: {
    NaviTopType() {
      return this.$store.state.navMenu.NaviTopType;
    },
    provider() {
      return this.$store.state.navMenu.provider;
    },
    ConnectWallet() {
      return this.$store.state.navMenu.ConnectWallet;
    },
    account() {
      return this.$store.state.navMenu.account;
    },
  },

  watch: {},

  created() {},

  activated() {},

  methods: {
    // 点击图标切换到主页面
    backHome() {
      this.$router.push("/");
      this.$store.commit("updateNaviTopType", "nft");
    },

    // 点击进入Pool页面
    gotoPool() {
      this.$router.push("/pool");
      this.$store.commit("updateNaviTopType", "pool");
    },

    // 点击进入Pool页面
    gotoFarms() {
      this.$router.push("/farms");
      this.$store.commit("updateNaviTopType", "farms");
    },

    // 点击connect按钮 连接钱包
    showConnect() {
      this.$store.commit("showSelectConnectPopup");
    },

    // 点击头像弹窗产生
    clickAvatar() {
      this.$store.commit("showAvatarDialog", true);
    },
  },
};
</script>

<style scoped>
/* 整个组件页面布局 */
.top {
  width: 100%;
  height: 6.25rem;
  /* background: url("../assets/blockone-background.png") no-repeat; */
  /* background-size: cover; */
  display: flex;
  justify-content: center;
}

/* 导航栏顶部 */
.NaviTop {
  display: flex;
  width: 100%;
  max-width: 1420px;
  justify-content: space-between;
}

/* 导航栏logo区域布局 */
.navLogoblock {
  margin: 1.9375rem 0 2.125rem 3.5625rem;
}

/* 导航栏左上角图标 */
.navLogo {
  width: 13.9375rem;
  object-fit: cover;
  cursor: pointer;
}

/* 导航栏右边布局 */
.navRight {
  display: flex;
  margin-top: 1.4375rem;
  margin-bottom: 1.6875rem;
  /* margin-right: 3.5rem; */
}

/* 文字和下划线区域布局 */
.titlebtnblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 4.6875rem;
  cursor: pointer;
}

/* pool文字属性 */
.poolword,
.farmsword,
.nftword {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

/* 导航栏选中文字下方线段样式 */
.navtitlelink {
  width: 1.88rem;
  height: 0.11rem;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 51%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(0px);
  margin-top: 7px;
  /* width: 4rem;
    height: 0.25rem;
    background: inherit;
    background-color: rgba(19, 99, 241, 1);
    border: none;
    border-radius: 1.1875rem;
    box-shadow: none;
    margin-top:0.6875rem;
    cursor: pointer; */
}

/* 没被点击时 隐藏导航栏文字按钮下方横线 */
.nodisplay {
  background-color: transparent;
  background: unset !important;
}

/* 连接按钮属性 */
.connectbtn {
  width: 13rem;
  height: 3rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1.5rem;
  /* width: 13rem;
    height: 3.125rem;
    background: #1363F1;
    border-radius: 1.5625rem; */
  margin-right: 2.1875rem;
  text-align: initial;
  display: flex;
}

/* 蓝色按钮中钱包图案属性 */
.bag {
  width: 2.0625rem;
  height: 1.875rem;
  object-fit: cover;
  margin: 0.625rem 0.8125rem 0.625rem 1rem;
}

/* 账号文字显示 */
.accounttext {
  width: 7.6875rem;
  height: 1.375rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.375rem;
  margin: 0.875rem 1.4375rem 0.875rem 0;
}

/* 头像样式 */
.avatar {
  width: 3.125rem;
  object-fit: cover;
  margin-right: 3.5rem;
  cursor: pointer;
}

/* 蓝色按钮中钱包图案属性 */
.bag {
  width: 2.0625rem;
  height: 1.875rem;
  object-fit: cover;
  margin: 0.625rem 0.8125rem 0.625rem 1rem;
}
</style>