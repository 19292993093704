<template>
  <div class="rewards">
    <!-- rewards 标题和图案 -->
    <div class="tipsblock">
      <!-- 文字提示 -->
      <div class="rewardstitle">Rewards</div>

      <!-- 图案 -->
      <div style="display:flex;align-items:center;">
        <el-tooltip class="item" effect="dark" placement="top">
          <div
            slot="content"
            style="
              font-size: 1rem;
              font-family: 'CeraGR-Regular';
              font-weight: 400;
            "
          >
            Rewards are calculated from the date of acquisition and unlocked
            after 1 year. <br />After unlocking, you can claim it by yourself.
          </div>
          <img src="../assets/desc.png" class="descicon" v-if="isQuestion" />
        </el-tooltip>
      </div>
    </div>

    <!-- 深蓝色矩形框 -->
    <div class="rectangle">
      <!-- 无记录图标 -->
      <div>
        <img src="../assets/recordicon.png" class="recordicon" />
      </div>
      <!-- 无记录文字 -->

      <div style="margin-top: 0.31rem">
        <span class="norecordword">No record</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoRewards",
  props: {
    isQuestion:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  activated() {},

  methods: {},
};
</script>

<style scoped>
/* 组件整体布局 */
.rewards {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 1.25rem;
  backdrop-filter: blur(10px);
  width: 27.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.69rem 1.06rem 1.38rem 1.06rem;
  box-sizing: border-box;
}

/* 文字和图案提示区域 */
.tipsblock {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*  */
  margin: 0 0 1.8125rem 0.4375rem;
}

/* rewards 标题提示 */
.rewardstitle {
  /* margin-left: 1.81rem; */
  font-size: 1.5rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
}

/* rewards标题右边图案显示 */
.descicon {
  width: 1rem;
  object-fit: cover;
  margin-left:0.5625rem;
}

/* rewards里面的矩形框 */
.rectangle {
  width: 25.13rem;
  height: 21.63rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 无记录图标属性 */
.recordicon {
  width: 5.63rem;
  height: 5.25rem;
  /* height:6.5625rem; */
}

/* 无记录文字属性 */
.norecordword {
  font-size: 1.13rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #999999;
}
</style>