<template>
  <div class="avatardialog" v-if="showAvatarDialog" @mousewheel.prevent>
    <div class="avatarcontainer" @click.stop>
      <!-- 标题和关闭图标显示区域 -->
      <div class="titlecloseblock">
        <!-- Your wallet -->
        <div class="wallettext">
          <div class="wallet_title">Your wallet</div>
          <div class="wallet_description">
            {{ accountData }}
          </div>
        </div>

        <!-- 关闭图标 -->
        <div style="cursor: pointer" @click="hidedialog()" @click.stop>
          <img src="../assets/x_min.svg" />
        </div>
      </div>

      <!-- 显示账号 -->
      <div class="acctext">{{ account }}</div>
      <div class="secound_back">
        <!-- 显示按钮数据和按钮区域 -->
        <div class="cartbutton">
          <!-- 显示数据 -->
          <div>
            <!-- cart文字显示样式 -->
            <div class="cartword">CART</div>

            <!-- cart数据显示样式 -->
            <div class="cartnum">{{ cartAmount || 0 }}</div>
          </div>

          <!-- change按钮 -->
          <div class="changebtn" @click="change()">
            <div class="changetext">Change</div>
          </div>
        </div>

        <!-- 虚线 -->
        <!-- <div class="solidlink"></div> -->

        <!-- 显示数据区域 -->
        <div class="cartdata">
          <div class="datablock">
            <div class="datatitle">bCART</div>

            <div class="dataword">{{ bcart || 0 }}</div>
          </div>

          <div class="datablock">
            <div class="datatitle">CART STAKED</div>

            <div class="dataword">{{ cartStaked || 0 }}</div>
          </div>

          <div class="datablock">
            <div class="datatitle">NFT</div>

            <div class="dataword">{{ nft }}</div>
          </div>
        </div>
      </div>
      <!-- 登出按钮 -->
      <div class="logoutbtn" @click="acclogout()">
        <div class="logouttext">Logout</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvatarDialog",
  data() {
    return {
      account: null,
      nft: 0,
    };
  },

  props: ["bcart", "cartStaked", "cartAmount", "mynft"],

  computed: {
    showAvatarDialog() {
      return this.$store.state.navMenu.showAvatarDialog;
    },
    accountData() {
      return this.$store.state.navMenu.accountAll;
    },
  },

  watch: {},

  created() {},

  activated() {},

  methods: {
    // change按钮
    change() {
      this.$store.commit("showAvatarDialog");
      this.$store.commit("showSelectConnectPopup");
    },

    // 点击按钮登出
    acclogout() {
      // 调用父组件方法登出
      this.$store.commit("updateLoginStatus", false);
      // 隐藏窗口
      this.$store.commit("showAvatarDialog");
    },

    // 关闭窗口
    hidedialog() {
      this.$store.commit("showAvatarDialog", false);
    },
  },
};
</script>

<style scoped lang="stylus">

/* 弹窗整体样式 */
.avatardialog {
  z-index: 2;
  overflow: hidden;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* 窗口矩形样式 */
.avatarcontainer {
  width: 34.5rem;
  // height: 24.63rem;
  height: 28.63rem;

  background: #ffffff;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  /* padding: 2.0625rem 0.75rem 1.125rem 0.875rem; */
  padding: 2.56rem 2.19rem 2.06rem 1.56rem;
  box-sizing: border-box;
}

/* 标题和关闭图标区域 */
.titlecloseblock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* wallet标题文字属性 */
.wallettext {
  text-align: left;

  .wallet_title {
    font-size: 1.38rem;
    font-family: 'CeraGR-Bold';
    font-weight: bold;
    color: #000000;
    letter-spacing: 1px;
  }

  .wallet_description {
    margin-top: 0.75rem;
    font-size: 1rem;
    font-family: 'CeraGR-Medium';
    font-weight: 500;
    color: #666666;
  }
}

/* 退出登录按钮样式 */
.logoutbtn {
  margin-top: 7.75rem;

  // margin-top: 2.75rem;
  background: url('../assets/logoutBorder.png') no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

/* 登出文字显示 */
.logouttext {
  font-size: 1rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
  color: #000000;
}

/* 显示账号文字属性 */
.acctext {
  font-size: 0.8125rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2d2626;
  line-height: 1.125rem;
  /* 设置了上下左右四个外边距离 */
  margin: 0.8125rem 0.875rem 1.375rem 0.625rem;
  text-align: initial;
}

.secound_back {
  height: 4.0rem;
  background: #F3F3F3;
  border-radius: 0.75rem;
  padding: 1.88rem 1.69rem 1.75rem 1.75rem;
}

/* 虚线上方的区域布局 */
.cartbutton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* 外边距设置了 左右两边和下边距 */
  text-align: initial;
}

/* change按钮样式 */
.changebtn {
  width: 6.63rem;
  height: 2.88rem;
  background: #333333;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../assets/borderBold.jpg') no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* change按钮文字样式 */
.changetext {
  font-size: 1rem;
  font-family: 'CeraGR-Bold';
  font-weight: bold;
  color: #FFFFFF;
}

/* 虚线显示属性 */
.solidlink {
  /* 外边距设置左右和下边距 */
  width: 100%;
  height: 0.13rem;
  background: #E5E5E5;
  margin-top: 1.94rem;
}

/* 虚线上方显示cart文字的属性 */
.cartword {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #666666;
}

/* 虚线上方显示cart数据的属性 */
.cartnum {
  margin-top: 0.55rem;
  font-size: 1.38rem;
  font-family: 'Gilroy-Bold';
  font-weight: bold;
  color: #4A76FB;
  letter-spacing: 1px;
}

/* 虚线下方显示cart数据区域布局 */
.cartdata {
  margin-top: 4.69rem;
  margin-bottom :3rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1.13rem;
  padding-right: 1.13rem;
}

/* 虚线下方显示每一块数据区域布局 */
.datablock {
  display: flex;
  flex-direction: column;

  .dataword {
    margin-top: 0.63rem;
    font-size: 1.13rem;
    font-family: 'Gilroy-Bold';
    font-weight: bold;
    color: #000000;
  }
}

/* 虚线下方显示每一块数据标题文字 */
.datatitle {
  font-size: 1rem;
  font-family: 'CeraGR-Medium';
  font-weight: 500;
  color: #666666;
}
</style>
