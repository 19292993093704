<template>
  <div class="nonftarea">
    <!-- 显示图片 -->
    <div>
      <img src="../assets/NoNFTfound.png" class="nofoundicon" />
    </div>

    <!-- 显示文字 -->
    <div class="nofoundword">No NFT found</div>
    <div class="nofoundword1" >Holding card pledge will increase the weight by 0.2</div>
    <!-- 显示按钮 -->
    <div class="getnftButton" @click="getNFT()">
      <div class="getnftButtonText">Get NFTs</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoNFT",
  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  activated() {},

  methods: {
    getNFT() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
/* 窗口样式 */
.nonftarea {
  width: 23.13rem;
  height: 26.88rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1.25rem;
  display: flex;
  flex-flow: column;
  /* justify-content: center; */
  align-items: center;
  box-sizing: border-box;
}

.nonftareaload {
  width: 15.75rem;
  height: 18.25rem;
  background: #061730;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 图片显示属性 */
.nofoundicon {
  width: 5rem;
  height: 5rem;
  margin-top: 6.56rem;
  object-fit: cover;
}

/* 文字显示属性 */
.nofoundword {
  font-size: 1.13rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #999999;
  margin: 1.19rem 0 2.63rem;
}
.nofoundword1 {
  font-size: 1.13rem;
  font-family: "CeraGR-Regular";
  font-weight: 400;
  color: #999999;
  margin-bottom: 2rem;
}
/* 按钮显示属性 */
.getnftButton {
  width: 10.5rem;
  height: 3.13rem;
  background: linear-gradient(
    220deg,
    #fe58ca 0%,
    #00c7c3 13%,
    #5ab9f7 23%,
    #392efe 53%,
    #fd5c65 100%
  );
  border-radius: 0.63rem;
  filter: blur(0px);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 按钮文字属性 */
.getnftButtonText {
  font-size: 1rem;
  font-family: "CeraGR-Medium";
  font-weight: 500;
  color: #ffffff;
}
</style>