<template>
  <div class="loadingarea">
    <img src="../assets/loading_white.png" class="loadingicon" />
  </div>
</template>

<script>
export default {
  name: "NFTLoading",
  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  activated() {},

  methods: {},
};
</script>

<style scoped>
/* 窗口样式 */
.loadingarea {
  width: 23.13rem;
  height: 26.88rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1.25rem;
  display: flex;
  flex-flow: column;
  /* padding:2.9375rem 4.0625rem 1.4375rem 4rem;
    box-sizing: border-box; */
  align-items: center;
  justify-content: center;
}

/* enter按钮的图标 */
.loadingicon {
  width: 2rem;
  object-fit: cover;
  animation-name: loading;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* 读取图片动画 */
@keyframes loading {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}
</style>