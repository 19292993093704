import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { Notification, Tooltip, Pagination, Button, Input, Carousel,CarouselItem,Link,Slider,Table,TableColumn} from 'element-ui'
Vue.prototype.$notify = Notification;

Vue.config.productionTip = false

Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Input)
Vue.use(Carousel)
Vue.use(Link)
Vue.use(CarouselItem)
Vue.use(Slider)
Vue.use(Table)
Vue.use(TableColumn)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

