<template>
  <div class="home">
    <div class="container">
        <el-pagination
            small
            layout="prev, pager, next"
            :total="50">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import Proxy from "./../proxy.js";
import axios from 'axios'

export default {
  name: 'Cart',
  props: {
    msg: String
  },
  data() {
    return {
      
    }
  },
  activated() {
       
  },
  methods: {
    
  }
}
</script>

<style scoped>
.paginationContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
