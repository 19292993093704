
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const navMenu = {
  state: {
    web3Modal: '',
    provider: '',
    account: '',
    accountAll: '',
    providerType: '',
    showConnectManage: false,
    showSelectConnectPopup: false,
    NaviTopType:'nft',
    ifInstallMetamask: true,
    showAvatarDialog: false,
    showStakeDialog:false,
    ConnectWallet:false,
    loginStatus: false
  },
  mutations: {
    updateWeb3Modal(state, obj) {
      state.web3Modal = obj 
    },
    updateProviderType(state, val) {
      state.providerType = val
    },
    updateProvider(state, obj) {
      state.provider = obj
    },
    updateIfInstallMetamask(state, val) {
      state.ifInstallMetamask = val
    },
    showSelectConnectPopup(state) {
      state.showSelectConnectPopup = !state.showSelectConnectPopup
    },
    updateNaviTopType(state, toptype) {
      state.NaviTopType = toptype
    },
    showAvatarDialog(state,val) {
      state.showAvatarDialog = val
    },
    showStakeDialog(state) {
      state.showStakeDialog = !state.showStakeDialog
    },
    updateConnectWallet(state, val) {
      state.ConnectWallet = val
    },
    setAccount(state, val) {
      state.account = val
    },
    setAllAccount(state, val) {
      state.accountAll = val
    },
    updateLoginStatus(state, val) {
      state.loginStatus = val
    }
  },
  actions: {
  },
  getters: {
  }
}


export default new Vuex.Store({
  modules: {
    navMenu,
  }
})
