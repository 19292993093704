/* eslint-disable */

import Web3 from "web3";
import BN from "bn.js";
import contract from "@truffle/contract";
import Cart from "../deployments/abi/Cart.json";
import FarmbCart from "../deployments/abi/FarmbCart.json";
import FarmNFT from "../deployments/abi/FarmNFT.json";
import CartCorePool from "../deployments/abi/CartCorePool.json";
import CartPoolFactory from "../deployments/abi/CartPoolFactory.json";

import LPToken from "../deployments/abi/BEP20USDT.json";
import Helper from "../deployments/abi/Helper.json"
import ERC721Token from "../deployments/abi/ERC721Token.json";
import NFTMock from "../deployments/abi/NFTMock.json";
import SelfServiceAccessControls from "../deployments/abi/SelfServiceAccessControls.json";

import UNILPToken from "../deployments/abi/UNILP.json";
import { NETWORK_NAME } from "./constants";

import store from "./store";

export default {

  async initWeb3Account(callback) {
    if(store.state.navMenu.providerType == 'WalletConnect'){
      this.provider = store.state.navMenu.provider
      this.web3 = new Web3(this.provider);
      this.web3.provider = this.provider

      this.chainid = await this.web3.eth.net.getId();

      this.web3.eth.getAccounts((err, accs) => {
        callback(this.web3, accs[0])
      })

    } else {
      if (window.ethereum) {
        this.provider = window.ethereum;

        if (window.imToken && !window.ethereum.supportsSubscriptions()) {
          window.ethereum.on = null;
        }

        try {
          await window.ethereum.enable();
        } catch (error) {
          console.error("User denied account access");
        }
        let web3 = new Web3(this.provider);

        this.chainid = await web3.eth.net.getId();

        web3.eth.getAccounts((err, accs) => {
          if (err != null) {
            console.error("无法获取账号， 是否安装了 Metamask");
            this.message = "";
            store.commit('updateProviderType', '')
            store.commit('updateIfInstallMetamask', false)
            return;
          }

          if (accs.length === 0) {
            console.error("无法获取账号，Metamask 时候正确配置.");
            return;
          }

          this.web3 = new Web3(this.provider);
          this.web3.provider = this.provider



          store.commit('updateProviderType',  'MetaMask')
          store.commit('updateConnectWallet', true)
          var accLeft = accs[0].substring(0, 8)
          var accRight = accs[0].substr(-4)
          var account = accLeft + '....' + accRight
          store.commit('setAccount', account)
          store.commit('updateLoginStatus', true)

          callback(this.web3, accs[0])
        })
      }
    }
  },

  getUNI() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/UNILP.json`);
    const proxy = contract(UNILPToken)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },
  getToken() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/Cart.json`);
    const proxy = contract(Cart)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },

  getCartPoolLP() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/CartCorePool_LP.json`);
    const proxy = contract(CartCorePool)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },

  getHelper() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/Helper.json`);
    const proxy = contract(Helper)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },

  // 用于获取LP余额
  getUNILP(){
    let addrJs = process.env.VUE_APP_LP_ADDRESS
    const proxy = contract(LPToken)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs);
  },

  getCartCorePool() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/CartCorePool.json`);
    const proxy = contract(CartCorePool)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },

  getCartPoolFactory() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/CartPoolFactory.json`);
    const proxy = contract(CartPoolFactory)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },

  getFarmbCart() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/FarmbCart.json`);

    const proxy = contract(FarmbCart)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },

  getFarmNFT() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/FarmNFT.json`);

    const proxy = contract(FarmNFT)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },


  getNFTMock() {
    const networkName = NETWORK_NAME[this.chainid];
    let addrJs = require(`../deployments/${networkName}/NFTMock.json`);

    const proxy = contract(NFTMock)
    proxy.setProvider(this.provider)
    return proxy.at(addrJs.address);
  },

  getERC721TokenAt(addr) {
    const proxy = contract(ERC721Token)
    proxy.setProvider(this.provider)
    return proxy.at(addr);
  },

  getSelfServiceAccessControls() {
    const proxy = contract(SelfServiceAccessControls)
    proxy.setProvider(this.provider)
    return proxy.at("0xA171F57c90d8363744C7f9f550EEaC7f5D974737");
  },

  getLockingWeight(lockPeriod){
    const weightMultiplier = new BN('1000000000000000000000000');
    const periodBn = new BN(lockPeriod)
    const daysBn = new BN(365 * 24 * 60 * 60)
    return periodBn.mul(weightMultiplier).div(daysBn).add(weightMultiplier)
  },

  // 添加小数点后强制保留方法
  formatDecimal(num, decimal) {
    num = num.toString()
    let index = num.indexOf('.')
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
    } else {
        num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
  }

}