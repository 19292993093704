<template>
    <div class="earncartblock">
        <!-- 紫色区域 左半边 -->
        <div class="earcartLeft">
            <div class="earncartword">Earn CART</div>
            <div style="display:flex;justify-content:flex-start; margin-top:1.25rem;">
                <div class="tvlword" style="margin-right:5.13rem;" v-if="earnTitle == 'UNI LP' ">TVL  ${{usdtPrices}}</div>

                <div class="tvlword" style="margin-right:5.13rem;" v-else>TVL {{usdtPrices.toFixed(2)}} USD</div>

                <!-- <div class="stakecartword" style="margin-right:5.13rem;">Stake {{earnTitle}}</div> -->
                <!-- <div class="tvlword">APR: {{apyStringMin}} - {{apyStringMax}}</div> -->

            </div>
        </div>

        <!-- 紫色区域 右半边 -->
        <div v-if="earnTitle == 'CART' " class="earncartRight shinydarken" >
            <a href="https://www.gate.io/cn/trade/CART_USDT" target="_blank" style="text-decoration: none;">
                <!-- <img src="../assets/getcart.png" class="getcarticon"> -->
                <div class="getcartword">GET CART</div>
            </a>
        </div>

         <div v-if="earnTitle == 'UNI LP' " class="earncartRight shinydarken" >
            <a href="https://app.uniswap.org/#/add/v2/0x5c8c8d560048f34e5f7f8ad71f2f81a89dbd273e/0xdac17f958d2ee523a2206206994597c13d831ec7?chain=mainnet" target="_blank" style="text-decoration: none;">
                <!-- <img src="../assets/getcart.png" class="getcarticon"> -->
                <div class="getcartword">GET LP</div>
            </a>
        </div>

    </div>
</template>

<script>
export default {
    name: "Earn",
    props:['earnTitle','usdtPrices'],
    data() {
        return {
            tvl: 10000000
        }
    },

    computed: {},

    activated() {

    },

    watch: {},

    created() {},

    methods: {

    }
};
</script>

<style scoped>
/* 左半边部分矩形框 样式设计 */
.earncartblock{
    width: 46.5rem;
height: 9.06rem;
    background: rgba(0, 0, 0, 0.4);backdrop-filter: blur(10px);
    border-radius: 1.25rem;
    padding:1.63rem 3rem 2.69rem 2.94rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* 紫色矩形框内部左半边部分布局 */
.earcartLeft{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}

/* 紫色矩形框内部右边半边部分布局 */
.earncartRight{
    width: 10.5rem;
height: 3.75rem;
background: url('../assets/btn_back.png');
background-repeat: no-repeat;
background-size: 100% 100%;
/* background: linear-gradient(220deg, #FE58CA 0%, #00C7C3 13%, #5AB9F7 23%, #392EFE 53%, #FD5C65 100%); */
border-radius: 0.81rem;
    display: flex;
    align-self: center;
    justify-content: center;

}

/* Earn Cart文字显示样式 */
.earncartword{
    font-size: 1.88rem;
font-family: 'CeraGR-Black';
font-weight: 900;
color: #FFFFFF;
}

/* stake cart文字显示样式 */
.stakecartword,.tvlword{
  font-size: 1.13rem;
/* font-family: 'CeraGR-Regular'; */
font-family: 'Gilroy-Regular';

font-weight: 400;
color: #FFFFFF;
}

/* getcart 图标显示属性 */
.getcarticon{
    width:1.25rem;
    height: 1.25rem;
    object-fit: cover;
}

/* getcart文字显示属性 */
.getcartword{
     font-size: 1rem;
font-family: 'CeraGR-Medium';
font-weight: 500;
color: #FFFFFF;
}

.earncartRight a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
</style>